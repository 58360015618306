import React from 'react';
import GenericHeader from './GenericHeader';
import {Title} from '../typography/Title';

type Props = {
  maxWidth?: string;
  backButton?: boolean;
  leftContainer?: React.ReactElement;
  rightContainer?: React.ReactElement;
  backgroundColor?: string;
  padding?: string;
  TitleComponent?: typeof Title;
  height?: string;
  alignItems?: string;
  children: string;
};

const ThreeSectionTitleHeader = ({
  maxWidth,
  backButton,
  leftContainer,
  rightContainer,
  backgroundColor,
  padding,
  TitleComponent = Title,
  height,
  alignItems,
  children,
}: Props) => {
  return (
    <GenericHeader
      maxWidth={maxWidth}
      backButton={backButton}
      leftContainer={leftContainer}
      backgroundColor={backgroundColor}
      padding={padding}
      height={height}
      alignItems={alignItems}
      menuButton={rightContainer}>
      <TitleComponent>{children}</TitleComponent>
    </GenericHeader>
  );
};

export default ThreeSectionTitleHeader;
