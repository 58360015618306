import styled from '@emotion/styled';

// styles
import {grey_100, black} from '../../theme/vars';

// components
import {HeaderLarge} from '../typography/HeaderLarge';
import ButtonIcon from '../buttons/ButtonIcon';
import {BodyText} from '../typography/BodyText';
import XIcon from '../../assets/icons/x.svg';

const Container = styled.div`
  background: ${grey_100};
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  width: 100%;
  padding: 0.5rem;
  position: relative;

  .close-button-container {
    position: absolute;
    right: 0;
    top: 50%;
    width: 2.5rem;
    height: 2.5rem;
    transform: translateY(-1.25rem);
  }
`;

type Props = {
  children: string;
  onClose?: () => void;
  subheader?: string;
  textTransform?: string;
  inGame?: boolean;
};

const LeaderboardHeader = ({
  textTransform,
  children,
  onClose,
  subheader,
  inGame,
}: Props) => {
  const BodyTextH2 = BodyText.withComponent('h2');

  return (
    <Container>
      <HeaderLarge textTransform={textTransform} color={black} size="1.3rem">
        {children}
      </HeaderLarge>
      {onClose && !inGame && (
        <div className="close-button-container">
          <ButtonIcon alt="close" onClick={onClose} icon={XIcon} />
        </div>
      )}
      {subheader && (
        <BodyTextH2 display="block" color={black}>
          {subheader}
        </BodyTextH2>
      )}
    </Container>
  );
};

export default LeaderboardHeader;
