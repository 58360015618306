import {useEffect, useMemo, ReactNode} from 'react';
import {useDispatch} from 'react-redux';
import PusherContext from '../contexts/PusherContext';
import Pusher from 'pusher-js';
import {useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';
import {environment} from '../environments/environment';
import {request, PUSHER_AUTH_ROUTE} from '../api';
import {getAuthToken, getUserId} from '../utils/cookies';
import usePusherSubscribersAndHandlers from '../hooks/usePusherSubscribersAndHandlers';
import {RootState} from '../redux/rootReducer';
import {IN_GAME_ROUTES} from '../constants/routes.constants';

const PUSHER_KEY = environment.PUSHER_KEY;
const PUSHER_CLUSTER = environment.PUSHER_CLUSTER;

const pusherClient =
  PUSHER_KEY && PUSHER_CLUSTER
    ? new Pusher(PUSHER_KEY, {
        cluster: PUSHER_CLUSTER,
        forceTLS: true,
        authorizer: channel => ({
          authorize: (socketId, callback) => {
            request({
              route: PUSHER_AUTH_ROUTE.PUSHER_AUTH,
              body: {
                socket_id: socketId,
                channel_name: channel.name,
              },
            })
              .then(response => {
                callback(null, response);
              })
              .catch(err => {
                callback(err, null);
              });
          },
        }),
      })
    : null;

const PusherProvider = ({children}: {children: ReactNode}) => {
  const dispatch = useDispatch();
  const userId = getUserId();
  const authToken = getAuthToken();
  const location = useLocation();
  const {gameId, teamId, joinedGame} = useSelector(
    (state: RootState) => state.game,
  );

  const {
    subscribeToPublicChannel,
    subscribeToGameTimeExperience,
    unsubscribeFromGameTimeExperience,
    subscribeToPrivateChannel,
    unsubscribeFromPrivateChannel,
  } = usePusherSubscribersAndHandlers(pusherClient);

  useEffect(() => {
    // Methods are no-ops by default (i.e. subscribe when already subscribed, cancel when not present)
    if (userId && authToken) {
      subscribeToPrivateChannel();
    }
  }, [
    userId,
    authToken,
    subscribeToPrivateChannel,
  ]);

  useEffect(() => {
    subscribeToPublicChannel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!joinedGame && gameId && teamId) {
      unsubscribeFromGameTimeExperience({gameId, teamId});
    }
  }, [joinedGame, gameId, teamId, unsubscribeFromGameTimeExperience]);

  useEffect(() => {
    // Methods are no-ops by default (i.e. subscribe when already subscribed)
    // Unsubscribe happens on game exit
    if (
      !joinedGame ||
      !gameId ||
      !teamId ||
      !IN_GAME_ROUTES.some(route => {
        if (location.pathname === route)
          return true;
        if (gameId && location.pathname === route.replace(':gameId', gameId))
          return true;
        return false;
      })
    ) {
      return;
    }
    subscribeToGameTimeExperience({
      gameId,
      teamId,
    });
  }, [
    joinedGame,
    gameId,
    teamId,
    dispatch,
    subscribeToGameTimeExperience,
    location,
  ]);

  const pusherContext = useMemo(
    () => ({
      subscribeToGameTimeExperience,
      unsubscribeFromGameTimeExperience,
      subscribeToPrivateChannel,
      unsubscribeFromPrivateChannel,
    }),
    [
      subscribeToGameTimeExperience,
      unsubscribeFromGameTimeExperience,
      subscribeToPrivateChannel,
      unsubscribeFromPrivateChannel,
    ],
  );

  return (
    <PusherContext.Provider value={pusherContext}>
      {children}
    </PusherContext.Provider>
  );
};

export default PusherProvider;
