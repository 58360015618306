import React from 'react';
import {FieldHookConfig, Field} from 'formik';
import styled from '@emotion/styled';
import Input from '../inputs/Input';
import InputError from '../inputs/InputError';
import InputLabel from '../inputs/InputLabel';
import InputStatus from '../inputs/InputStatus';
import ErrorButton from '../buttons/ErrorButton';
import {defaultInputField} from '../../theme/form';

type ContainerProps = {
  css: any;
};

const Container = styled.div<ContainerProps>`
  ${props => props.css};
`;

type BaseTextFieldProps = FieldHookConfig<string> & {
  label: string;
  name: string;
  type: string;
  hasErrorButton?: boolean;
  errorButtonHandler?: () => void;
  inputStyle?: any;
  isEditable?: boolean;
  inputMode?:
    | 'text'
    | 'none'
    | 'tel'
    | 'url'
    | 'email'
    | 'numeric'
    | 'decimal'
    | 'search';
};

const InputField = ({
  name,
  label,
  type,
  onChange,
  onBlur,
  hasErrorButton,
  errorButtonHandler,
  inputStyle,
  isEditable = true,
  inputMode,
}: BaseTextFieldProps) => {
  const inputProps = {
    name: name,
    type: type,
    onChange: onChange,
    onBlur: onBlur,
  };

  if (!onChange) {
    delete inputProps.onChange;
  }

  if (!onBlur) {
    delete inputProps.onBlur;
  }

  return (
    <Container css={inputStyle?.self || defaultInputField}>
      <Field name={name}>
        {({field, form, meta}: {field: any; form: any; meta: any}) => (
          <>
            <Input
              required
              id={field.name}
              value={field.value}
              inputStyle={inputStyle?.input}
              {...inputProps}
              disabled={!isEditable}
              inputMode={inputMode}
            />
            <InputLabel htmlFor={field.name} labelStyle={inputStyle?.label}>
              {label}
            </InputLabel>
            {meta.touched && meta.error && (
              <InputError>{meta.error}</InputError>
            )}
            {meta.touched &&
              form.status &&
              !!form.status[field.name] &&
              !meta.error && (
                <InputStatus>{form.status[field.name]}</InputStatus>
              )}
            {hasErrorButton &&
              meta.touched &&
              meta.error &&
              errorButtonHandler && (
                <ErrorButton handleClick={errorButtonHandler} />
              )}
          </>
        )}
      </Field>
    </Container>
  );
};

export default InputField;
