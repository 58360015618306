import styled from '@emotion/styled';
import {white} from '../../theme/vars';

type BodyTextProps = {
  color?: string;
  theme?: Record<string, Record<string, string>>;
  align?: string;
  size?: string;
  bold?: boolean;
  uppercase?: boolean;
  display?: string;
};

export const BodyText = styled('p')<BodyTextProps>`
  color: ${props => props.color || white};
  font-family: Roboto-Regular;
  font-size: ${props => props.size || '1rem'};
  line-height: 1.5;
  text-align: ${props => props.align || 'center'};
  font-weight: ${props => (props.bold ? 'bold' : 'normal')};
  text-transform: ${props => (props.uppercase ? 'uppercase' : 'none')};
  display: ${props => props.display || 'inline'};
`;
