import {createSlice} from '@reduxjs/toolkit';
import {request, USER_ROUTES, putS3} from '../../../../api';
import {AppThunk} from '../../../store';
import { getUser, updateUserProfilePicPath } from '../../user';

const initialState = {
  isSettingAvatar: false,
  avatarSource: null,
  setAvatarSourceError: null,
};

const avatarSourceSlice = createSlice({
  name: 'avatarSource',
  initialState,
  reducers: {
    setAvatarSourceRequest(state) {
      state.isSettingAvatar = true;
    },
    setAvatarSourceSuccess(state, action) {
      state.isSettingAvatar = false;
      state.avatarSource = action.payload;
    },
    setAvatarSourceError(state, action) {
      state.setAvatarSourceError = action.payload.error;
    },
    clearAvatarState: () => initialState,
  },
});

export const {
  setAvatarSourceRequest,
  setAvatarSourceSuccess,
  setAvatarSourceError,
  clearAvatarState,
} = avatarSourceSlice.actions;

const apiUploadAvatarAndGetUrl = async (avatar: any) => {
  return request({
    route: USER_ROUTES.POST_USER_AVATAR,
    body: avatar,
  });
};

const apiSaveAvatarUrl = async (avatar: any) => {
  return request({
    route: USER_ROUTES.UPDATE_USER_PROFILE_PICTURE,
    body: avatar,
  });
};

export const setAvatarSource =
  ({
    userId,
    avatarImageSource,
  }: {
    userId: string;
    avatarImageSource: any;
  }): AppThunk =>
  async (dispatch, getState) => {
    dispatch(setAvatarSourceRequest());
    try {
      const state = getState();
      const file = avatarImageSource.files[0];
      const extension = file.name.split('.').reverse()[0];
      const filename = `${userId}.${extension}`;
      const contentType = file.type;
      const body = {filename, contentType};

      const signedS3Url = await apiUploadAvatarAndGetUrl(body);

      if (!signedS3Url) {
        return dispatch(setAvatarSourceError('Could not get avatar url.'));
      }

      // This request sends the signed url to s3
      await putS3(filename, contentType, signedS3Url, file);

      const s3Url = signedS3Url.split('?')[0];

      //
      const {profilePicPath} = await apiSaveAvatarUrl({
        profilePicPath: s3Url,
      });
      // error handling
      if (!profilePicPath) {
        return dispatch(
          setAvatarSourceError('Could not update user w/ avatar.'),
        );
      }
      dispatch(setAvatarSourceSuccess(profilePicPath));
      if (state.user.userData && !state.user.userData.isNewUser) {
        dispatch(getUser());
      } else {
        dispatch(updateUserProfilePicPath({ profilePicPath }));
      }
    } catch (err) {
      dispatch(setAvatarSourceError((err as Error).message));
    }
  };

export default avatarSourceSlice.reducer;
