import styled from '@emotion/styled';
//styles
import {black} from '../../theme/vars';
import HT from '../media/HTLogo';

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${black};
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const InteractionPlaceholder = () => {
  return (
    <Container>
      <HT />
    </Container>
  );
};

export default InteractionPlaceholder;
