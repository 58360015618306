import {createSlice} from '@reduxjs/toolkit';
import { AppThunk } from '../../store';
import { trackEventData } from '../../../utils/analytics';
import { TOGGLE_RADIO } from '../../../constants/analytics.constants';

export type RadioType = {
  isPlaying: boolean;
  streamUrl: string | null;
};

const initialState: RadioType = {
  isPlaying: false,
  streamUrl: null,
};

const mediaSlice = createSlice({
  name: 'radio',
  initialState,
  reducers: {
    play(state) {
      state.isPlaying = true;
    },
    stop(state) {
      state.isPlaying = false;
    },
    setRadioStreamUrl(state, action) {
      state.streamUrl = action.payload;
    },
    clearRadioState: () => initialState,
  },
});

export const toggleIsPlaying = (): AppThunk => (dispatch, getState) => {
  const state = getState();
  const {isPlaying} =  state.radio;
  trackEventData(TOGGLE_RADIO, {isPlaying: !isPlaying})
  if (isPlaying) {
    dispatch(stop());
  } else {
    dispatch(play());
  }
};


export const {
  play,
  stop,
  setRadioStreamUrl,
  clearRadioState,
} = mediaSlice.actions;

export default mediaSlice.reducer;
