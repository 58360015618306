import {useSelector} from 'react-redux';
import styled from '@emotion/styled';

// state
import {RootState} from '../../redux/rootReducer';

// components
import LeaderboardTableRow from './LeaderboardTableRow';
import {BodyText} from '../typography/BodyText';

// style
import {white} from '../../theme/vars';

const Container = styled.table`
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;

  thead,
  tbody {
    width: 100%;
  }

  tbody {
    margin-bottom: 7rem;
  }

  .header-container {
    width: 100%;
    display: flex;
  }

  .header {
    font-family: Roboto-Regular;
    font-size: 0.75rem;
    text-align: center;
    color: ${white};
    text-transform: uppercase;
    padding: 0.5rem 0;
  }

  .header-rank {
    width: 20%;
    margin-left: 5px;
  }

  .header-total-points {
    margin-right: 5px;
  }

  .header-spacer {
    width: 55%;
  }

  span {
    font-family: 'Industry-UltraItalic';
  }
`;

type Props = {
  leaderboardData: any[];
  skipHeader?: boolean;
};

const LeaderboardTable = ({leaderboardData, skipHeader}: Props) => {
  const {userData} = useSelector((state: RootState) => state.user);
  const BodyTextSpan = BodyText.withComponent('span');
  return (
    <Container>
      <thead>
        <tr className="header-container">
          <th className="header-rank header">
            <BodyTextSpan uppercase size=".75rem">
              Rank
            </BodyTextSpan>
          </th>
          <th className="header-spacer"></th>
          <th className="header-total-points header">
            <BodyTextSpan uppercase size=".75rem">
              Total PTS
            </BodyTextSpan>
          </th>
        </tr>
      </thead>
      <tbody>
        {leaderboardData &&
          leaderboardData.map((item, i) => {
            if (item.userId === userData?.id && i >= 3) {
              return (
                <LeaderboardTableRow isUser key={item.userId} item={item} />
              );
            }
            return null;
          })}
        {leaderboardData &&
          leaderboardData.map((item, i) => {
            if (skipHeader || i > 2) {
              return <LeaderboardTableRow key={item.userId} item={item} />;
            }
            return null;
          })}
        {leaderboardData && leaderboardData.length < 3 &&
          <tr style={{ display: 'flex', width: '100%' }}>
            <td colSpan={3} width={'100%'} style={{ display: 'flex', paddingTop: '10px' }}>
              <BodyTextSpan color={white} style={{ width: '100%', textAlign: 'center' }}>
                No additional data available.
              </BodyTextSpan>
            </td>
          </tr>
        }
      </tbody>
    </Container>
  );
};

export default LeaderboardTable;
