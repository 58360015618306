import {firebaseApp} from '../environments/firebase';
import { getAnalytics, logEvent, setUserId, setUserProperties, setAnalyticsCollectionEnabled, CustomParams } from "firebase/analytics";
import { PAGE_VIEW, SCREEN_VIEW } from '../constants/analytics.constants';

const analytics = getAnalytics(firebaseApp);

const logAnalyticsEvent = (eventName: string, params?: { [key: string]: any }) => {
  logEvent(analytics, eventName, {...params, property: 'web'});
}

const trackScreen = (screenName: string) => {
  logAnalyticsEvent(SCREEN_VIEW, {screen_name: screenName, screen_class: screenName});
  logAnalyticsEvent(PAGE_VIEW, {page_title: screenName, page_location: window.location.href});
}

const trackEventData = (category: any, data: any) => logAnalyticsEvent(category, data);

const trackUserId = (userId: string) =>
  setUserId(analytics, userId);

const trackUserProperties = (userObject: CustomParams) =>
  setUserProperties(analytics, userObject);

const setCollectionEnabled = (enable: boolean) =>
  setAnalyticsCollectionEnabled(analytics, enable);

export {
  trackScreen,
  trackEventData,
  trackUserId,
  trackUserProperties,
  setCollectionEnabled,
};
