import styled from '@emotion/styled';
import logo1 from '../../assets/images/logo@1x.png';
import logo2 from '../../assets/images/logo@2x.png';
import logo3 from '../../assets/images/logo@3x.png';

type Props = {
  width?: string;
};

const Image = styled('img')<Props>`
  width: ${props => props.width || '100%'};
`;

const HTLogo = ({width}: Props) => {
  return (
    <Image
      width={width}
      src={logo1}
      alt="HomeTurf logo"
      srcSet={`
        ${logo2} 2x,
        ${logo3} 3x,
      `}
    />
  );
};

export default HTLogo;
