import {
  setScoreboard,
  showInteraction,
  showScoreboard,
  videoReady,
} from '../../actions';
import {createSlice} from '@reduxjs/toolkit';
import {AppThunk} from '../../store';

type Scoreboard = {
  gameId: string | null;
  teamId: string | null;
  metadata: any | null;
  scoreboardData: any | null;
  visible: boolean;
};

const initialState: Scoreboard = {
  gameId: null,
  teamId: null,
  metadata: null,
  scoreboardData: null,
  visible: true,
};

const scoreboardSlice = createSlice({
  name: 'scoreboard',
  initialState,
  reducers: {
    clearScoreboard(state) {
      return initialState;
    },
    hideScoreboard(state) {
      state.visible = false;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(setScoreboard, (state, action) => {
        state.gameId = action.payload.gameId;
        state.teamId = action.payload.teamId;
        state.metadata = action.payload.metadata;
        state.scoreboardData = action.payload.scoreboardData;
      })
      .addCase(showScoreboard, state => {
        state.visible = true;
      })
      .addCase(showInteraction, state => {
        scoreboardSlice.caseReducers.hideScoreboard(state);
      })
      .addCase(videoReady, state => {
        scoreboardSlice.caseReducers.hideScoreboard(state);
      });
  },
});

export const {hideScoreboard, clearScoreboard} = scoreboardSlice.actions;

export const triggerScoreboard =
  (scoreboard = null): AppThunk =>
  async dispatch => {
    //set new scoreboard data if present or render old data
    if (scoreboard) {
      dispatch(setScoreboard(scoreboard));
    }
    dispatch(showScoreboard());
  };

export default scoreboardSlice.reducer;
