import {useSelector} from 'react-redux';
import {RootState} from '../../redux/rootReducer';
import GameViewWrapper from '../../wrappers/GameViewWrapper';
import LeaderboardBase from '../../components/leaderboard/LeaderboardBase';
import InGameLayout from '../../components/layouts/InGameLayout';

const InGameLeaderboard = () => {
  const {activeTeam} = useSelector((state: RootState) => state.teams);
  const {gameId} = useSelector((state: RootState) => state.game);

  return (
    <>
    {gameId && (
      <GameViewWrapper>
        <InGameLayout activeTeam={activeTeam} gameId={gameId}>
          <LeaderboardBase
            gameId={gameId}
            inGame={true}
          />
        </InGameLayout>
      </GameViewWrapper>
    )}
    </>
  );
};

export default InGameLeaderboard;
