import styled from '@emotion/styled';
import { curiousBlue } from '../../theme/vars';

const ChevronLink = styled.a`
  font-family: Roboto-Regular;
  color: ${curiousBlue};
  position: relative;
  padding: 0;
  padding-right: 1.5rem;
  border: none;
  background: none;
  margin: 0;
  &:after {
    content: '';
    background: url(../../../assets/icons/blueChevron.svg) no-repeat;
    width: 1rem;
    height: 1rem;
    display: inline-block;
    background-size: contain;
    position: absolute;
    right: 0;
  }
`;

export default ChevronLink;
