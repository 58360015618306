//credit https://css-tricks.com/linearly-scale-font-size-with-css-clamp-based-on-the-viewport/
export const clampBuilder = (minFontSize: number, maxFontSize: number) => {
  const root = document.querySelector('html') as any; // TODO: fix this
  const pixelsPerRem = Number(getComputedStyle(root).fontSize.slice(0, -2));
  const minWidthPx = 320;
  const maxWidthPx = 1366;

  const minWidth = minWidthPx / pixelsPerRem;
  const maxWidth = maxWidthPx / pixelsPerRem;

  const slope = (maxFontSize - minFontSize) / (maxWidth - minWidth);
  const yAxisIntersection = -minWidth * slope + minFontSize;

  return `clamp( ${minFontSize}rem, ${yAxisIntersection}rem + ${
    slope * 100
  }vw, ${maxFontSize}rem )`;
};
