import {RootState} from '../../rootReducer';
import {createEntityAdapter, createSlice} from '@reduxjs/toolkit';
import {request, INTERACTIONS_ROUTES} from '../../../api';
import {AppThunk} from '../../store';

type SliceState = {
  submittingPoll: boolean;
  success: any | null;
  error: any | null;
  step: {
    timeStarted: number | null;
    current?: any | null;
    interactionId: string | null;
  };
};

const initialValueState: SliceState = {
  submittingPoll: false,
  success: null,
  error: null,
  step: {
    timeStarted: null,
    current: null,
    interactionId: null,
  },
};

const resultsAdapter = createEntityAdapter({
  selectId: (result: any) => result.interactionId,
});

const gameStatusAdapter = createEntityAdapter({
  selectId: (gameStatus: any) => gameStatus.gameId,
});

const initialState = {
  ...initialValueState,
  results: resultsAdapter.getInitialState(),
};

const pollSlice = createSlice({
  name: 'poll',
  initialState: gameStatusAdapter.getInitialState(initialState),
  reducers: {
    updateGameStatus: gameStatusAdapter.upsertOne,
    setStep(state, action) {
      state.step = {
        timeStarted: Date.now(),
        current: action.payload.step,
        interactionId: action.payload.interactionId,
      };
    },
    submitPollRequest(state) {
      state.submittingPoll = true;
      state.error = null;
    },
    submitPollSuccess(state) {
      state.submittingPoll = false;
      state.success = true;
    },
    submitPollError(state, action) {
      state.submittingPoll = false;
      state.error = action.payload.error;
    },
    clearPollStatus(state) {
      state.submittingPoll = false;
      state.success = null;
      state.error = null;
    },
    pollResults(state, action) {
      resultsAdapter.addOne(state.results, action.payload);
    },
    removePollResults(state, action) {
      resultsAdapter.removeOne(state.results, action.payload);
    },
    clearPoll(state) {
      return {...state, ...initialState};
    },
  },
});

export const {
  updateGameStatus,
  setStep,
  submitPollRequest,
  submitPollSuccess,
  submitPollError,
  clearPollStatus,
  clearPoll,
  pollResults,
  removePollResults,
} = pollSlice.actions;

const apiSubmitPoll = async (body: any) => {
  return request({
    route: INTERACTIONS_ROUTES.SUBMIT_POLL,
    body: body,
  });
};

export const handlePollDetails =
  ({body}: {body: any}): AppThunk =>
  async dispatch => {
    dispatch(submitPollRequest());
    try {
      const {err} = await apiSubmitPoll(body);
      if (err) {
        return dispatch(submitPollError(err.message));
      }
      dispatch(submitPollSuccess());
    } catch (err) {
      dispatch(submitPollError((err as any).message));
    }
  };

export const handlePollCompletion =
  ({interactionId}: {interactionId: string}): AppThunk =>
  async dispatch => {
    dispatch(removePollResults(interactionId));
  };

export const {selectById: selectGameStatusById} =
  gameStatusAdapter.getSelectors((state: RootState) => state.poll);

export const {selectById: selectResultById} = resultsAdapter.getSelectors(
  (state: RootState) => state.poll.results,
);

export default pollSlice.reducer;
