/* eslint-disable no-script-url */
import {useState} from 'react';
import styled from '@emotion/styled';
import {css} from '@emotion/react';
import HTModal from '../modals/HTModal';
import HTWebView from '../interactions/HTWebView';
import {defaultModal} from '../../theme/modal';

type LinkStyles = {
  color?: string;
  fontFamily?: string;
};

const LinkContainer = styled.a<LinkStyles>`
  ${({fontFamily}) =>
    fontFamily &&
    css`
      font-family: ${fontFamily};
    `}
  ${({color}) =>
    color &&
    css`
      color: ${color};
    `}
`;

type Props = {
  url: string;
  color?: string;
  fontFamily?: string;
  children: any;
};

const Link = ({url, color, fontFamily, children}: Props) => {
  const [isLinkPopupOpen, setIsLinkPopupOpen] = useState(false);

  return (
    <>
      <HTModal
        isWebView={false}
        isOpen={isLinkPopupOpen}
        onClose={() => setIsLinkPopupOpen(false)}
        modalStyling={{
          ...defaultModal,
          content: {
            ...defaultModal.content,
            padding: 0,
          },
        }}>
        <HTWebView
          title="External Link"
          allowScroll={true}
          url={url}
          minHeight="80vh"
        />
      </HTModal>
      <LinkContainer
        href={url}
        color={color}
        fontFamily={fontFamily}
        onClick={event => {
          event.preventDefault();
          setIsLinkPopupOpen(true);
          return false;
        }}>
        {children}
      </LinkContainer>
    </>
  );
};

export default Link;
