import styled from '@emotion/styled';

// components
import {HeaderLarge} from '../typography/HeaderLarge';
import LeaderboardTopRankUser from './LeaderboardTopRankUser';
import LeaderboardTable from './LeaderboardTable';
import ChevronLink from '../typography/ChevronLink';

// styles
import {white, white_10} from '../../theme/vars';

const Container = styled.div`
  width: 100%;
  .top-ranked-users {
    width: 100%;
    background: ${white};
    border-radius: 3px;
  }

  .top-ranked-users-wrapper {
    display: flex;
    width: 100%;
    align-items: flex-end;
    padding: 0.5rem;
  }

  .leaderboard-table-container {
    width: 100%;
    padding-top: 0.5rem;
    background: ${white_10};
  }

  .top-ranked-users-games-link-container {
    display: flex;
    justify-content: flex-end;
    margin: 0;
  }
`;

type Props = {
  leaderboardData: any;
  setIsFlipped?: (val: boolean) => void;
};

const LeaderboardList = ({
  leaderboardData,
  setIsFlipped,
}: Props) => {
  const HeaderLargeH2 = HeaderLarge.withComponent('h2');
  const ChevronLinkAsButton = ChevronLink.withComponent('button');
  const getUsers = () => {
    return (
      <div className="leaderboard-table-container">
        <HeaderLargeH2 size="1.3rem">THE REST OF THE PACK</HeaderLargeH2>
        <LeaderboardTable leaderboardData={leaderboardData} />
      </div>
    );
  };

  return (
    <Container>
      <div className="top-ranked-users">
        {setIsFlipped ? (
          <>
            <div className="top-ranked-users-games-link-container">
              <ChevronLinkAsButton onClick={() => setIsFlipped(true)}>
                By Game
              </ChevronLinkAsButton>
            </div>
          </>
        ) : null}
        <div className="top-ranked-users-wrapper">
          {leaderboardData[1] && (
            <LeaderboardTopRankUser rowData={leaderboardData[1]} />
          )}
          {leaderboardData[0] && (
            <LeaderboardTopRankUser rowData={leaderboardData[0]} large />
          )}
          {leaderboardData[2] && (
            <LeaderboardTopRankUser rowData={leaderboardData[2]} />
          )}
        </div>
      </div>
      {leaderboardData && getUsers()}
    </Container>
  );
};

export default LeaderboardList;
