const Menu = () => {
  return (
    <svg width="33" height="23" viewBox="0 0 33 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd"
            d="M0.25 1.75C0.25 0.852537 0.977537 0.125 1.875 0.125H31.125C32.0225 0.125 32.75 0.852537 32.75 1.75C32.75 2.64746 32.0225 3.375 31.125 3.375H1.875C0.977537 3.375 0.25 2.64746 0.25 1.75ZM0.25 11.5C0.25 10.6025 0.977537 9.875 1.875 9.875H31.125C32.0225 9.875 32.75 10.6025 32.75 11.5C32.75 12.3975 32.0225 13.125 31.125 13.125H1.875C0.977537 13.125 0.25 12.3975 0.25 11.5ZM0.25 21.25C0.25 20.3525 0.977537 19.625 1.875 19.625H31.125C32.0225 19.625 32.75 20.3525 32.75 21.25C32.75 22.1475 32.0225 22.875 31.125 22.875H1.875C0.977537 22.875 0.25 22.1475 0.25 21.25Z"
            fill="white"/>
    </svg>
  );
};

export default Menu;
