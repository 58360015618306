import styled from '@emotion/styled';
import {HeaderLarge} from '../typography/HeaderLarge';
import {defaultThemeColor, curiousBlue} from '../../theme/vars';
import {clampBuilder} from '../../utils/style';
import GenericToastMessage from './GenericToastMessage';

export const PointsText = styled('h2')`
  font-family: Roboto-Bold;
  font-size: 1rem;
  font-size: ${clampBuilder(0.5, 1)};
  color: ${defaultThemeColor};
  text-transform: uppercase;
`;

export const ClampedHeaderLarge = styled(HeaderLarge)`
  font-size: ${clampBuilder(0.75, 1.5)};
`;

type Props = {
  pointsEarned: any;
};

const PointsToastMessage = ({pointsEarned}: Props) => {
  const handleConditionalCopy = (activity: any, category: string) => {
    switch (activity) {
      case 'trivia':
        return category === 'correct'
          ? ' - TRIVIA - CORRECT ANSWER!'
          : ' - TRIVIA';
      case 'team-scream':
        return category === 'top10'
          ? ' - TEAM SCREAM - TOP 10 LOUDEST!'
          : ' - TEAM SCREAM';
      case 'poll':
        return ' - POLL RESPONSE';
      case 'chat':
        return ' - FIRST CHAT';
      default:
        return '';
    }
  };

  return (
    <GenericToastMessage
      bodyText="Great job! Keep participating to rack up points on the leaderboard!"
      rightContainer={
        <>
          <ClampedHeaderLarge color={curiousBlue} align="left">
            +{pointsEarned.pointsEarned}
          </ClampedHeaderLarge>
          <PointsText>POINTS</PointsText>
        </>
      }>
      <>
        YOU EARNED POINTS
        {handleConditionalCopy(
          pointsEarned.activity,
          pointsEarned.scoreCategory,
        )}
      </>
    </GenericToastMessage>
  );
};

export default PointsToastMessage;
