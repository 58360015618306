import md5 from 'blueimp-md5';
import {createSlice} from '@reduxjs/toolkit';
import {request, GAME_ROUTES, USER_ROUTES} from '../../../api';
import errorMessages from '../../../constants/errorMessages';
import {AppThunk} from '../../store';
import {clearScoreboard} from '../scoreboard';
import {resetInteraction} from '../interaction';

type SliceState = {
  gameId: string | null;
  teamId: string | null;
  loading: boolean;
  error: string | null;
  joinedGame: boolean;
  hasJoinedHomeGame: boolean;
  submittingChat: boolean;
  submittingChatError: string | null;
  activeMillis: number;
  config: {
    startupVideoWatched: boolean;
    isMuted: boolean;
    getstreamToken: string | null;
    gameChatChannelName: string | null;
    isChatOpen: boolean;
    showWatchPartyModal: boolean;
    hasSeenTalkSmackModal: boolean;
    hasGameDrawerBeenExpanded: boolean;
  };
  calibration: {
    isCalibrating: boolean;
    hasCheckedStillSynced: boolean;
    offsetSeconds: number | null;
    timeLastJoinedGameOrCalibrated: Date | null;
  };
  modals: {
    chatModalViewed: boolean;
    syncModalViewed: boolean;
    rulesModalViewed: boolean;
  };
  leaderboardVisible: boolean;
  appInBackground: boolean;
  watchPartyGameId: string | null;
  watchPartyShortcode: string | null;
  watchPartyMeetingId: string | null;
  watchPartyPasscode: string | null;
  watchPartyConnected: boolean;
};

export const initialState: SliceState = {
  gameId: null,
  teamId: null,
  loading: false,
  error: null,
  joinedGame: false,
  hasJoinedHomeGame: false,
  submittingChat: false,
  submittingChatError: null,
  activeMillis: 0,
  config: {
    startupVideoWatched: false,
    isMuted: false,
    getstreamToken: null,
    gameChatChannelName: null,
    isChatOpen: false,
    showWatchPartyModal: false,
    hasSeenTalkSmackModal: false,
    hasGameDrawerBeenExpanded: false,
  },
  calibration: {
    isCalibrating: false,
    offsetSeconds: null,
    timeLastJoinedGameOrCalibrated: null,
    hasCheckedStillSynced: false,
  },
  modals: {
    chatModalViewed: false,
    syncModalViewed: false,
    rulesModalViewed: false,
  },
  leaderboardVisible: false,
  appInBackground: false,
  watchPartyGameId: null,
  watchPartyShortcode: null,
  watchPartyMeetingId: null,
  watchPartyPasscode: null,
  watchPartyConnected: false,
};

const gameSlice = createSlice({
  name: 'game',
  initialState,
  reducers: {
    joinGameStart(state, action) {
      state.loading = true;
      state.joinedGame = false;
      state.leaderboardVisible = false;
      state.submittingChat = initialState.submittingChat;
      state.submittingChatError = initialState.submittingChatError;
      state.appInBackground = false;
      if (
        action.payload.gameId !== state.gameId ||
        action.payload.teamId !== state.teamId
      ) {
        state.gameId = action.payload.gameId;
        state.teamId = action.payload.teamId;
        state.config = initialState.config;
        state.modals = initialState.modals;
        state.calibration = initialState.calibration;
        state.activeMillis =
          initialState.activeMillis;
      }
    },
    joinGameFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    joinGameSuccess(state, action) {
      state.loading = false;
      state.joinedGame = true;
      state.error = initialState.error;
      state.config.getstreamToken = action.payload.getstreamToken;
      state.config.gameChatChannelName = `feed-${md5(
        `${state.gameId}-${state.teamId}`,
      )}`;
      state.config.hasSeenTalkSmackModal = false;
      state.config.hasGameDrawerBeenExpanded = false;
    },
    setHasJoinedHomeGame(state, action) {
      state.hasJoinedHomeGame = action.payload.hasJoinedHomeGame;
    },
    resetJoinGameStatus(state) {
      state.error = initialState.error;
      state.joinedGame = false;
    },
    setStartupVideoWatched(state, action) {
      state.config.startupVideoWatched = action.payload;
    },
    cancelCalibrationAndSetOffsetToZeroIfNeeded(state) {
      if (state.calibration.offsetSeconds) {
        // reset still synced timer
        state.calibration.hasCheckedStillSynced = false;
        state.calibration.timeLastJoinedGameOrCalibrated = new Date();
      } else {
        state.calibration.offsetSeconds = 0;
      }
    },
    setTimeLastJoinedGameOrCalibrated(state) {
      state.calibration.timeLastJoinedGameOrCalibrated = new Date();
    },
    markHasCheckedStillSynced(state) {
      state.calibration.hasCheckedStillSynced = true;
    },
    setIsChatOpen(state, action) {
      state.config.isChatOpen = action.payload;
    },
    setShowWatchPartyModal(state, action) {
      state.config.showWatchPartyModal = action.payload;
    },
    toggleMuteInteractions(state) {
      state.config.isMuted = !state.config.isMuted;
    },
    showLeaderboard(state) {
      state.leaderboardVisible = true;
    },
    hideLeaderboard(state) {
      state.leaderboardVisible = false;
    },
    chatModalShown(state) {
      state.config.hasSeenTalkSmackModal = true;
    },
    submitChatMessageRequest(state) {
      state.submittingChat = true;
    },
    submitChatMessageSuccess(state) {
      state.submittingChat = false;
    },
    submitChatMessageError(state, action) {
      state.submittingChatError = action.payload;
    },
    hideContestTerms(state) {
      state.modals.rulesModalViewed = true;
    },
    setActiveMillis(state, action) {
      state.activeMillis = action.payload;
    },
    resetActiveMillis(state) {
      state.activeMillis = 0;
    },
    gameDrawerHasBeenExpanded(state) {
      state.config.hasGameDrawerBeenExpanded = true;
    },
    exitGameTime(state) {
      state.joinedGame = false;
      state.activeMillis = 0;
    },
    enterGameTime(state) {
      state.joinedGame = true;
    },
    setAppInBackground(state, action) {
      state.appInBackground = action.payload;
    },
    resetGameState() {
      return initialState;
    },
    setWatchPartyGameId(state, action) {
      state.watchPartyGameId = action.payload;
    },
    setWatchPartyShortcode(state, action) {
      state.watchPartyShortcode = action.payload;
    },
    setWatchPartyMeetingId(state, action) {
      state.watchPartyMeetingId = action.payload;
    },
    setWatchPartyPasscode(state, action) {
      state.watchPartyPasscode = action.payload;
    },
    setWatchPartyConnected(state, action) {
      state.watchPartyConnected = action.payload;
    },
    resetAllWatchPartyVars(state) {
      state.watchPartyGameId = null;
      state.watchPartyShortcode = null;
      state.watchPartyMeetingId = null;
      state.watchPartyPasscode = null;
      state.watchPartyConnected = false;
    },
    setGetstreamToken(state, action) {
      state.config.getstreamToken = action.payload.getstreamToken;
    }
  },
});

export const {
  joinGameStart,
  joinGameFailure,
  joinGameSuccess,
  setHasJoinedHomeGame,
  resetJoinGameStatus,
  cancelCalibrationAndSetOffsetToZeroIfNeeded,
  setTimeLastJoinedGameOrCalibrated,
  markHasCheckedStillSynced,
  setStartupVideoWatched,
  setIsChatOpen,
  setShowWatchPartyModal,
  toggleMuteInteractions,
  showLeaderboard,
  hideLeaderboard,
  chatModalShown,
  submitChatMessageRequest,
  submitChatMessageSuccess,
  submitChatMessageError,
  hideContestTerms,
  setActiveMillis,
  resetActiveMillis,
  gameDrawerHasBeenExpanded,
  enterGameTime,
  exitGameTime,
  setAppInBackground,
  resetGameState,
  setWatchPartyGameId,
  setWatchPartyShortcode,
  setWatchPartyMeetingId,
  setWatchPartyPasscode,
  resetAllWatchPartyVars,
  setWatchPartyConnected,
  setGetstreamToken,
} = gameSlice.actions;

export const joinGame =
  (gameId: string, callback: () => void): AppThunk =>
  async (dispatch, getState) => {
    const state = getState();
    const teamId = state.teams.activeAppTeamId;
    const {userData} = state.user;
    const {nextGame} = state.teams;

    dispatch(clearScoreboard());
    dispatch(resetInteraction());
    dispatch(joinGameStart({gameId, teamId}));

    try {
      if (!userData) {
        throw new Error('Missing user data when joining game.');
      }
      const data = await request({
        route: GAME_ROUTES.JOIN_GAME,
        pathParams: {gameId, teamId},
      });

      const hasJoinedHomeGame = teamId === nextGame?.homeTeamId;

      dispatch(setHasJoinedHomeGame({hasJoinedHomeGame}));
      dispatch(joinGameSuccess(data));

      // dispatch(
      //   setMetadataAndToken({metadata: data.hlsMetadata, token: data.hlsToken}),
      // );
      callback();
    } catch (error) {
      dispatch(joinGameFailure(errorMessages.joinGameError));
      //throw errorMessages.joinGameError;
    }
  };

const submitChatToAPI = async (gameId: string, teamId: string, text: string, isInHomeStadium: boolean) => {
  return request({
    route: USER_ROUTES.SUBMIT_CHAT_MESSAGE,
    body: {
      gameId,
      teamId,
      text,
      isInHomeStadium
    },
  });
};

export const submitChatMessage =
  (gameId: string, teamId: string, message: string, isInHomeStadium: boolean): AppThunk =>
  async dispatch => {
    dispatch(submitChatMessageRequest());
    try {
      await submitChatToAPI(gameId, teamId, message, isInHomeStadium);
      dispatch(submitChatMessageSuccess());
    } catch (err) {
      dispatch(submitChatMessageError((err as Error).message));
    }
  };

export default gameSlice.reducer;
