import {useState} from 'react';

// components
import LoggedInLayout, {LoggedInLayoutProps} from './LoggedInLayout';
import Menu from '../menu/Menu';
import routes from '../../constants/routes.constants';
import HomeMenuIcon from '../../assets/icons/HomeMenuIcon';
import { useDispatch, useSelector } from 'react-redux';
import {RootState} from '../../redux/rootReducer';
// import GlobalLeaderboardMenuIcon from '../../assets/icons/GlobalLeaderboardMenuIcon';
import { useLocation, useNavigate } from 'react-router-dom';
import {MenuItemProps} from '../menu/MenuItem';
import MenuProfile from '../../assets/icons/MenuProfile';
import GameMenuIcon from '../../assets/icons/GameMenuIcon';
import logout from '../../assets/icons/logout.svg';
import { logoutUser } from '../../redux/modules/user';
import { AnyAction } from 'redux';
import DynamicMenuIcon from '../image/DynamicMenuIcon';
import styled from '@emotion/styled';
import useQuery from '../../hooks/useQuery';

type LogoutIconWrapperProps = {
  flip?: boolean;
}

const LogoutIconWrapper = styled.div`
  padding: 0 3px;
  -webkit-transform: ${(props: LogoutIconWrapperProps) => props.flip? 'scaleX(-1)' : ''};
  transform: ${(props: LogoutIconWrapperProps) => props.flip? 'scaleX(-1)' : ''};
`;

//TODO: There’s a lot of copy/paste to generate menu items between HomeLayout and InGameLayout.  Should probably consolidate.

const HomeLayout = ({
  children,
}: Omit<LoggedInLayoutProps, 'setMenuOpen'|'gameId'>) => {
  const [open, setOpen] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {search} = useLocation();
  const query = useQuery(search);

  const bypassStadiumKey = query.get('bypassKey');


  const user = useSelector((state: RootState) => state.user.userData);
  const {isInHomeStadium} = useSelector((state: RootState) => state.geolocation);
  const {nextGame, activeTeam} = useSelector((state: RootState) => state.teams);

  const extraMenuItems: MenuItemProps[] = [];

  const staticMenuItems: MenuItemProps[] = [
    // {
    //   name: 'Home',
    //   icon: <HomeMenuIcon />,
    //   route: routes.home,
    //   onClick: () => {
    //     navigate(routes.home);
    //   },
    // },
    // {
    //   name: 'Season Leader Board',
    //   icon: <GlobalLeaderboardMenuIcon />,
    //   route: routes.leaderboard,
    //   onClick: () => navigate(routes.leaderboard),
    // },
  ];

  if (nextGame) {
    staticMenuItems.push({
      name: isInHomeStadium ? 'Multi-View' : 'Game',
      icon: <GameMenuIcon />,
      route: routes.inGame,
      onClick: () => {
        navigate(routes.inGame.replace(':gameId', nextGame.id).concat(`?bypassKey=${bypassStadiumKey}`));
      }
    });
  }

  if (user?.guestUser && nextGame) {
    extraMenuItems.push(
      {
        name: 'Login/Register',
        icon: <LogoutIconWrapper flip><img width={26} height={26} src={logout} alt="icon" /></LogoutIconWrapper>,
        route: routes.smsAuth,
        onClick: () => navigate(routes.smsAuth.replace(':gameId', nextGame.id)),
      }
    )
  } else {
    if (nextGame) {
      extraMenuItems.push(
        {
          name: 'Edit Profile',
          icon: <MenuProfile />,
          route: routes.editProfile,
          onClick: () => {
            navigate(routes.editProfile.replace(':gameId', nextGame.id));
          }
        }
      );
    }
    extraMenuItems.push(
      {
        name: 'Logout',
        icon: <LogoutIconWrapper><img width={26} height={26} src={logout} alt="icon" /></LogoutIconWrapper>,
        route: routes.initialLaunch,
        onClick: () => {
          dispatch(logoutUser() as unknown as AnyAction);
          navigate(routes.initialLaunch);
        }
      }
    )
  }

  const dynamicMenuItems: MenuItemProps[] = (activeTeam?.menuItems || []).map((item: any) => ({
    name: item.item_name,
    icon: <DynamicMenuIcon path={item.item_icon_path} src={item.item_icon_url} alt={`${item.item_name} icon`} />,
    route: item.item_url,
    onClick: () => window.open(item.item_url, '_blank'),
  }));


  return (
    <>
      {!!user && (
        <Menu
          open={open}
          onClose={() => setOpen(false)}
          username={user ? user.username : ''}
          avatar={user?.profilePicPath ? user.profilePicPath : ''}
          isGuestUser={!!user?.guestUser}
          gameId={nextGame?.id}
          menuItems={[ ...staticMenuItems, ...dynamicMenuItems, ...extraMenuItems ]}
        />
      )}
      <LoggedInLayout
        setMenuOpen={() => setOpen(true)}
        includeMenu={true}>
        {children}
      </LoggedInLayout>

    </>
  );
};

export default HomeLayout;
