import {useState} from 'react';
import {TailSpin} from 'react-loader-spinner';
import {motion, useAnimation} from 'framer-motion';
import styled from '@emotion/styled';

// styles
import {defaultThemeColor, white} from '../../theme/vars';

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const FadeAwayBackground = styled(motion.div)`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: ${defaultThemeColor};
`;

const IFrame = styled.iframe<IFrameProps>`
  width: 100%;
  height: 100%;
  pointer-events: ${({allowScroll}) => (allowScroll ? 'inherit' : 'none')};
  min-height: ${({minHeight}) => (minHeight ? minHeight : '0')};

  &.hidden {
    height: 0;
  }
`;

const LoadingContainer = styled.div`
  z-index: 1;
  position: absolute;
`;

type IFrameProps = {
  allowScroll?: boolean;
  minHeight?: string;
};

type Props = {
  url: string;
  title: string;
  allowScroll?: boolean;
  minHeight?: string;
};

const HTWebView = ({url, title, allowScroll = false, minHeight}: Props) => {
  const [webPageLoaded, setWebPageLoaded] = useState(false);
  const [showFadeAwayBackground, shouldShowFadeAwayBackground] = useState(true);
  const controls = useAnimation();

  const onMyFrameLoad = async () => {
    setWebPageLoaded(true);
    await controls.start('fadeIn');
    shouldShowFadeAwayBackground(false);
  };

  const backgroundVariants = {
    initial: {
      opacity: 1,
    },
    fadeIn: {
      opacity: 0,
      transition: {
        duration: 1.5,
      },
    },
  };

  return (
    <Container>
      <IFrame
        allowScroll={allowScroll}
        minHeight={minHeight}
        className={(!webPageLoaded && 'hidden') || ''}
        title={title}
        id="ht-webview"
        src={url}
        onLoad={onMyFrameLoad}
      />
      {showFadeAwayBackground && (
        <FadeAwayBackground
          initial="initial"
          animate={controls}
          variants={backgroundVariants}
        />
      )}
      {!webPageLoaded && (
        <LoadingContainer>
          <TailSpin color={white} height={75} width={75} />
        </LoadingContainer>
      )}
    </Container>
  );
};

export default HTWebView;
