import {useState} from 'react';
import {useDispatch} from 'react-redux';

// components
import TriviaTransitionToAnswer from '../../components/poll/TriviaTransitionToAnswer';
import TriviaShowAnswerToQuestion from '../../components/poll/TriviaShowAnswerToQuestion';
import InteractionContainer from '../../components/interactions/InteractionContainer';
import TriviaCountdownQuestion from '../../components/poll/TriviaCountdownQuestion';
import TriviaLoadingResults from '../../components/poll/TriviaLoadingResults';
import TriviaResults from '../../components/poll/TriviaResults';

// constants
import {triviaStep} from '../../constants/Trivia.constants';

// hooks
import {
  handleTriviaDetails,
  handleTriviaCompletion,
} from '../../redux/modules/trivia';
import {resetInteraction} from '../../redux/modules/interaction';
import {setStep} from '../../redux/modules/trivia';
import useTriviaPollSetup from '../../hooks/useTriviaPollSetup';
import {AnyAction} from '@reduxjs/toolkit';

type Props = {
  interaction: any;
  userId: string;
  gameId: string;
  trivia: any;
  joinedTeam: {
    color: string;
  };
  isWatchPartyConnected?: boolean;
  isGuestUser: boolean;
};

const Trivia = ({
  interaction,
  userId,
  gameId,
  trivia,
  joinedTeam,
  isWatchPartyConnected = false,
  isGuestUser,
}: Props) => {
  const dispatch = useDispatch();

  const {step} = trivia;
  const {timeStarted} = step;

  const {interactionId, metadata: interactionMetaData} = interaction;
  const [activeAnswer, setActiveAnswer] = useState<string | null>(null);
  const {color: backgroundColor} = joinedTeam;

  const setCurrentStep = (step: any) => {
    dispatch(setStep({step, interactionId}));
  };

  useTriviaPollSetup({
    currentStep: step,
    setStep: setCurrentStep,
    gameId,
    interactionId,
    type: 'trivia',
  });

  const handleTriviaCountdownQuestion = (nextStep: any, answer: any) => {
    setActiveAnswer(answer);
    setCurrentStep(nextStep);
  };

  const checkAnswer = () => {
    const answerObject =
      interactionMetaData &&
      interactionMetaData.options.find(
        (option: {id: null}) => option.id === activeAnswer,
      );

    if (answerObject) {
      return answerObject.correct;
    }
  };

  const startTriviaSubmit = (body: any) =>
    dispatch(handleTriviaDetails({body}) as unknown as AnyAction);

  const startClearInteractionType = () =>
    dispatch(resetInteraction() as unknown as AnyAction);

  const startTriviaCompletion = (interactionId: string) =>
    dispatch(handleTriviaCompletion({interactionId}) as unknown as AnyAction);

  // switch to render
  const renderStateSwitch = (renderStep: {
    current: any;
    timeStarted: number;
  }) => {
    switch (renderStep?.current) {
      case triviaStep.COUNTDOWN_QUESTION:
        return (
          <TriviaCountdownQuestion
            interaction={interaction}
            setStep={handleTriviaCountdownQuestion}
            handleSubmit={startTriviaSubmit}
            timeStarted={timeStarted}
            userId={userId}
            isWatchPartyConnected={isWatchPartyConnected}
            backgroundColor={backgroundColor}
            isGuestUser={isGuestUser}
          />
        );
      case triviaStep.TRANSITION_TO_ANSWER:
        return (
          <TriviaTransitionToAnswer
            interaction={interaction}
            setStep={setCurrentStep}
            activeAnswer={activeAnswer}
            isWatchPartyConnected={isWatchPartyConnected}
            backgroundColor={backgroundColor}
          />
        );
      case triviaStep.SHOW_ANSWER_TO_QUESTION:
        return (
          <TriviaShowAnswerToQuestion
            interaction={interaction}
            setStep={setCurrentStep}
            isCorrectAnswer={checkAnswer()}
            isWatchPartyConnected={isWatchPartyConnected}
            activeAnswer={activeAnswer}
            backgroundColor={backgroundColor}
          />
        );
      case triviaStep.LOADING_RESULTS:
        return (
          <TriviaLoadingResults
            interaction={interaction}
            setStep={setCurrentStep}
            activeAnswer={activeAnswer}
            isWatchPartyConnected={isWatchPartyConnected}
            clearInteractionType={startClearInteractionType}
            backgroundColor={backgroundColor}
          />
        );
      case triviaStep.RESULTS:
        return (
          <TriviaResults
            interaction={interaction}
            trivia={trivia}
            isCorrectAnswer={checkAnswer()}
            activeAnswer={activeAnswer}
            clearInteractionType={startClearInteractionType}
            onTriviaCompletion={startTriviaCompletion}
            isWatchPartyConnected={isWatchPartyConnected}
            backgroundColor={backgroundColor}
          />
        );
      default:
        return null;
    }
  };

  return (
    <InteractionContainer
      backgroundColor={backgroundColor}
      isWatchPartyConnected={false}
      content={
        interactionMetaData &&
        interactionMetaData.options &&
        renderStateSwitch(step)
      }
    />
  );
};

export default Trivia;
