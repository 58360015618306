import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { black } from '../../theme/vars';
import { environment } from '../../environments/environment';
import InteractionContainer from '../../components/interactions/InteractionContainer';
import { clearInteraction } from '../../redux/modules/interaction';
import { useDispatch, useSelector } from 'react-redux';
import { AnyAction } from 'redux';
import { RootState } from '../../redux/rootReducer';

const DURATION = 5000;

type ContainerProps = {
  color: string;
};

const Container = styled.div<ContainerProps>`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ color }) => (color ? `#${color}` : `${black}`)};

  .image-interaction-content {
    flex: 1;
    padding: 1rem;
  }
`;

const GameImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: contain;
  max-height: inherit;
  padding: 1rem;
`;

const ImageCard = styled.div`
  background: linear-gradient(rgb(49, 49, 49), rgb(0, 0, 0));
  border-radius: 12px;
  max-height: calc(100vh - 6rem);
`;

type ImageInteractionsProps = {
  mediaPath: string;
  joinedTeam: any;
};

const ImageInteraction = ({
  mediaPath,
  joinedTeam,
}: ImageInteractionsProps) => {
  const dispatch = useDispatch();
  const { color: backgroundColor } = joinedTeam;
  const { visible } = useSelector((state: RootState) => state.interaction);
  //NOTE: this code assumes you are always using a qualified domain and not running under localhost.
  const domainParts = window.location.hostname.split('.'); // will be length 2 or 3
  const domain = domainParts.slice(domainParts.length === 2 ? 0 : 1).join('.');
  let mediaUrl = `https://${environment.baseMediaUrl}.${domain}`;
  if (environment.baseMediaPort && environment.baseMediaPort !== '') {
    mediaUrl = `${mediaUrl}:${environment.baseMediaPort}`
  }
  const source = `${mediaUrl}/${mediaPath}`

  useEffect(() => {
    let interval: string | number | NodeJS.Timeout | undefined;

    if (visible) {
      interval = setInterval(() => {
        dispatch(clearInteraction() as unknown as AnyAction);
      }, DURATION);
    }
    return () => {
      interval && clearInterval(interval);
    };
  }, [visible, dispatch]);

  if (!visible) return null;
  
  return (
    <InteractionContainer
      content={
        <Container color={backgroundColor}>
          <div className="image-interaction-content">
            <ImageCard>
              <GameImage src={source} />
            </ImageCard>
          </div>
        </Container>
      }
      backgroundColor={backgroundColor}
      isWatchPartyConnected={false}
    />
  );
};

export default ImageInteraction;
