import {createSlice} from '@reduxjs/toolkit';
import {LOGIN_ROUTES, request} from '../../../../api';
import {AppThunk} from '../../../store';
import {setUser, setUserToken} from '../../user';
import { setGetstreamToken } from '../../game';

const PHONE_NUMBER_REGISTRATION_SOURCE = 'webapp';

const initialState = {
  country: '',
  phoneNumber: '',
  submittingPhoneNumber: false,
  submitPhoneNumberSuccess: false,
  submitPhoneNumberError: null,
  verifyingPhoneNumber: false,
  verifyPhoneNumberSuccess: false,
  verifyPhoneNumberError: null,
  isResend: false,
};

const standaloneSMSSlice = createSlice({
  name: 'standalone-sms',
  initialState,
  reducers: {
    submitPhoneNumberRequest(state, action) {
      state.submittingPhoneNumber = true;
      state.submitPhoneNumberError = null;
      state.isResend = action.payload.isResend
    },
    submitPhoneNumberSuccess(state, action) {
      state.submittingPhoneNumber = false;
      state.submitPhoneNumberSuccess = true;
      state.country = action.payload.country;
      state.phoneNumber = action.payload.phoneNumber;
      state.submitPhoneNumberError = null;
    },
    submitPhoneNumberError(state, action) {
      state.submittingPhoneNumber = false;
      state.submitPhoneNumberError = action.payload.error;
    },
    verifyPhoneNumberRequest(state) {
      state.verifyingPhoneNumber = true;
      state.verifyPhoneNumberError = null;
    },
    verifyPhoneNumberSuccess(state) {
      state.verifyingPhoneNumber = false;
      state.verifyPhoneNumberSuccess = true;
      state.verifyPhoneNumberError = null;
    },
    setVerifyPhoneNumberError(state, action) {
      state.verifyingPhoneNumber = false;
      state.verifyPhoneNumberError = action.payload.error;
    },
    clearPhoneNumberState: () => initialState,
    clearResendState(state) {
      state.isResend = false;
    },
    clearVerifyPhoneNumberError(state) {
      state.verifyPhoneNumberError = null;
    },
    clearSubmitPhoneNumberError(state) {
      state.submitPhoneNumberError = null;
    }
  },
});

export const {
  submitPhoneNumberRequest,
  submitPhoneNumberSuccess,
  submitPhoneNumberError,
  verifyPhoneNumberRequest,
  verifyPhoneNumberSuccess,
  setVerifyPhoneNumberError,
  clearPhoneNumberState,
  clearResendState,
  clearVerifyPhoneNumberError,
  clearSubmitPhoneNumberError
} = standaloneSMSSlice.actions;

const apiSubmitPhoneNumber = async (country: string, phoneNumber: string) => {
  return await request({
    route: LOGIN_ROUTES.SUBMIT,
    body: {
      country,
      phoneNumber,
    },
  });
};

const apiVerifyPhoneNumber = async (
  country: string,
  phoneNumber: string,
  code: string,
  teamId: string,
  username: string,
) => {
  return await request({
    route: LOGIN_ROUTES.VERIFY,
    body: {
      country,
      phoneNumber,
      code,
      teamId,
      source: PHONE_NUMBER_REGISTRATION_SOURCE,
      username,
    },
  });
};

export const submitPhoneNumber =
  (country: string, phoneNumber: string, isResend: boolean = false): AppThunk =>
  async dispatch => {
    dispatch(submitPhoneNumberRequest({isResend}));
    try {
      await apiSubmitPhoneNumber(country, phoneNumber);
      dispatch(submitPhoneNumberSuccess({country, phoneNumber}));
    } catch (err) {
      dispatch(submitPhoneNumberError((err as Error).message));
    }
  };

export const verifyPhoneNumber =
  (
    country: string,
    phoneNumber: string,
    code: string,
    username: string,
  ): AppThunk =>
  async (dispatch, getState) => {
    dispatch(verifyPhoneNumberRequest());
    try {
      const teamId = getState().teams.activeAppTeamId;
      const {user, authToken, getstreamToken} = await apiVerifyPhoneNumber(
        country,
        phoneNumber,
        code,
        teamId,
        username,
      );
      // Handle setting user tokens
      if (user && authToken && getstreamToken) {
        setUserToken({user: user, authToken: authToken});
        dispatch(setGetstreamToken({getstreamToken}));
        dispatch(verifyPhoneNumberSuccess());
        dispatch(setUser({user}));
      }
    } catch (error) {
      // TODO: Update formatting of error so first message is a string (need to verify handling throughout app)
      dispatch(
        setVerifyPhoneNumberError({
          error: `${
            (error as Error).message || 'Could not verify phone number'
          }`,
        }),
      );
    }
  };

export default standaloneSMSSlice.reducer;
