import {useCallback, useEffect} from 'react';
import DefaultLayout from '../../components/layouts/DefaultLayout';
import {TailSpin} from 'react-loader-spinner';
import {useLocation, useNavigate} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {RootState} from '../../redux/rootReducer';
import {isEmpty} from '../../utils/arrays';
import routes from '../../constants/routes.constants';
import { resetGameState } from '../../redux/modules/game';
import { AnyAction } from 'redux';
import { resetInteraction } from '../../redux/modules/interaction';
import useQuery from '../../hooks/useQuery';
import { clearTeams } from '../../redux/modules/teams';
import { clearMedia } from '../../redux/modules/media';

const PRELOAD_TIMEOUT_MILLISECONDS = 12000;

export default function InitialLaunchView() {
  const {search} = useLocation();
  const query = useQuery(search);
  const navigate = useNavigate();

  const bypassStadiumKey = query.get('bypassKey');


  const {
    activeAppTeamId,
    error: teamsError,
    teamsData: teams,
  } = useSelector((state: RootState) => state.teams);

  const { error: getMediaError, mediaData } = useSelector((state: RootState) => state.media);

  const {userData} = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearTeams() as unknown as AnyAction);
    dispatch(clearMedia() as unknown as AnyAction);
    dispatch(resetGameState() as unknown as AnyAction);
    dispatch(resetInteraction() as unknown as AnyAction);
  });

  const navigateToPreLoginError = useCallback(
    (error: string) => {
      navigate(
        `${routes.preLoginError}${
          search ? search + '&' : '?'
        }error=${encodeURIComponent(error)}`,
      );
    },
    [search, navigate],
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      const error = 'Data preloading timed out';
      navigateToPreLoginError(error);
    }, PRELOAD_TIMEOUT_MILLISECONDS);
    return () => {
      clearTimeout(timeout);
    };
  }, [navigateToPreLoginError]);

  useEffect(() => {
    const requiredDataPreloaded =
      !!userData &&
      !!activeAppTeamId &&
      !isEmpty(teams) &&
      !!mediaData;


    if (!requiredDataPreloaded) {
      return;
    }
    navigate(`${routes.home}?bypassKey=${bypassStadiumKey}`);

  }, [activeAppTeamId, bypassStadiumKey, dispatch, navigate, mediaData, teams, userData]);

  useEffect(() => {
    if (teamsError) navigateToPreLoginError(teamsError);
    if (getMediaError) navigateToPreLoginError(getMediaError)
  }, [teamsError, navigateToPreLoginError, getMediaError]);


  return (
    <DefaultLayout>
      <TailSpin color="#00BFFF" height={75} width={75} />
    </DefaultLayout>
  );
}
