import {FormEvent, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import styled from '@emotion/styled';
import {useNavigate, useParams} from 'react-router-dom';
import {
  formatPhoneNumberIntl,
  isValidPhoneNumber,
} from 'react-phone-number-input';
import {RootState} from '../../redux/rootReducer';
import {
  clearPhoneNumberState,
  clearResendState, clearSubmitPhoneNumberError, clearVerifyPhoneNumberError,
  submitPhoneNumber as submitPhoneNumberRedux,
  verifyPhoneNumber as verifyPhoneNumberRedux,
} from '../../redux/modules/authorization/standaloneSMS';
import DefaultLayout from '../../components/layouts/DefaultLayout';
import PhoneNumberInput from '../../components/inputs/PhoneNumberInput';
import InputContainer from '../../components/inputs/InputContainer';
import {Button} from '../../components/buttons/Button';
import {white} from '../../theme/vars';

import {AnyAction} from '@reduxjs/toolkit';
import HTLogo from '../../components/media/HTLogo';
import {Spacer} from '../../components/layouts/Spacer';
import EventWrapper from '../../wrappers/EventWrapper';
import { SMS_RESEND_SUCCESS_MESSAGE } from '../../constants/Global.constants';
import useToast from '../../hooks/useToast';
import routes from '../../constants/routes.constants';

const VERIFICATION_CODE_MIN_LENGTH = 4;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  margin-top: 40%;
  padding-left: 50px;
  padding-right: 50px;
`;

const SMSTextInfo = styled.div`
  color: ${white};
  font-family: 'Roboto-Regular';
  font-size: 0.75rem;
  letter-spacing: -0.29px;
  text-align: center;
  margin-top: 10px;
  p {
    margin-bottom: 5px;
  }
`;

const ValidationInstructions = styled.div`
  color: ${white};
  font-family: 'Roboto-Regular';
  font-size: 20px;
  text-align: center;
  div {
    margin-bottom: 5px;
  }
  margin-bottom: 40px;
`;

// TODO: Move to shared component
const ResetButton = styled.button`
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;

  /* inherit font & color from ancestor */
  color: inherit;
  font: inherit;
  line-height: normal;

  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
`;

const SecondaryActionButtons = styled.div`
  margin-top: 5px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SecondaryActionButton = styled(ResetButton)`
  margin-top: 0.75rem;
  color: ${white};
  font-family: 'Roboto-Regular';
  text-transform: uppercase;
  font-size: 0.75rem;
  text-align: center;
  &:hover {
    cursor: pointer;
  }
`;

enum SMSState {
  SUBMIT,
  VERIFY,
}

const SMSAuth = () => {
  const toast = useToast();
  const {
    country,
    phoneNumber: savedPhoneNumber,
    submittingPhoneNumber,
    submitPhoneNumberSuccess,
    submitPhoneNumberError,
    verifyingPhoneNumber,
    verifyPhoneNumberSuccess,
    verifyPhoneNumberError,
    isResend,
  } = useSelector((state: RootState) => state.authorization.standaloneSMS);
  const {userData: user} = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {gameId} = useParams<{gameId: string}>();

  const [smsState, setSMSState] = useState(SMSState.SUBMIT);
  const [phoneNumber, setPhoneNumber] = useState(savedPhoneNumber);
  const [verificationCode, setVerificationCode] = useState('');

  useEffect(() => {
    dispatch(clearPhoneNumberState());
  }, [dispatch]);

  useEffect(() => {
    if (submitPhoneNumberError) {
      toast.statusToast(submitPhoneNumberError, 'error');
      dispatch(clearSubmitPhoneNumberError() as unknown as AnyAction);
    }
  }, [toast, submitPhoneNumberError, dispatch]);

  useEffect(() => {
    if (verifyPhoneNumberError) {
      toast.statusToast(verifyPhoneNumberError, 'error');
      dispatch(clearVerifyPhoneNumberError() as unknown as AnyAction);
    }
  }, [dispatch, toast, verifyPhoneNumberError]);

  useEffect(() => {
    if (smsState === SMSState.VERIFY && isResend && submitPhoneNumberSuccess) {
      toast.statusToast(SMS_RESEND_SUCCESS_MESSAGE, 'success');
      dispatch(clearResendState()); // In case user needs to resend again
    }
  }, [toast, smsState, isResend, submitPhoneNumberSuccess, dispatch]);

  useEffect(() => {
    // Move to verify screen when submit succeeds
    if (phoneNumber && savedPhoneNumber && country) {
      if (smsState !== SMSState.VERIFY && submitPhoneNumberSuccess)
        setSMSState(SMSState.VERIFY);
    } else if (!phoneNumber || !country) {
      setSMSState(SMSState.SUBMIT);
    }
  }, [
    dispatch,
    smsState,
    submitPhoneNumberSuccess,
    phoneNumber,
    savedPhoneNumber,
    country,
  ]);

  useEffect(() => {
    if (verifyPhoneNumberSuccess) {
      dispatch(clearPhoneNumberState());
      if (gameId) {
        if (user?.isNewUser) {
          navigate(routes.accountSetup.replace(':gameId', gameId));
        } else {
          navigate(routes.inGame.replace(':gameId', gameId));
        }
      } else {
        navigate(routes.home);
      }

    }
  }, [verifyPhoneNumberSuccess, navigate, user, dispatch, gameId]);

  const submitPhoneNumber = (e:FormEvent, isResend: boolean = false) => {
    e.preventDefault();
    setVerificationCode('');
    dispatch(submitPhoneNumberRedux('US', phoneNumber, isResend) as unknown as AnyAction);
  };
  const verifyPhoneNumber = (e:FormEvent) => {
    e.preventDefault();
    dispatch(
      verifyPhoneNumberRedux(
        country,
        phoneNumber,
        verificationCode,
        '',
      ) as unknown as AnyAction,
    );
  };
  const resetPhoneNumber = () => {
    dispatch(clearPhoneNumberState());
    setPhoneNumber('');
  };

  return (
    <EventWrapper>
      <DefaultLayout noHeader>
        <Spacer top="67px" />
        <HTLogo width="242px" />
        <Container>
          {smsState === SMSState.SUBMIT && (
            <form onSubmit={submitPhoneNumber}>
              <PhoneNumberInput
                placeholder="Your Phone"
                phoneNumber={phoneNumber}
                setPhoneNumber={setPhoneNumber}
              />
              <Button
                disabled={
                  !phoneNumber ||
                  !isValidPhoneNumber(phoneNumber) ||
                  submittingPhoneNumber
                }
                type="submit"
              >
                Get Started
              </Button>
              <SMSTextInfo>
                <p>By tapping Get Started, an SMS will be sent.</p>
                <p>Message and data rates may apply.</p>
              </SMSTextInfo>
            </form>
          )}
          {smsState === SMSState.VERIFY && (
            <form onSubmit={verifyPhoneNumber}>
              <ValidationInstructions>
                <div>Enter the validation code sent to</div>
                <div>{formatPhoneNumberIntl(phoneNumber)}</div>
              </ValidationInstructions>
              <InputContainer
                name="verification-code"
                type="text"
                label="Your Code"
                inputMode="numeric"
                value={verificationCode}
                setValue={setVerificationCode}
              />
              <Button
                disabled={
                  verificationCode.length < VERIFICATION_CODE_MIN_LENGTH ||
                  verifyingPhoneNumber
                }
                type="submit"
              >
                Next
              </Button>
              <SecondaryActionButtons>
                <SecondaryActionButton
                  type="button"
                  onClick={(e: FormEvent) => submitPhoneNumber(e, true)}>
                  Resend code to {formatPhoneNumberIntl(phoneNumber)}
                </SecondaryActionButton>
                <SecondaryActionButton type="button" onClick={resetPhoneNumber}>
                  Enter a new number
                </SecondaryActionButton>
              </SecondaryActionButtons>
            </form>
          )}
        </Container>
      </DefaultLayout>
    </EventWrapper>
  );
};

export default SMSAuth;
