import styled from '@emotion/styled';

type Props = {
  top?: string;
  right?: string;
  bottom?: string;
  left?: string;
  flex?: boolean;
  center?: boolean;
  textCenter?: boolean;
};

export const Spacer = styled('div')<Props>`
  margin-top: ${props => props.top || '0'};
  margin-right: ${props => props.right || '0'};
  margin-bottom: ${props => props.bottom || '0'};
  margin-left: ${props => props.left || '0'};
  ${props => props.flex && 'flex: 1; display: flex;'}
  ${props => props.center && 'justify-content: center; align-items: center;'}
  ${props => props.textCenter && 'text-align: center;'}
`;
