export function isEmpty<T>(array?: T[]): boolean {
  return !array || array.length === 0;
}

// From https://stackoverflow.com/a/46700791/1814717
export function notEmpty<TValue>(
  value: TValue | null | undefined,
): value is TValue {
  return value !== null && value !== undefined;
}
