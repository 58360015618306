import styled from '@emotion/styled';
import {limeGreen} from '../../theme/vars';

const Status = styled.p`
  color: ${limeGreen};
  font-family: Roboto-Regular;
  font-size: 0.75rem;
  padding: 0.25rem 0 0 1rem;
  order: -1;
`;

type Props = {
  children?: string;
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function ({children}: Props) {
  return <Status className="status">{children}</Status>;
}
