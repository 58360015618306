import styled from '@emotion/styled';
import LiveIcon1 from '../../assets/icons/live@1x.png';
import LiveIcon2 from '../../assets/icons/live@2x.png';
import LiveIcon3 from '../../assets/icons/live@3x.png';
import LiveTablet from '../../assets/icons/liveTablet@3x.png';

const Container = styled.div`
  width: 15%;
  img {
    width: 100%;
  }
`;

const GameCardLive = () => {
  return (
    <Container>
      <img
        src={LiveIcon1}
        alt="live"
        srcSet={`
        ${LiveIcon2} 2x,
        ${LiveIcon3} 3x,
        ${LiveTablet} 680w
      `}
      />
    </Container>
  );
};

export default GameCardLive;
