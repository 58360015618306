import styled from '@emotion/styled';

// components
import GameCardLive from './GameCardLive';
import HTImage from '../media/HTImage';
import {Button} from '../buttons/Button';
import GameCardCountDown from './GameCardCountDown';

// style
import {black_50} from '../../theme/vars';

// types
import {Game} from '../../types/game';
import {AssetType} from '../../types/assetType';

// hooks
import useGameCardStatuses from '../../hooks/useGameCardStatuses';
import {HomeTurfUser} from '../../types/user';
import { useNavigate, useLocation } from 'react-router-dom';
import routes from '../../constants/routes.constants';
import useQuery from '../../hooks/useQuery';

const Container = styled.div`
  width: 100%;

  padding-left: 20px;
  padding-right: 20px;

  box-shadow: 0px 2px 5px 2px ${black_50};
  margin-bottom: 1rem;
  .game-card-container {
    padding: 0.75rem 0.75rem 0;
    min-height: 240px;
  }
  .game-card-team-logo-container {
    display: flex;
  }
  .game-card-team-logo {
    width: 50%;
    padding: 10% 10% 5%;
  }
  .game-card-team-logo:first-of-type {
    padding-right: 5%;
  }
  .game-card-team-logo:last-of-type {
    padding-left: 5%;
  }
`;

const GameInfoContainer = styled.div`
  margin-bottom: 1rem;

  h2 {
    text-align: center;
    font-family: Industry-Bold;
    font-size: 24px;
    line-height: 22px;
    letter-spacing: -0.384px;
    text-transform: uppercase;

    margin-bottom: 28px;
  }

  strong {
    text-align: center;
    font-family: Roboto-Bold;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.384px;
  }

  p {
    font-family: Roboto-Regular;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.384px;
  }
`;

const JoinButtonContainer = styled.div`
  margin-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
`;

const dateOptions: any = {weekday: 'long', month: 'long', day: 'numeric'};
const timeOptions: any = {
  hour: 'numeric',
  minute: 'numeric',
  hour12: true,
  timeZoneName: 'short',
};

type Props = {
  game: Game;
  activeTeamId: string;
  user: HomeTurfUser | null;
};

const GameCard = ({game, activeTeamId, user}: Props) => {
  const {homeTeam, awayTeam, id} = game;
  const gameDate = new Date(game.gameTime);
  const {showCountdown, isJoiningGame, canJoinGame} = useGameCardStatuses(
    activeTeamId,
    user?.testUser || false,
    game,
  );

  const navigate = useNavigate();
  const {search} = useLocation();
  const query = useQuery(search);

  const bypassStadiumKey = query.get('bypassKey');


  const formattedDate = new Intl.DateTimeFormat('en-US', dateOptions).format(
    gameDate,
  );

  const formattedTime = new Intl.DateTimeFormat('en-US', timeOptions).format(
    gameDate,
  );

  return (
    <Container>
      {!canJoinGame &&
        <GameInfoContainer>
          <h2>Next Game</h2>
          <strong>
            {awayTeam.name} at {homeTeam.name}
          </strong>
          <p>{formattedDate}</p>
          <p>{formattedTime}</p>
        </GameInfoContainer>
      }
      <HTImage type={AssetType.GameCard} teamId={activeTeamId} gameId={game.id}>
        <div className="game-card-container">
          {canJoinGame && <GameCardLive />}
          <div className="game-card-team-logo-container">
            <div className="game-card-team-logo">
              <HTImage
                type={AssetType.TeamLogo}
                teamId={homeTeam.id}
                altText={homeTeam.name}
              />
            </div>
            <div className="game-card-team-logo">
              <HTImage
                type={AssetType.TeamLogo}
                teamId={awayTeam.id}
                altText={awayTeam.name}
              />
            </div>
          </div>
        </div>
      </HTImage>
      {canJoinGame && (
        <JoinButtonContainer>
          <Button
            disabled={isJoiningGame as any}
            onClick={() => {
              navigate(routes.inGame.replace(':gameId', id).concat(`?bypassKey=${bypassStadiumKey}`));
            }}
            borderRadius="0 0 3px 3px">
            Join the Experience
          </Button>
        </JoinButtonContainer>
      )}
      {showCountdown && <GameCardCountDown startTime={game.gameTime} />}
    </Container>
  );
};

export default GameCard;
