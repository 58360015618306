import {useState} from 'react';

// components
import LoggedInLayout, {LoggedInLayoutProps} from './LoggedInLayout';
import Menu from '../menu/Menu';
import routes from '../../constants/routes.constants';
import {RootState} from '../../redux/rootReducer';
import {useDispatch, useSelector} from 'react-redux';
import GameMenuIcon from '../../assets/icons/GameMenuIcon';
import HomeMenuIcon from '../../assets/icons/HomeMenuIcon';
import logout from '../../assets/icons/logout.svg';
// import InGameLeaderboardMenuIcon from '../../assets/icons/InGameLeaderboardMenuIcon';
import {useNavigate} from 'react-router-dom';
import {exitGameTime} from '../../redux/modules/game';
import {MenuItemProps} from '../menu/MenuItem';
import DynamicMenuIcon from '../image/DynamicMenuIcon';
import { logoutUser } from '../../redux/modules/user';
import { AnyAction } from 'redux';
import styled from '@emotion/styled';
import MenuProfile from '../../assets/icons/MenuProfile';

type LogoutIconWrapperProps = {
  flip?: boolean;
}

const LogoutIconWrapper = styled.div`
  padding: 0 3px;
  -webkit-transform: ${(props: LogoutIconWrapperProps) => props.flip? 'scaleX(-1)' : ''};
  transform: ${(props: LogoutIconWrapperProps) => props.flip? 'scaleX(-1)' : ''};
`;

const InGameLayout = ({
  children,
  activeTeam,
  gameId,
}: Omit<LoggedInLayoutProps, 'setMenuOpen'>) => {
  const [open, setOpen] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const user = useSelector((state: RootState) => state.user.userData);
  const {isInHomeStadium} = useSelector((state: RootState) => state.geolocation);

  const extraMenuItems: MenuItemProps[] = [];

  const staticMenuItems: MenuItemProps[] = [
    // {
    //   name: 'Home',
    //   icon: <HomeMenuIcon />,
    //   route: routes.home,
    //   onClick: () => {
    //     dispatch(exitGameTime());
    //     navigate(routes.home);
    //   },
    // },
    {
      name: isInHomeStadium ? 'Multi-View' : 'Game',
      icon: <GameMenuIcon />,
      route: routes.inGame,
      onClick: () => navigate(routes.inGame.replace(':gameId', gameId)),
    },
    // {
    //   name: 'Leader Board',
    //   icon: <InGameLeaderboardMenuIcon />,
    //   route: routes.inGameLeaderboard,
    //   onClick: () => navigate(routes.inGameLeaderboard),
    // },
  ];

  if (user?.guestUser && gameId) {
    extraMenuItems.push(
      {
        name: 'Login/Register',
        icon: <LogoutIconWrapper flip><img width={26} height={26} src={logout} alt="icon" /></LogoutIconWrapper>,
        route: routes.smsAuth,
        onClick: () => navigate(routes.smsAuth.replace(':gameId', gameId)),
      }
    )
  } else {
    if (gameId) {
      extraMenuItems.push(
        {
          name: 'Edit Profile',
          icon: <MenuProfile />,
          route: routes.editProfile,
          onClick: () => {
            navigate(routes.editProfile.replace(':gameId', gameId));
          }
        }
      )
    }
    extraMenuItems.push(
      {
        name: 'Logout',
        icon: <LogoutIconWrapper><img width={26} height={26} src={logout} alt="icon" /></LogoutIconWrapper>,
        route: routes.initialLaunch,
        onClick: () => {
          dispatch(logoutUser() as unknown as AnyAction);
          navigate(routes.initialLaunch);
        },
      }
    )
  }

  const dynamicMenuItems: MenuItemProps[] = (activeTeam?.menuItems || []).map((item: any) => ({
    name: item.item_name,
    icon: <DynamicMenuIcon path={item.item_icon_path} src={item.item_icon_url} alt={`${item.item_name} icon`} />,
    route: item.item_url,
    onClick: () => window.open(item.item_url, '_blank'),
  }));

  return (
    <>
      <Menu
        open={open}
        onClose={() => setOpen(false)}
        username={user ? user.username : ''}
        avatar={user?.profilePicPath ? user.profilePicPath : ''}
        isGuestUser={!!user?.guestUser}
        menuItems={[ ...staticMenuItems, ...dynamicMenuItems, ...extraMenuItems ]}
        gameId={gameId}
      />
      <LoggedInLayout
        setMenuOpen={() => setOpen(true)}
        includeMenu={true}>
        {children}
      </LoggedInLayout>
    </>
  );
};

export default InGameLayout;
