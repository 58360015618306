import {configureStore, Action} from '@reduxjs/toolkit';
import {ThunkAction} from 'redux-thunk';
import storage from 'redux-persist/lib/storage';
import {
  persistReducer,
  persistStore,
  // FLUSH,
  // REHYDRATE,
  // PAUSE,
  // PERSIST,
  // PURGE,
  // REGISTER,
} from 'redux-persist';
import {
  createWhitelistFilter,
  createBlacklistFilter,
} from 'redux-persist-transform-filter';

import rootReducer, {RootState} from './rootReducer';
import devToolsEnhancer from 'remote-redux-devtools';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

const videoPlayerWhitelistFilter = createWhitelistFilter('videoPlayer', [
  'metadata',
  'token',
]);

const audioPlayerWhitelistFilter = createWhitelistFilter('audioPlayer', [
  'audioManifest',
]);

const interactionBlacklistFilter = createBlacklistFilter('pusher', [
  'gameState',
]);

const gameBlacklistFilter = createBlacklistFilter('game', [
  'watchPartyConnected',
  'config.isChatOpen',
]);


const rootPersistConfig = {
  key: 'root',
  storage,
  blacklist: ['accountSetup', 'geolocation', 'radio'],
  transforms: [
    gameBlacklistFilter,
    videoPlayerWhitelistFilter,
    audioPlayerWhitelistFilter,
    interactionBlacklistFilter,
  ],
  stateReconciler: autoMergeLevel2,
  // migrate: (state) => {
  //   console.log('Migration Running!')
  //   return Promise.resolve(state)
  // }
};

const persistedReducer = persistReducer<RootState>(
  rootPersistConfig,
  rootReducer,
);

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production' && !process.env.REDUX_REMOTE,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
      // serializableCheck: {
      //   ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      // },
    }),
  ...(process.env.NODE_ENV !== 'production' &&
    process.env.REDUX_REMOTE && {
      enhancers: [
        devToolsEnhancer({
          realtime: true,
          name: 'HomeTurf',
          hostname: 'localhost',
          port: 8000,
        }),
      ],
    }),
});

declare const module: any;
if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./rootReducer', () => {
    const newRootReducer = require('./rootReducer').default;
    store.replaceReducer(newRootReducer);
  });
}

export type AppDispatch = typeof store.dispatch;
export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;

export const persistor = persistStore(store);

export default store;
