/**
 * We may not need this any longer since we've changed the login/join game flow
 * to accommodate guest users. Leaving it here for now in case it turns out we do.
 * 9/9/23
 */
import EventWrapper from './EventWrapper';

type Props = {
  children: React.ReactNode;
};

const LoggedInViewWrapper = ({children}: Props) => {
  return (
    <EventWrapper>{children}</EventWrapper>
  );
};

export default LoggedInViewWrapper;
