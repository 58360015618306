import {useRef, useState} from 'react';
import {useSelector} from 'react-redux'; // TODO: useSelector for google email, etc.
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';

// components
import EditPersonalForm from '../../components/forms/EditPersonalForm';
import EditAccountForm from '../../components/forms/EditAccountForm';

//constants
import {
  ACCESSIBILITY_URL,
  // PRIVACY_POLICY_URL,
} from '../../constants/Url.constants';

//utils
import {RootState} from '../../redux/rootReducer';
import ProfileCard from '../../components/layouts/ProfileCard';
import {
  black,
  defaultThemeColor,
  curiousBlue,
  eastBay,
  white,
} from '../../theme/vars';

import Link from '../../components/typography/Link';

import {css} from '@emotion/react';
import {FormStyle} from '../../theme/form';
import LoggedInViewWrapper from '../../wrappers/LoggedInViewWrapper';
// import {HomeTurfUser} from '../../types/user';
import useToast from '../../hooks/useToast';
// import ProfilePictureInput from '../../components/inputs/ProfilePictureInput';
// import { setAvatarSource } from '../../redux/modules/accountSetup/avatarSource';
// import { AnyAction } from 'redux';
import InGameLayout from '../../components/layouts/InGameLayout';
import routes from '../../constants/routes.constants';
import { useParams } from 'react-router';

const Container = styled.div`
  display: flex;
  //height: calc(100vh - 132px);
  flex: 1;
  width: 100%;
  overflow-y: scroll;
`;

const ProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  //height: calc(100vh - 132px);
  padding: 2rem;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  .userName {
    color: ${white};
    font-family: 'Industry-UltraItalic';
    font-size: 1rem;
    margin-bottom: 1rem;
  }

  .bodyText {
    color: ${eastBay};
    font-family: 'Roboto-Regular';
    font-size: 0.8rem;
    white-space: pre-wrap;
    line-height: 1.5;
  }

  .sharingInfoText {
    color: ${defaultThemeColor};
    font-family: 'Roboto-Regular';
    font-size: 0.7rem;
  }

  .participationContainer {
    display: flex;
    justify-content: space-between;
    padding: 2rem 0;
  }

  .participationText {
    color: ${defaultThemeColor};
    font-family: 'Roboto-Regular';
    font-size: 1rem;
  }
`;

const labelStyle = css`
  color: ${defaultThemeColor};
  font-family: Roboto-Bold;
  font-size: 0.8rem;
  padding-bottom: 5px;
`;

const inlineFormStyle: FormStyle = {
  field: {
    input: {
      label: labelStyle,
      input: (props: {disabled: boolean}) => css`
        color: ${defaultThemeColor};
        background-color: ${white};
        border: 1px ${props.disabled ? white : curiousBlue} solid;
        border-radius: 3px;
        font-family: 'Roboto-Regular';
        font-size: 0.8rem;
        height: 2rem;
        padding: 1px 4px;
        width: 100%;
        &:disabled {
          opacity: 1;
        }
      `,
      self: css`
        position: relative;
        margin-bottom: 0.6rem;
        width: 100%;
        display: flex;
        flex-direction: column-reverse;
      `,
    },
    select: {
      label: labelStyle,
      select: (props: {disabled: boolean}) => css`
        display: grid;
        grid-template-areas: 'select';
        align-items: center;
        position: relative;
        height: 2rem;
        width: 100%;
        border: 1px ${props.disabled ? white : curiousBlue} solid;
        border-radius: 3px;
        cursor: ${props.disabled ? 'inherit' : 'pointer'};
        background-color: ${white};
        &:after {
          visibility: ${props.disabled ? 'hidden' : 'visible'};
          content: '';
          position: absolute;
          right: 0.25rem;
          top: 0.5rem;
          width: 1rem;
          height: 1rem;
          background: center / contain no-repeat
            url(../../assets/images/chevronDown.png);
          filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(334deg)
            brightness(104%) contrast(101%);
        }
        select {
          appearance: none;
          background-color: transparent;
          border: none;
          color: ${defaultThemeColor};
          opacity: 1;
          padding: 1px 4px;
          margin: 0;
          width: 100%;
          font-family: 'Roboto-Regular';
          font-size: 0.8rem;
          cursor: inherit;
          line-height: inherit;
          z-index: 1;
          outline: none;
        }
        selectOption {
          color: ${black};
        }
      `,
      self: css`
        position: relative;
        margin-bottom: 0.6rem;
        width: 100%;
      `,
    },
  },
};

const EditProfile = () => {
  const toast = useToast();
  const navigate = useNavigate();
  // const dispatch = useDispatch();
  const {userData} = useSelector((state: RootState) => state.user);
  const {activeTeam} = useSelector((state: RootState) => state.teams);
  const {gameId: gameIdFromStore} = useSelector((state: RootState) => state.game);
  const {gameId: gameIdFromUrl} = useParams<{gameId?: string}>();
  // Next line needed for avatar
  // const {username, profilePicPath, id} = userData as HomeTurfUser;

  const [editPersonalButtonOpen, setEditPersonalButtonOpen] = useState(false);
  const [editAccountButtonOpen, setEditAccountButtonOpen] = useState(false);

  const editPersonalFormikRef = useRef<any | null>(null);
  // TODO: Fix this
  const editAccountFormikRef = useRef<any | null>(null);

  // const showUploadScreen = (e: any) => {
  //   if (e.target.value) {
  //     dispatch(
  //       setAvatarSource({
  //         userId: id,
  //         avatarImageSource: e.target,
  //       }) as unknown as AnyAction,
  //     );
  //   }
  // };

  // const {avatarSource: avatarSourceData} = useSelector(
  //   (state: RootState) => state.accountSetup,
  // );
  // const {isSettingAvatar} = avatarSourceData;

  if (!userData) navigate(routes.initialLaunch);

  return (
    <LoggedInViewWrapper>
      <InGameLayout activeTeam={activeTeam} gameId={gameIdFromUrl || gameIdFromStore || ''}>
        <Container>
          <ProfileContainer>
            {/*<ProfilePictureInput*/}
            {/*  isSettingAvatar={isSettingAvatar}*/}
            {/*  avatarImageSource={profilePicPath}*/}
            {/*  showUploadScreen={showUploadScreen}*/}
            {/*/>*/}
            {/*<div className="userName">{username}</div>*/}
            <ProfileCard
              title="Account Info"
              isEditable={true}
              isOpenable={false}
              editing={editAccountButtonOpen}
              onPressEdit={() => {
                if (!editAccountButtonOpen) {
                  setEditAccountButtonOpen(true);
                } else {
                  editAccountFormikRef.current.handleSubmit();
                }
              }}
              onPressCancel={() => {
                editAccountFormikRef.current.handleReset();
                setEditAccountButtonOpen(false);
              }}>
              <EditAccountForm
                isEditable={editAccountButtonOpen}
                onUpdate={() => {
                  toast.statusToast('Updated Account Info Successfully...', 'success');
                  setEditAccountButtonOpen(false);
                }}
                formStyle={inlineFormStyle}
                ref={editAccountFormikRef}
              />
            </ProfileCard>
            <ProfileCard
              title="Personal Info"
              isEditable={true}
              isOpenable={false}
              editing={editPersonalButtonOpen}
              onPressEdit={() => {
                if (!editPersonalButtonOpen) {
                  setEditPersonalButtonOpen(true);
                } else {
                  editPersonalFormikRef.current.handleSubmit();
                }
              }}
              onPressCancel={() => {
                editPersonalFormikRef.current.handleReset();
                setEditPersonalButtonOpen(false);
              }}>
              <EditPersonalForm
                isEditable={editPersonalButtonOpen}
                onUpdate={() => {
                  toast.statusToast('Updated Personal Info Successfully...', 'success');
                  setEditPersonalButtonOpen(false);
                }}
                formStyle={inlineFormStyle}
                ref={editPersonalFormikRef}
              />
            </ProfileCard>
            <ProfileCard title="Accessibility" hrVisible={true}>
              <div className="bodyText">
                At HomeTurf, we are committed to ensuring that our website and
                mobile applications are accessible to everyone, including
                individuals with disabilities.{'\n'}
                {'\n'}
                <Link url={ACCESSIBILITY_URL}>View</Link> our accessibility
                commitment.
              </div>
            </ProfileCard>
          </ProfileContainer>
        </Container>
      </InGameLayout>
    </LoggedInViewWrapper>
  );
};

export default EditProfile;
