import { TypeOptions } from 'react-toastify';
import {ReactComponent as Success} from '../../assets/icons/usernameAvailable.svg';
import {ReactComponent as Error} from '../../assets/icons/red-x.svg';
import GenericToastMessage from './GenericToastMessage';

type Props = {
  status: TypeOptions;
  statusText: any;
};

const StatusToastMessage = ({status, statusText}: Props) => {
  return (
    <GenericToastMessage
      rightContainer={status === 'success' ? <Success /> : <Error />}>
      {statusText}
    </GenericToastMessage>
  );
};

export default StatusToastMessage;
