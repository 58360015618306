import {RootState} from '../../rootReducer';
import {createEntityAdapter, createSlice} from '@reduxjs/toolkit';
import {request, INTERACTIONS_ROUTES} from '../../../api';
import {AppThunk} from '../../store';

type SliceState = {
  submittingTrivia: boolean;
  success: any | null;
  error: any | null;
  step: {
    interactionId: string | null;
    timeStarted: number | null;
    current?: any;
  };
};

const initialValueState: SliceState = {
  submittingTrivia: false,
  success: null,
  error: null,
  step: {
    interactionId: null,
    timeStarted: null,
    current: null,
  },
};

const resultsAdapter = createEntityAdapter({
  selectId: (result: any) => result.interactionId,
});

const gameStatusAdapter = createEntityAdapter({
  selectId: (gameStatus: any) => gameStatus.gameId,
});

const initialState = {
  ...initialValueState,
  results: resultsAdapter.getInitialState(),
};

const triviaSlice = createSlice({
  name: 'trivia',
  initialState: gameStatusAdapter.getInitialState(initialState),
  reducers: {
    updateGameStatus: gameStatusAdapter.upsertOne,
    setStep(state, action) {
      state.step = {
        timeStarted: Date.now(),
        current: action.payload.step,
        interactionId: action.payload.interactionId,
      };
    },
    submitTriviaRequest(state) {
      state.submittingTrivia = true;
      state.error = null;
    },
    submitTriviaSuccess(state) {
      state.submittingTrivia = false;
      state.success = true;
    },
    submitTriviaError(state, action) {
      state.submittingTrivia = false;
      state.error = action.payload.error;
    },
    clearTriviaStatus(state) {
      state.submittingTrivia = false;
      state.success = null;
      state.error = null;
    },
    triviaResults(state, action) {
      resultsAdapter.addOne(state.results, action.payload);
    },
    removeTriviaResults(state, action) {
      resultsAdapter.removeOne(state.results, action.payload);
    },
    clearTrivia(state) {
      return {...state, ...initialState};
    },
  },
});

export const {
  updateGameStatus,
  setStep,
  submitTriviaRequest,
  submitTriviaSuccess,
  submitTriviaError,
  clearTriviaStatus,
  triviaResults,
  removeTriviaResults,
  clearTrivia,
} = triviaSlice.actions;

const apiSubmitTrivia = async (body: any) => {
  return request({
    route: INTERACTIONS_ROUTES.SUBMIT_TRIVIA,
    body: body,
  });
};

export const handleTriviaDetails =
  ({body}: {body: any}): AppThunk =>
  async dispatch => {
    dispatch(submitTriviaRequest());
    try {
      const {err} = await apiSubmitTrivia(body);
      if (err) {
        return dispatch(submitTriviaError((err as Error).message));
      }
      dispatch(submitTriviaSuccess());
    } catch (err) {
      dispatch(submitTriviaError((err as Error).message));
    }
  };

export const handleTriviaCompletion =
  ({interactionId}: {interactionId: any}): AppThunk =>
  async dispatch => {
    dispatch(removeTriviaResults(interactionId));
  };

export const {selectById: selectGameStatusById} =
  gameStatusAdapter.getSelectors((state: RootState) => state.trivia);

export const {selectById: selectResultById} = resultsAdapter.getSelectors(
  (state: RootState) => state.trivia.results,
);

export default triviaSlice.reducer;
