import * as Yup from 'yup';

export const phoneValidation = Yup.object().shape({
  phone: Yup.string()
    .min(10, 'Please Provide Valid Number')
    .matches(/^[0-9]*$/, 'Please provide only numeric values')
    .required('Phone Number Required'),
});

export const verifyValidation = Yup.object().shape({
  code: Yup.string()
    .required('Code Required')
    .min(6, 'Please Provide 6 Digit Code'),
});

const fields = {
  firstName: Yup.string()
    .required('First name is required'),
  lastName: Yup.string()
    .required('Last name is required'),
  username: Yup.string()
    .required()
    .min(4, 'Usernames must be 4 or more characters')
    .required('Username Required'),
  emailAddress: Yup.string()
    .required()
    .email('Please provide valid email address')
    .required('Email address Required'),
  zipCode: Yup.string()
    .length(5, 'Please Provide 5 Digit Zip Code')
    .matches(/^[0-9]*$/, 'Please provide only numeric values')
    .nullable()
    .required('Zip Code Required'),
  country: Yup.string().nullable().required('Country Required'),
  city: Yup.string().nullable().required('City Required'),
};

export const accountValidation = Yup.object().shape({
  username: fields.username,
  emailAddress: fields.emailAddress,
  firstName: fields.firstName,
  lastName: fields.lastName
});

export const intAccountValidation = Yup.object().shape({
  username: fields.username,
  emailAddress: fields.emailAddress,
  country: fields.country,
  city: fields.city,
});

export const profileValidation = Yup.object().shape({
  zipCode: fields.zipCode,
  country: fields.country,
});

export const intProfileValidation = Yup.object().shape({
  country: fields.country,
  city: fields.city,
});

export const updateAccountValidation = Yup.object().shape({
  phone: Yup.string()
    .min(10, 'Please Provide Valid Number')
    .matches(/^[0-9]*$/, 'Please provide only numeric values'),
  emailAddress: Yup.string()
    .nullable()
    .email('Please provide valid email address'),
});

export const updatePersonalValidation = Yup.object().shape({
  zipCode: fields.zipCode,
  country: fields.country,
});

export const updateIntPersonalValidation = Yup.object().shape({
  country: fields.country,
  city: fields.city,
});

export const seasonTicketValidation = Yup.object().shape({
  ticketNumber: Yup.string()
    .min(3, 'Please Provide Valid Number')
    .required('Valid Number Required'),
});

export const updateAccountInfoValidation = Yup.object().shape({
  username: fields.username,
  emailAddress: fields.emailAddress,
});
