import React from 'react';
import styled from '@emotion/styled';

const Container = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 2.5rem;
  height: 2.5rem;
  img {
    width: 20px;
    height: 20px;
  }
`;

type Props = {
  alt: string;
  icon: string;
  onClick: () => void;
};

const ButtonIcon = ({alt, icon, onClick}: Props) => {
  return (
    <Container onClick={onClick}>
      <img src={icon} alt={alt} />
    </Container>
  );
};

export default ButtonIcon;
