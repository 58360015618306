import LiveGameProvider from '../providers/LiveGameProvider';
import React from 'react';
import LoggedInViewWrapper from './LoggedInViewWrapper';

type Props = {
  children: React.ReactNode;
};

const GameViewWrapper = ({children}: Props) => {
  return (
    <LoggedInViewWrapper>
      <LiveGameProvider>{children}</LiveGameProvider>
    </LoggedInViewWrapper>
  );
};

export default GameViewWrapper;
