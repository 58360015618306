// TODO: Error handling strategy

import {getAuthToken, getUserId} from '../utils/cookies';
import {environment} from '../environments/environment';
import routes, {PRE_LOGIN_ROUTES} from '../constants/routes.constants';

enum REQUEST_TYPE {
  GET,
  POST,
  PUT,
  PATCH,
  DELETE,
}

type RouteDetail = {
  path: string;
  type: REQUEST_TYPE;
};

type RoutesHashMap = {
  [key: string]: RouteDetail;
};

export const BASE_API_ROUTE = '/v1/api';

export const PUSHER_AUTH_ROUTE: RoutesHashMap = {
  PUSHER_AUTH: {
    path: `${BASE_API_ROUTE}/pusher/auth/{userId}`,
    type: REQUEST_TYPE.POST,
  },
};

export const BASE_ROUTES: RoutesHashMap = {
  CHECK_USERNAME: {
    path: `${BASE_API_ROUTE}/username-check/{username}`,
    type: REQUEST_TYPE.GET,
  },
  TEAMS: {
    path: `${BASE_API_ROUTE}/teams`,
    type: REQUEST_TYPE.GET,
  },
  GAME_SCHEDULE: {
    path: `${BASE_API_ROUTE}/games/schedule/teams/{teamId}/next`,
    type: REQUEST_TYPE.GET,
  },
  LIVE_CHANNEL_CALIBRATION: {
    path: `${BASE_API_ROUTE}/audio-recognition/live-channel-calibration`,
    type: REQUEST_TYPE.GET,
  },
};

export const BASE_LOGIN_ROUTE = `${BASE_API_ROUTE}/auth/login`;
export const LOGIN_ROUTES: RoutesHashMap = {
  SUBMIT: {
    path: `${BASE_LOGIN_ROUTE}`,
    type: REQUEST_TYPE.POST,
  },
  VERIFY: {
    path: `${BASE_LOGIN_ROUTE}/confirm`,
    type: REQUEST_TYPE.POST,
  },
  GET_USER_AND_APP_TOKENS: {
    path: `${BASE_LOGIN_ROUTE}-auth0`,
    type: REQUEST_TYPE.POST,
  },
  TEST_USER_LOGIN: {
    path: `${BASE_LOGIN_ROUTE}-test`,
    type: REQUEST_TYPE.POST,
  },
  CLEAR_TEST_USER: {
    path: `${BASE_LOGIN_ROUTE}-test`,
    type: REQUEST_TYPE.DELETE,
  },
};

export const BASE_USER_ROUTE = `${BASE_API_ROUTE}/users/{userId}`;

export const USER_ROUTES: RoutesHashMap = {
  SUBMIT_ACCOUNT_DETAILS: {
    path: BASE_USER_ROUTE,
    type: REQUEST_TYPE.PUT,
  },
  GET_USER: {
    path: BASE_USER_ROUTE,
    type: REQUEST_TYPE.GET,
  },
  UPDATE_USER_PRIVACY: {
    path: `${BASE_USER_ROUTE}/privacy`,
    type: REQUEST_TYPE.PATCH,
  },
  UPDATE_USER_ANALYTICS: {
    path: `${BASE_USER_ROUTE}/analytics`,
    type: REQUEST_TYPE.PATCH,
  },
  UPDATE_USER_ACCOUNT_INFO: {
    path: `${BASE_USER_ROUTE}/accountInfo`,
    type: REQUEST_TYPE.PATCH,
  },
  UPDATE_USER_PERSONAL_INFO: {
    path: `${BASE_USER_ROUTE}/personalInfo`,
    type: REQUEST_TYPE.PATCH,
  },
  UPDATE_USER_HOME_TURF: {
    // Not for embedded flow; need to address users with wrong HomeTurf
    path: `${BASE_USER_ROUTE}/homeTurf`,
    type: REQUEST_TYPE.POST,
  },
  // Not all teams have season tickets, TODO: Determine how to load team data + enable in on-boarding flow
  UPDATE_USER_SEASON_TICKET: {
    // TODO: Migrate to own separate table (backend) for multiple HT support
    path: `${BASE_USER_ROUTE}/seasonTicket`,
    type: REQUEST_TYPE.PATCH,
  },
  POST_USER_AVATAR: {
    // Upload picture and obtain URL
    path: `${BASE_USER_ROUTE}/profile-image-url`,
    type: REQUEST_TYPE.POST,
  },
  UPDATE_USER_PROFILE_PICTURE: {
    // Save URL from above to profile
    path: `${BASE_USER_ROUTE}/profilePicture`,
    type: REQUEST_TYPE.PATCH,
  },
  SUBMIT_CHAT_MESSAGE: {
    path: `${BASE_USER_ROUTE}/chat`,
    type: REQUEST_TYPE.POST,
  },
  GET_PARTICIPATION_TEAMS: {
    path: `${BASE_USER_ROUTE}/participation/teams`,
    type: REQUEST_TYPE.GET,
  },
  GET_PARTICIPATION_TEAM_GAMES: {
    path: `${BASE_USER_ROUTE}/participation/teams/{teamId}/games`,
    type: REQUEST_TYPE.GET,
  },
  GENERATE_GUEST_USER: {
    path: `${BASE_API_ROUTE}/auth/guest`,
    type: REQUEST_TYPE.POST,
  },
};

const BASE_GAMES_ROUTE = `${BASE_API_ROUTE}/games`;

export const GAME_ROUTES: RoutesHashMap = {
  JOIN_GAME: {
    path: `${BASE_GAMES_ROUTE}/{gameId}/teams/{teamId}/user/{userId}/join-web`,
    type: REQUEST_TYPE.POST,
  },
  GAME_SCHEDULE_REQUEST: {
    path: `${BASE_GAMES_ROUTE}/scheduled`,
    type: REQUEST_TYPE.GET,
  },
  TEAM_SCHEDULE_REQUEST: {
    path: `${BASE_GAMES_ROUTE}/schedule/teams/{teamId}`,
    type: REQUEST_TYPE.GET,
  },
  WEEK_SCHEDULE_REQUEST: {
    path: `${BASE_GAMES_ROUTE}/schedule/weeks/{week}`,
    type: REQUEST_TYPE.GET,
  },
};

const BASE_LEADERBOARD_ROUTE = `${BASE_API_ROUTE}/leaderboards`;

export const LEADERBOARD_ROUTES: RoutesHashMap = {
  USERS_TEAM_LEADERBOARD: {
    path: `${BASE_LEADERBOARD_ROUTE}/teams/{teamId}/users/{userId}`,
    type: REQUEST_TYPE.GET,
  },
  USERS_TEAM_GAME_LEADERBOARD: {
    path: `${BASE_LEADERBOARD_ROUTE}/teams/{teamId}/games/{gameId}/users/{userId}`,
    type: REQUEST_TYPE.GET,
  },
};

const BASE_PARTICIPATION_ROUTE = `${BASE_API_ROUTE}/users/{userId}/participation/teams`;

export const PARTICIPATION_ROUTES: RoutesHashMap = {
  PARTICIPATION_TEAMS: {
    path: BASE_PARTICIPATION_ROUTE,
    type: REQUEST_TYPE.GET,
  },
  PARTICIPATION_TEAM_GAMES: {
    path: `${BASE_PARTICIPATION_ROUTE}/{teamId}/games`,
    type: REQUEST_TYPE.GET,
  },
};

const BASE_MEDIA_ROUTE = `${BASE_API_ROUTE}/manifest`;

export const MEDIA_ROUTES: RoutesHashMap = {
  BOOTSTRAP_MANIFEST_REQUEST: {
    path: `${BASE_MEDIA_ROUTE}/bootstrap-web/{teamId}`,
    type: REQUEST_TYPE.GET,
  },
  TEAM_AUDIO_MANIFEST_REQUEST: {
    path: `${BASE_MEDIA_ROUTE}/teams/{teamId}/sounds`,
    type: REQUEST_TYPE.GET,
  },
};

const BASE_LEADERBOARDS_ROUTE = 'leaderboards';

export const LEADERBOARDS_ROUTES: RoutesHashMap = {
  GET_LEADERBOARD: {
    path: `${BASE_LEADERBOARDS_ROUTE}top/user/{userId}`,
    type: REQUEST_TYPE.GET,
  },
  GET_TEAM_USER_LEADERBOARD: {
    path: `${BASE_LEADERBOARDS_ROUTE}teams/{teamId}/users/{userId}`,
    type: REQUEST_TYPE.GET,
  },
  GET_TEAM_GAME_USER_LEADERBOARD: {
    path: `${BASE_LEADERBOARDS_ROUTE}teams/{teamId}/games/{gameId}/users/{userId}`,
    type: REQUEST_TYPE.GET,
  },
};

const BASE_INTERACTIONS_ROUTE = `${BASE_API_ROUTE}/interactions`;

export const INTERACTIONS_ROUTES: RoutesHashMap = {
  SUBMIT_TEAM_SCREAM_RESULTS: {
    path: `${BASE_INTERACTIONS_ROUTE}/team-scream`,
    type: REQUEST_TYPE.POST,
  },
  SUBMIT_TRIVIA: {
    path: `${BASE_INTERACTIONS_ROUTE}/trivia`,
    type: REQUEST_TYPE.POST,
  },
  SUBMIT_POLL: {
    path: `${BASE_INTERACTIONS_ROUTE}/poll`,
    type: REQUEST_TYPE.POST,
  },
};

export const PROCESS_AUDIO_CAPTURE_ROUTE = {
  path: `${BASE_API_ROUTE}/calibration/live-channel`,
  type: REQUEST_TYPE.POST,
};

export const PROCESS_TEAM_SCREAM_ROUTE = {
  path: `${BASE_API_ROUTE}/interactions/team-scream`,
  type: REQUEST_TYPE.POST,
};

export const CREATE_WATCH_PARTY_ROUTE = {
  path: `${BASE_API_ROUTE}/users/{userId}/watchparty`,
  type: REQUEST_TYPE.POST,
};

export const FETCH_WATCH_PARTY_ROUTE = {
  path: `${BASE_API_ROUTE}/watchparty/{shortcode}`,
  type: REQUEST_TYPE.GET,
};

const CONTENT_TYPE_HEADER = 'content-type';
const JSON_TYPE = 'application/json';

export const getHeaders = (
  token: string,
  extraHeaders: Record<string, string> = {},
  usesFormData: boolean,
) => {
  const baseHeaders = {
    Authorization: `Bearer ${token}`,
    Accept: 'text/plain',
    ClientId: 'HomeTurfApp',
  } as any;
  if (!usesFormData) baseHeaders['Content-Type'] = 'application/json';
  return {
    ...baseHeaders,
    ...extraHeaders,
  };
};

const appendSearchParams = (
  url: URL,
  searchParams: Record<string, string | string[]>,
) => {
  if (searchParams)
    Object.keys(searchParams).forEach(key => {
      const param = searchParams[key];
      if (Array.isArray(param)) {
        param.forEach(listItem => url.searchParams.append(key, listItem));
      } else {
        url.searchParams.append(key, param);
      }
    });
};

const createRequestPath = (
  path: string,
  pathParams: Record<string, string> = {},
  searchParams: Record<string, string | string[]> = {},
) => {
  const updatedPath = Object.entries(pathParams).reduce((acc, [key, value]) => {
    return acc.replace(`{${key}}`, value);
  }, path);

  //NOTE: this code assumes you are always using a qualified domain and not running under localhost.
  const domainParts = window.location.hostname.split('.'); // will be length 2 or 3
  const domain = domainParts.slice(domainParts.length === 2 ? 0 : 1).join('.');
  let apiUrl = `https://${environment.baseUrl}.${domain}`;
  if (environment.basePort && environment.basePort !== '') {
    apiUrl = `${apiUrl}:${environment.basePort}`
  }
  const requestUrl = new URL(`${apiUrl}${updatedPath}`);
  appendSearchParams(requestUrl, searchParams);
  return requestUrl.href;
};

const checkSuccess = (response: Response) => {
  if (response.status >= 200 && response.status < 300) return response;

  const contentType = response.headers.get(CONTENT_TYPE_HEADER);
  if (contentType && contentType.includes(JSON_TYPE)) {
    return response.json().then(data => {
      throw new Error(data.message || `Non-successful response code: ${response.status}, ${response.statusText}`);
    });
  } else {
    throw new Error(
      `Non-successful response code: ${response.status}, ${response.statusText}`,
    );
  }
};

const checkStatus: (value: Response) => Response | PromiseLike<Response> = (
  response: Response,
) => {
  if (response.status === 401 || response.status === 403) {
    // TODO: Deal with 500s, prevent getting them because of lost team id (i.e. cleared local storage)

    // TODO: Consider replacing with lib to check paths
    // NOTE: Is this even necessary? What pre-login routes would generate 401/403?
    const pathname = window.location.pathname;
    const matchedPrelogin = [...PRE_LOGIN_ROUTES].some((route) => (pathname === `/${route.split('/')[0]}`));
    if (!matchedPrelogin) {
      // TODO: Should pop a "Your session expired" toast - maybe pass a param to initial launch in url
      window.location.replace(routes.initialLaunch);
    }
  }
  return response;
};

type RequestFunctionProps = {
  route: string;
  headers: Record<string, string>;
  body?: string | FormData;
  useTextResponse?: boolean;
  signal?: any;
};

type RequestFunction = (props: RequestFunctionProps) => Promise<any>; // eslint-disable-line

const requestForTypeMap = new Map<REQUEST_TYPE, RequestFunction>([
  [REQUEST_TYPE.GET, getRequest],
  [REQUEST_TYPE.POST, postRequest],
  [REQUEST_TYPE.PUT, putRequest],
  [REQUEST_TYPE.PATCH, patchRequest],
  [REQUEST_TYPE.DELETE, deleteRequest],
]);

export async function putS3(
  filename: string,
  contentType: any,
  signedS3Url: any,
  avatarImageSource: any,
) {
  return putRequest({
    route: signedS3Url,
    headers: {
      'x-amz-acl': 'public-read',
      'Content-Type': contentType,
    },
    body: avatarImageSource,
    useTextResponse: true,
  });
}

export async function request({
  route,
  pathParams = {},
  searchParams = {},
  body = {},
  formData,
  extraHeaders = {},
  useTextResponse = false,
  signal,
}: {
  route: RouteDetail;
  pathParams?: Record<string, string>;
  searchParams?: Record<string, string | string[]>;
  body?: Record<string, unknown>;
  formData?: FormData;
  extraHeaders?: Record<string, string>;
  useTextResponse?: boolean;
  signal?: any;
}) {
  const token = getAuthToken() as any; // TODO: Fix this
  const userId = getUserId() as any; // TODO: Fix this
  const path = createRequestPath(
    route.path,
    {...pathParams, userId},
    searchParams,
  );
  const headers = getHeaders(
    token,
    userId ? {...extraHeaders, userId} : extraHeaders,
    !!formData,
  );
  const method = requestForTypeMap.get(route.type);
  if (!method) return;
  return method({
    route: path,
    headers,
    body: formData || JSON.stringify(body),
    useTextResponse,
    signal,
  });
}

const getData = (response: Response, useTextResponse: boolean) => {
  return useTextResponse ? response.text() : response.json();
};

// Body unused for getRequest, but added for now to conform to RequestFunction
async function getRequest({route, headers, signal}: RequestFunctionProps) {
  const response = await fetch(route, {
    method: 'GET',
    headers,
    credentials: 'include',
    signal,
  })
    .then(checkStatus)
    .then(checkSuccess);
  return response.json();
}

async function postRequest({
  route,
  headers,
  body,
  useTextResponse = false,
  signal,
}: RequestFunctionProps) {
  const response = await fetch(route, {
    method: 'POST',
    headers,
    body,
    credentials: 'include',
    signal,
  })
    .then(checkStatus)
    .then(checkSuccess);

  return getData(response, useTextResponse);
}

async function putRequest({
  route,
  headers,
  body,
  useTextResponse = false,
  signal,
}: RequestFunctionProps) {
  const response = await fetch(route, {
    method: 'PUT',
    headers,
    body,
    signal,
  })
    .then(checkStatus)
    .then(checkSuccess);

  return getData(response, useTextResponse);
}

async function patchRequest({
  route,
  headers,
  body,
  useTextResponse = false,
  signal,
}: RequestFunctionProps) {
  const response = await fetch(route, {
    method: 'PATCH',
    headers,
    body,
    signal,
  })
    .then(checkStatus)
    .then(checkSuccess);
  return getData(response, useTextResponse);
}

async function deleteRequest({
  route,
  headers,
  body,
  useTextResponse = false,
  signal,
}: RequestFunctionProps) {
  const response = await fetch(route, {
    method: 'DELETE',
    headers,
    body,
    signal,
  })
    .then(checkStatus)
    .then(checkSuccess);
  return getData(response, useTextResponse);
}
