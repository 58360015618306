import DefaultLayout from '../../components/layouts/DefaultLayout';
import {HeaderLarge} from '../../components/typography/HeaderLarge';
import {Spacer} from '../../components/layouts/Spacer';

export default function MissingTeam() {
  return (
    <DefaultLayout>
      <HeaderLarge>Missing Team ID</HeaderLarge>
      <Spacer top="2rem" />
    </DefaultLayout>
  );
}
