import React from 'react';
import HTHeader from '../headers/HTHeader';
import {white} from '../../theme/vars';
import styled from '@emotion/styled';
import MenuButton from '../buttons/MenuButton';

const BodyContainer = styled.div`
  display: flex;
  flex: 1;
  height: calc(100% - 88px);
`;

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  color: ${white};
  font-family: 'Roboto-Regular';

  .nav-link {
    color: white;
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    text-align: center;
    border: none;
    background: none;

    img {
      height: 30px;
      width: auto;
      pointer-events: none;
    }
  }

  .active {
    border-top: 3px solid #327ac2;
  }

  nav {
    display: flex;
    width: 100%;
    height: 60px;
    bottom: 0;
    justify-content: space-evenly;
    z-index: 1;
  }

  .navbar {
    padding-top: 0;
  }
`;

export type LoggedInLayoutProps = {
  children: React.ReactNode;
  setMenuOpen?: () => void;
  activeTeam?: any;
  gameId: string;
  includeMenu?: boolean;
};

const LoggedInLayout = ({
  children,
  setMenuOpen,
  includeMenu = false,
}: Omit<LoggedInLayoutProps, 'gameId'>) => {
  return (
    <Container>
      <HTHeader
        menuButton={
          includeMenu && setMenuOpen ? <MenuButton menuAction={setMenuOpen} /> : undefined
        }
      />
      <BodyContainer>
        {children}
      </BodyContainer>
    </Container>
  );
};

export default LoggedInLayout;
