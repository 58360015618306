import { useDispatch, useSelector } from 'react-redux';
import { useRef, useEffect } from 'react';
import { RootState } from '../../../redux/rootReducer';
import useToast from '../../../hooks/useToast';
import { stop } from '../../../redux/modules/radio';

const RadioPlayer = () => {
  const {isPlaying, streamUrl} = useSelector((state: RootState) => state.radio);
  const playerRef = useRef<HTMLAudioElement | null>(null);
  const toast = useToast();
  const dispatch = useDispatch();

  const errorHandler = () => {
    toast.statusToast('Unable to start audio player.', 'error');
    dispatch(stop);
  }

  const endHandler = () => {
    dispatch(stop);
  }

  useEffect(() => {
    if (isPlaying && !!playerRef?.current && streamUrl) {
      playerRef.current.play();
    }
  }, [isPlaying, playerRef, streamUrl]);

  if (!streamUrl) return null;

  return (
    <>
      {/* Mount & dismount the audio element based on play state so the stream
      always plays live when the user toggles the radio 'on' */}
      {isPlaying && streamUrl &&  (
        <audio ref={playerRef}>
          <source src={streamUrl} type='audio/mpeg' onError={errorHandler} onAbort={errorHandler} onEnded={endHandler} />
        </audio>
      )}

    </>
  );
};

export default RadioPlayer;
