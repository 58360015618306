import {useState} from 'react';
import styled from '@emotion/styled';

// components
import {BodyText} from '../typography/BodyText';

// utils
import {clampBuilder} from '../../utils/style';

// style
import {black, white} from '../../theme/vars';

import DefaultImage from '../../assets/icons/avatar.svg';

type ContainerProps = {
  large: boolean;
};

const Container = styled.div<ContainerProps>`
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;

  .avatar-container {
    position: relative;
  }

  img {
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
    width: ${({large}) =>
      large ? clampBuilder(5.5, 8) : clampBuilder(4.5, 6)};
    height: ${({large}) =>
      large ? clampBuilder(5.5, 8) : clampBuilder(4.5, 6)};
  }

  .rank-number {
    width: ${clampBuilder(2, 3)};
    height: ${clampBuilder(2, 3)};
    background: ${black};
    border: 1px solid ${white};
    border-radius: 50%;
    position: absolute;
    right: ${({large}) =>
      large ? clampBuilder(-1, -2) : clampBuilder(-0.5, -1)};
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    p {
      font-family: Industry-UltraItalic;
      font-size: ${clampBuilder(1, 2)};
    }
  }

  .total-points {
    font-family: Industry-UltraItalic;
    font-size: 1rem;
    font-size: ${clampBuilder(1.25, 2)};
    color: ${black};

    span {
      font-size: ${clampBuilder(0.75, 1)};
    }
  }
`;

type Props = {
  rowData: any;
  large?: boolean;
};

const LeaderboardTopRankUser = ({rowData, large = false}: Props) => {
  const {profilePicPath, rank, totalPoints, username} = rowData;
  const [image, setImage] = useState(
    profilePicPath ? profilePicPath : DefaultImage,
  );

  return (
    <Container large={large}>
      <div className="avatar-container">
        <img
          onError={() => setImage(DefaultImage)}
          src={image}
          alt={username}
          style={{ backgroundColor: black }}
        />
        <div className="rank-number">
          <BodyText>{rank}</BodyText>
        </div>
      </div>
      <BodyText color={black}>{username || 'user'}</BodyText>
      <span className="total-points">
        {totalPoints}
        <span>PTS</span>
      </span>
    </Container>
  );
};

export default LeaderboardTopRankUser;
