import {useCallback, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {
  EVERYONE_CHANNEL,
  PRIVATE_CHANNEL,
  GAME_TIME_CHANNEL,
} from '../constants/Pusher.constants';

import {RootState} from '../redux/rootReducer';
import {
  subscribeToPublicGenerator,
  unsubscribeFromPublicGenerator,
} from '../pusher/subscriptionHandlers/public';
import {
  subscribeToPrivateGenerator,
  unsubscribeFromPrivateGenerator,
} from '../pusher/subscriptionHandlers/private';
import {
  subscribeToGameTimeGenerator,
  unsubscribeFromGameTimeGenerator,
} from '../pusher/subscriptionHandlers/gameTime';
import useToast from './useToast';

const usePusherSubscribersAndHandlers = (pusherClient: any) => {
  const toast = useToast();
  const {currentSubscriptions} = useSelector(
    (state: RootState) => state.pusher,
  );

  const showPointsEarned = useCallback(
    (payload: any) => {
      toast.pointsToast(payload);
    },
    [toast],
  );

  const subscriptionHandlers = useMemo(() => {
    const defaultArgs = {
      pusherClient,
    };
    return {
      subscribeToPublicChannel: subscribeToPublicGenerator(defaultArgs),
      // TODO: Fix this
      subscribeToPrivateChannel: subscribeToPrivateGenerator({
        ...defaultArgs,
        showPointsEarned,
      }),
      // TODO: Fix this
      subscribeToGameTimeExperience: subscribeToGameTimeGenerator(
        defaultArgs as any,
      ),
      unsubscribeFromPublicChannel: unsubscribeFromPublicGenerator(defaultArgs),
      unsubscribeFromPrivateChannel:
        unsubscribeFromPrivateGenerator(defaultArgs),
      unsubscribeFromGameTimeExperience:
        unsubscribeFromGameTimeGenerator(defaultArgs),
    };
  }, [pusherClient, showPointsEarned]);

  const subscriptionMethodForChannelMap = useMemo(() => {
    return new Map<string, (data?: any) => void>([
      [EVERYONE_CHANNEL, subscriptionHandlers.subscribeToPublicChannel],
      [PRIVATE_CHANNEL, subscriptionHandlers.subscribeToPrivateChannel],
      [GAME_TIME_CHANNEL, subscriptionHandlers.subscribeToGameTimeExperience],
    ]);
  }, [subscriptionHandlers]);

  const onForegroundPusherHandler = () => {
    currentSubscriptions.forEach(({channel, data}) => {
      const subscriptionMethod = subscriptionMethodForChannelMap.get(channel);
      if (subscriptionMethod) subscriptionMethod(data);
      else
        console.warn(
          `Cannot re-subscribe to channel of type ${channel} - method not found`,
        );
    });
  };

  return {
    ...subscriptionHandlers,
    onForegroundPusherHandler,
  };
};

export default usePusherSubscribersAndHandlers;
