import {DividingLine} from '../../components/dividingLine/DividingLine';
import {Spacer} from '../../components/layouts/Spacer';
import HTLogo from '../../components/media/HTLogo';
import {BodyText} from '../../components/typography/BodyText';
import {HeaderLarge} from '../../components/typography/HeaderLarge';
import styled from '@emotion/styled';
import bgImage from '../../assets/images/fan-background.png';
import bgImage2 from '../../assets/images/fan-background@2x.png';
import bgImage3 from '../../assets/images/fan-background@3x.png';
import {white} from '../../theme/vars';
import { useSelector } from 'react-redux';
import {RootState} from '../../redux/rootReducer';

const BackgroundContainer = styled.div`
  display: flex;
  flex: 1;
  height: 100vh;
  flex-direction: column;

  background-image: url(${bgImage});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  @media screen and (min-width: 375px) {
    background-image: url(${bgImage2});
  }
  @media screen and (min-width: 750px) {
    background-image: url(${bgImage3});
  }
`;

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding: 0 2rem 5rem;

  a {
    color: ${white};
    font-family: Roboto-Bold;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 0.563px;
    text-decoration-line: underline;
  }
`;

const Under13 = () => {
  const {activeTeam} = useSelector((state: RootState) => state.teams);

  return (
    <BackgroundContainer>
      <Container>
        <Spacer top="67px" />
        <HTLogo width="242px" />
        <Spacer top="100px" />
        <HeaderLarge>
          Sorry, this experience is for Fans 13 years of age and older
        </HeaderLarge>
        <DividingLine />
        <BodyText>
          {`Visit the ${activeTeam.name} on `}{' '}
          <a href={activeTeam.homeUrl}>NHL.com</a> for the latest news, stats,
          and highlights.
        </BodyText>
      </Container>
    </BackgroundContainer>
  );
};

export default Under13;
