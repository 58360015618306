import styled from '@emotion/styled';
import {defaultInputLabel} from '../../theme/form';

type ContainerProps = {
  css: any;
};

const Label = styled.label<ContainerProps>`
  ${props => props.css};
`;

type Props = {
  htmlFor: string;
  labelStyle?: any;
  children?: string;
};

const InputLabel = ({
  htmlFor,
  labelStyle = defaultInputLabel,
  children,
}: Props) => {
  return (
    <Label css={labelStyle} htmlFor={htmlFor}>
      {children}
    </Label>
  );
};

export default InputLabel;
