import {css, Global} from '@emotion/react';
import {defaultBackgroundColor, darkBlue, white, curiousBlueActive} from './vars';

const GlobalStyles = () => {
  const RobotoBold = require('../assets/fonts/Roboto-Bold.ttf');
  const RobotoBlack = require('../assets/fonts/Roboto-Black.ttf');
  const RobotoBlackItalic = require('../assets/fonts/Roboto-BoldItalic.ttf');
  const RobotoBoldItalic = require('../assets/fonts/Roboto-BoldItalic.ttf');
  const RobotoRegular = require('../assets/fonts/Roboto-Regular.ttf');
  const AbolitionRegular = require('../assets/fonts/Abolition-Regular.otf');
  const ChairdrobeBlack = require('../assets/fonts/Chairdrobe-Black.ttf');
  const Digital7Italic = require('../assets/fonts/digital-7-italic.ttf');
  const DINCondensedBold = require('../assets/fonts/DIN-Condensed-Bold.ttf');
  const IndustryBlack = require('../assets/fonts/Industry-Black.ttf');
  const IndustryBlackItalic = require('../assets/fonts/Industry-BlackItalic.ttf');
  const IndustryBold = require('../assets/fonts/Industry-Bold.ttf');
  const IndustryBoldItalic = require('../assets/fonts/Industry-BoldItalic.ttf');
  const IndustryBook = require('../assets/fonts/Industry-Book.ttf');
  const IndustryBookItalic = require('../assets/fonts/Industry-BookItalic.ttf');
  const IndustryDemi = require('../assets/fonts/Industry-Demi.ttf');
  const IndustryDemiItalic = require('../assets/fonts/Industry-DemiItalic.ttf');
  const IndustryLight = require('../assets/fonts/Industry-Light.ttf');
  const IndustryLightItalic = require('../assets/fonts/Industry-Light.ttf');
  const IndustryMedium = require('../assets/fonts/Industry-Medium.ttf');
  const IndustryMediumItalic = require('../assets/fonts/Industry-MediumItalic.ttf');
  const IndustryUltraItalic = require('../assets/fonts/Industry-UltraItalic.otf');

  return (
    <Global
      styles={css`
        @font-face {
          font-family: 'Roboto-Bold';
          src: url(${RobotoBold}) format('truetype');
        }
        @font-face {
          font-family: 'Roboto-Black';
          src: url(${RobotoBlack}) format('truetype');
        }
        @font-face {
          font-family: 'Roboto-BlackItalic';
          src: url(${RobotoBlackItalic}) format('truetype');
        }
        @font-face {
          font-family: 'Roboto-BoldItalic';
          src: url(${RobotoBoldItalic}) format('truetype');
        }
        @font-face {
          font-family: 'Roboto-Regular';
          src: url(${RobotoRegular}) format('truetype');
        }
        @font-face {
          font-family: 'Abolition-Regular';
          src: url(${AbolitionRegular}) format('opentype');
        }
        @font-face {
          font-family: 'Chairdrobe-Black';
          src: url(${ChairdrobeBlack}) format('truetype');
        }
        @font-face {
          font-family: 'digital-7-italic';
          src: url(${Digital7Italic}) format('truetype');
        }
        @font-face {
          font-family: 'DIN-Condensed-Bold';
          src: url(${DINCondensedBold}) format('truetype');
        }
        @font-face {
          font-family: 'Industry-Black';
          src: url(${IndustryBlack}) format('truetype');
        }
        @font-face {
          font-family: 'Industry-BlackItalic';
          src: url(${IndustryBlackItalic}) format('truetype');
        }
        @font-face {
          font-family: 'Industry-Bold';
          src: url(${IndustryBold}) format('truetype');
        }
        @font-face {
          font-family: 'Industry-BoldItalic';
          src: url(${IndustryBoldItalic}) format('truetype');
        }
        @font-face {
          font-family: 'Industry-Book';
          src: url(${IndustryBook}) format('truetype');
        }
        @font-face {
          font-family: 'Industry-BookItalic';
          src: url(${IndustryBookItalic}) format('truetype');
        }
        @font-face {
          font-family: 'Industry-Demi';
          src: url(${IndustryDemi}) format('truetype');
        }
        @font-face {
          font-family: 'Industry-DemiItalic';
          src: url(${IndustryDemiItalic}) format('truetype');
        }
        @font-face {
          font-family: 'Industry-Light';
          src: url(${IndustryLight}) format('truetype');
        }
        @font-face {
          font-family: 'Industry-LightItalic';
          src: url(${IndustryLightItalic}) format('truetype');
        }
        @font-face {
          font-family: 'Industry-Medium';
          src: url(${IndustryMedium}) format('truetype');
        }
        @font-face {
          font-family: 'Industry-MediumItalic';
          src: url(${IndustryMediumItalic}) format('truetype');
        }
        @font-face {
          font-family: 'Industry-UltraItalic';
          src: url(${IndustryUltraItalic}) format('opentype');
        }
        /* http://meyerweb.com/eric/tools/css/reset/
        v2.0 | 20110126
        License: none (public domain)
        */

        html,
        body,
        div,
        span,
        applet,
        object,
        iframe,
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        p,
        blockquote,
        pre,
        a,
        abbr,
        acronym,
        address,
        big,
        cite,
        code,
        del,
        dfn,
        em,
        img,
        ins,
        kbd,
        q,
        s,
        samp,
        small,
        strike,
        strong,
        sub,
        sup,
        tt,
        var,
        b,
        u,
        i,
        center,
        dl,
        dt,
        dd,
        ol,
        ul,
        li,
        fieldset,
        form,
        label,
        legend,
        table,
        caption,
        tbody,
        tfoot,
        thead,
        tr,
        th,
        td,
        article,
        aside,
        canvas,
        details,
        embed,
        figure,
        figcaption,
        footer,
        header,
        hgroup,
        menu,
        nav,
        output,
        ruby,
        section,
        summary,
        time,
        mark,
        audio,
        video {
          margin: 0;
          padding: 0;
          border: 0;
          font-size: 100%;
          font: inherit;
          vertical-align: baseline;
        }
        /* HTML5 display-role reset for older browsers */
        article,
        aside,
        details,
        figcaption,
        figure,
        footer,
        header,
        hgroup,
        menu,
        nav,
        section {
          display: block;
        }
        body {
          line-height: 1;
          -webkit-text-size-adjust: none;
        }
        ol,
        ul {
          list-style: none;
        }
        blockquote,
        q {
          quotes: none;
        }
        blockquote:before,
        blockquote:after,
        q:before,
        q:after {
          content: '';
          content: none;
        }
        table {
          border-collapse: collapse;
          border-spacing: 0;
        }
        html,
        body,
        #root {
          background: ${defaultBackgroundColor};
          height: 100%;
        }
        html {
          box-sizing: border-box;
        }
        *,
        *:before,
        *:after {
          box-sizing: inherit;
        }
        .full-width {
          width: 100%;
        }
        /* Defining styles here with importants because we need to override
        video.js button styles when a toast is shown in fullscreen video mode */
        .interactionToastCtaButton {
          background-color: ${darkBlue} !important;
          border: none !important;
          border-radius: 3px !important;
          padding: 0.7rem 1.2rem !important;
          color: ${white} !important;
          font-family: Roboto-Bold !important;
          font-size: 0.9rem !important;
          text-transform: uppercase !important;
          width: 100% !important;
          text-align: center !important;
          text-decoration: none !important;
          margin: 0 !important;
          max-width:  inherit !important;
          &:hover,
          &:active {
            background-color: ${curiousBlueActive} !important;
          }
        }
      `}
    />
  );
};

export default GlobalStyles;
