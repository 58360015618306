import { useState, useEffect } from 'react';

const usePageVisibility = () => {
  const [isVisible, setIsVisible] = useState<boolean>(document.visibilityState === 'visible');
  const onVisibilityChange = () => {
    const newState = (document.visibilityState === 'visible');
    if (isVisible !== newState) {
      setIsVisible(newState);
    }

  }

  useEffect(() => {
    document.addEventListener("visibilitychange", onVisibilityChange, false)

    return () => {
      document.removeEventListener("visibilitychange", onVisibilityChange)
    }
  })

  return isVisible
}

export default usePageVisibility;
