import { useEffect, useMemo, useRef, useState } from 'react';
import {useDispatch, useSelector} from 'react-redux'; // TODO: useSelector for google email, etc.
import { useNavigate, useParams } from 'react-router-dom';
import {Form, Formik, FormikProps} from 'formik';

import styled from '@emotion/styled';

// components
import {Button} from '../../components/buttons/Button';
import CheckboxField from '../../components/fields/CheckboxField';
import InputField from '../../components/fields/InputField';
import TermsServicePrivacyPolicy from '../../components/termsServicePrivacyPolicy/TermsServicePrivacyPolicy';
import {BodyText} from '../../components/typography/BodyText';

//utils
import {accountValidation} from '../../utils/validation';
import { clearUser, putAccountDetails } from '../../redux/modules/user';
import {UserAccountSetup} from '../../types/user';
import {
  clearCheckUsernameState,
  setUsername,
} from '../../redux/modules/accountSetup/checkUserName';
import {RootState} from '../../redux/rootReducer';
import DefaultLayout from '../../components/layouts/DefaultLayout';
import {AnyAction} from '@reduxjs/toolkit';
import {Spacer} from '../../components/layouts/Spacer';
import routes from '../../constants/routes.constants';
import HTLogo from '../../components/media/HTLogo';
import EventWrapper from '../../wrappers/EventWrapper';
// import ProfilePictureInput from '../../components/inputs/ProfilePictureInput';
// import { setAvatarSource } from '../../redux/modules/accountSetup/avatarSource';
import useToast from '../../hooks/useToast';
import { trackEventData, trackUserId, trackUserProperties } from '../../utils/analytics';
import { USER_REGISTRATION } from '../../constants/analytics.constants';
import { clearUserCookies } from '../../utils/cookies';

const Container = styled.div`
  display: flex;
  height: 100vh;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  overflow-y: scroll;
  margin-top: 28px;
  padding: 0 2rem;

  scrollbar-width: none; /* Firefox */
  ::-webkit-scrollbar {
    display: none;
  }

  .checkboxContainer {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 1rem;
  }

  .checkboxText {
    margin-right: 1rem;
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 100%;
  }
`;

enum accountSetupStep {
  INITIAL = 1,
  CLIENT_OPT_INS = 2
}

const AccountSetup = () => {
  const toast = useToast();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState<accountSetupStep>(accountSetupStep.INITIAL);
  const [formData, setFormData] = useState({});
  const {gameId} = useParams<{gameId: string}>();

  const debounce = useRef<NodeJS.Timeout | null>(null);

  const throttleUsernameCheck = (usernameToCheck: string) => {
    if (debounce.current) clearTimeout(debounce.current);

    if (!usernameToCheck) {
      dispatch(setUsername(usernameToCheck) as unknown as AnyAction);
    } else {
      debounce.current = setTimeout(() => {
        dispatch(setUsername(usernameToCheck) as unknown as AnyAction);
      }, 400);
    }
  };

  const {userData: user} = useSelector((state: RootState) => state.user);

  //For avatar: const {username, emailAddress, firstName, lastName, profilePicPath, id}
  const {username, emailAddress, firstName, lastName} =
    useMemo(() => {
      if (!user) return {};
      const {username, emailAddress, firstName, lastName} = user;
      return {
        username,
        emailAddress,
        firstName,
        lastName,
      };
    }, [user]);

  const {checkUserName} = useSelector((state: RootState) => state.accountSetup);

  const {loading, available} = checkUserName;

  const formikRef = useRef<any | null>(null);

  useEffect(() => {
    if (formikRef.current && !loading) {
      if (available && formikRef.current.dirty) {
        const errors = formikRef.current.errors || {};
        delete errors.username;
        formikRef.current.setErrors(errors);
      } else if (formikRef.current.dirty) {
        formikRef.current.setFieldError(
          'username',
          'Username is not available!'
        );
        formikRef.current.setFieldTouched('username', true, false);
      }
    }
  }, [loading, available]);

  // const showUploadScreen = (e: any) => {
  //   if (e.target.value) {
  //     dispatch(
  //       setAvatarSource({
  //         userId: id || '',
  //         avatarImageSource: e.target,
  //       }) as unknown as AnyAction,
  //     );
  //   }
  // };

  // const {avatarSource: avatarSourceData} = useSelector(
  //   (state: RootState) => state.accountSetup,
  // );
  // const {isSettingAvatar} = avatarSourceData;

  if (!user) navigate(routes.initialLaunch);

  const renderStateSwitch = (renderStep: accountSetupStep, props: any) => {
    switch (renderStep) {
      case accountSetupStep.INITIAL:
        return (
          <Form noValidate={true}>
            <InputField
              name="firstName"
              type="text"
              label="First Name"
              onChange={(e: any) => props.handleChange(e)}
              onBlur={(e: any) => props.handleBlur(e)}
            />
            <InputField
              name="lastName"
              type="text"
              label="Last Name"
              onChange={(e: any) => props.handleChange(e)}
              onBlur={(e: any) => props.handleBlur(e)}
            />
            <InputField
              name="username"
              type="text"
              label="Username"
              onChange={(e: any) => {
                props.handleChange(e);
                throttleUsernameCheck(e.target.value);
              }}
              onBlur={(e: any) => {
                props.handleBlur(e);
                if (Object.keys(props.errors).includes('username') && props.errors.username) {
                  props.setFieldValue('username', '');
                }
              }}
            />
            <InputField
              name="emailAddress"
              type="text"
              label="Email"
              onChange={(e: any) => props.handleChange(e)}
              onBlur={(e: any) => props.handleBlur(e)}
            />
            <div className="checkboxContainer">
              <div className="checkboxText">
                <BodyText align="left" display="block">
                  I am over the age of 13
                </BodyText>
              </div>
              <CheckboxField
                name="over17" // TODO: change to over13
                type="checkbox"
                label="Over 13"
                onChange={(e: any) => props.handleChange(e)}
                onBlur={(e: any) => props.handleBlur(e)}
              />
            </div>
            <Button type="submit" disabled={!props.isValid || props.isSubmitting}>Continue</Button>
            <Spacer top="20px" />
          </Form>
        );
      case accountSetupStep.CLIENT_OPT_INS:
        return (
          <Form noValidate={true}>
            <div className="checkboxContainer">
              <div className="checkboxText">
                <BodyText bold uppercase>SMS</BodyText>
                <BodyText align="left" display="block">
                  By checking this box, you expressly agree to receive recurring automated promotional, personalized marketing, and non-promotional text messages (e.g., cart reminders, event updates, and special offers) from the New York Islanders at the cell number used when signing up. Consent is not a condition of any purchase. Reply HELP for help and STOP to cancel at any time. Msg frequency varies. Msg & data rates may apply.
                </BodyText>
              </div>
              <CheckboxField
                name="clientOptIns.sms" // TODO: change to over13
                type="checkbox"
                label="SMS Opt-In"
                onChange={(e: any) => props.handleChange(e)}
              />
            </div>
            <div className="checkboxContainer">
              <div className="checkboxText">
                <BodyText bold uppercase>EMAIL</BodyText>
                <BodyText align="left" display="block">
                  I would like to receive marketing and other emails from the New York Islanders and UBS Arena.
                </BodyText>
              </div>
              <CheckboxField
                name="clientOptIns.email" // TODO: change to over13
                type="checkbox"
                label="Email Opt-In"
                onChange={(e: any) => props.handleChange(e)}
              />
            </div>
            <Spacer top="20px" />
            <Button type="submit">Submit</Button>
            <Spacer top="20px" />
            <TermsServicePrivacyPolicy />
          </Form>
        )
      default:
        return null;
    }
  };

  return user && (
    <EventWrapper>
      <DefaultLayout noHeader>
        <Spacer top="67px" />
        <HTLogo width="242px" />
        <Spacer top="15px" />
        <Container>
          {/*<ProfilePictureInput*/}
          {/*  isSettingAvatar={isSettingAvatar}*/}
          {/*  avatarImageSource={profilePicPath}*/}
          {/*  showUploadScreen={showUploadScreen}*/}
          {/*/>*/}
          {/* <AvatarImage src={avatarImage} alt="User avatar" /> */}
          <Formik
            initialValues={{
              firstName: firstName || '',
              lastName: lastName || '',
              username: username || '',
              emailAddress: emailAddress || '',
              over17: false, // TODO: change to over13
            }}
            onSubmit={(values, actions) => {
              const details = {...user, ...values};
              if (currentStep === accountSetupStep.INITIAL) {
                dispatch(clearCheckUsernameState());
                if (!values.over17) {
                  clearUserCookies();
                  dispatch(clearUser());
                  navigate(routes.under13);
                  return;
                }
                if (formikRef.current.isValid) {
                  const data = { ...formData, ...details };
                  setFormData(data);
                  setCurrentStep(currentStep + 1);
                }
              } else if (currentStep === accountSetupStep.CLIENT_OPT_INS) {
                const data = { ...formData, ...details };
                dispatch(
                  putAccountDetails({
                    details: data,
                    completionCallback: success => {
                      if (!success) {
                        actions.setSubmitting(false);
                        toast.statusToast('Something went wrong, please check your entries and try again.', 'error');
                      } else {
                        trackUserId(user.id);
                        trackUserProperties({phoneNumber: user.phoneNumber, emailAddress: values.emailAddress, optIns: values.clientOptIns});
                        trackEventData(USER_REGISTRATION, {
                          id: user.id,
                          emailAddress: values.emailAddress,
                          phoneNumber: user.phoneNumber,
                          optIns: values.clientOptIns,
                        });
                        toast.statusToast('You\'ve successfully registered, thank you!', 'success');
                        navigate(gameId ? routes.inGame.replace(':gameId', gameId) : routes.home);
                      }
                    },
                  }) as unknown as AnyAction,
                );
              }
            }}
            validationSchema={accountValidation}
            validateOnChange={true}
            validateOnMount={true}
            validateOnBlur={false}
            innerRef={formikRef}>
            {(props: FormikProps<UserAccountSetup>) => (
              renderStateSwitch(currentStep, props)
            )}
          </Formik>
        </Container>
      </DefaultLayout>
    </EventWrapper>
  );
};

export default AccountSetup;
