import styled from '@emotion/styled';
import {curiousBlue} from '../../theme/vars';

type DividingLineProps = {
  height?: string;
  margin?: string;
};

export const DividingLine = styled('div')<DividingLineProps>`
  width: 100%;
  height: ${props => props.height || '3px'};
  margin: ${props => props.margin || '2rem 0'};
  background-color: ${curiousBlue};
`;
