import {format, parseISO} from 'date-fns';

export const msToTime = (duration: number) => {
  const seconds = Math.floor((duration / 1000) % 60),
    minutes = Math.floor((duration / (1000 * 60)) % 60),
    hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

  const hh = hours < 10 ? '0' + hours : hours;
  const mm = minutes < 10 ? '0' + minutes : minutes;
  const ss = seconds < 10 ? '0' + seconds : seconds;

  return hh + ':' + mm + ':' + ss;
};

export const formatAliasAbbreviation = (abbrev: string) => {
  const aliasList = {
    ARI: 'Arizona',
    ATL: 'Atlanta',
    BAL: 'Baltimore',
    BUF: 'Buffalo',
    CAR: 'Carolina',
    CHI: 'Chicago',
    CIN: 'Cincinnati',
    CLE: 'Cleveland',
    DAL: 'Dallas',
    DEN: 'Denver',
    DET: 'Detroit',
    GB: 'Green Bay',
    HOU: 'Houston',
    IND: 'Indianapolis',
    JAC: 'Jacksonville',
    KC: 'Kansas city',
    LAC: 'Los Angeles',
    LA: 'Los Angeles',
    MIA: 'Miami',
    MIN: 'Minnesota',
    NE: 'New England',
    NO: 'New Orleans',
    NYG: 'New York',
    NYJ: 'New York',
    OAK: 'Cornerback',
    PHI: 'Philadelphia',
    PIT: 'Pittsburgh',
    SEA: 'Seattle',
    TB: 'Tampa Bay',
    TEN: 'Tennessee',
    WAS: 'Washington',
    SF: 'San Francisco',
    LV: 'Las Vegas',
    // TODO: Fix this
  } as any;
  return aliasList[abbrev] as string | null;
};

export function formatPositionAbbreviation(abbrev: string) {
  const listOfPositions = {
    QB: 'Quarterback',
    RB: 'Running Back',
    FB: 'Fullback',
    WR: 'Wide Receiver',
    TE: 'Tight End',
    OL: 'Offensive Lineman',
    C: 'Center',
    G: 'Goalie',
    LG: 'Left Guard',
    RG: 'Right Guard',
    T: 'Tackle',
    LT: 'Left Tackle',
    RT: 'Right Tackle',
    K: 'Kicker',
    KR: 'Kick Returner',
    DL: 'Defensive Lineman',
    DE: 'Defensive End',
    DT: 'Defensive Tackle',
    NT: 'Nose Tackle',
    LB: 'Linebacker',
    ILB: 'Inside Linebacker',
    OLB: 'Outside Linebacker',
    MLB: 'Middle Linebacker',
    DB: 'Defensive Back',
    CB: 'Cornerback',
    FS: 'Free Safety',
    SS: 'Strong Safety',
    S: 'Safety',
    P: 'Punter',
    PR: 'Punt Returner',
    DEF: 'Defense',
    F: 'Forward',
    D: 'Defenseman',
    LW: 'Left Wing',
    RW: 'Right Wing',
    NA: 'N/A',
  } as any;
  return listOfPositions[abbrev] as string | null;
}

export function formatDateOfBirth(date: string) {
  return format(parseISO(date), 'MMM d, yyyy');
}

export function formatNHLDraftPick(draft: {year: any; round: any; pick: any}) {
  return `${draft.year}, ${draft.round} rnd., ${draft.pick} pk.`;
}

export function percent(n: number, of = 100) {
  if (n === of) return '100%';
  return `${((100 * n) / of).toFixed(2)}%`;
}

export const getPeriodComparisonSubtitle = (period: any) => {
  switch (period) {
    case '1':
      return '1ST PERIOD';
    case '2':
      return '2ND PERIOD';
    case '3':
      return '3RD PERIOD';
    default:
      return 'OVERTIME';
  }
};
