import styled from '@emotion/styled';
import { ToastContainer, toast } from "react-toastify";
import { ToastContainerIds } from '../../constants/Global.constants';

const StyledToastContainer = styled(ToastContainer)`
  .Toastify__toast {
    margin: 0;
    padding: 0;
    background-color: transparent;
  }
`;
type Props = {
  containerId: ToastContainerIds;
}

const HTToastContainer = ({ containerId }: Props) => {
  return (
    <StyledToastContainer
      enableMultiContainer
      containerId={containerId}
      hideProgressBar
      position={toast.POSITION.TOP_CENTER}
      draggablePercent={40}
      closeButton={false}
    />
  )
}

export default HTToastContainer;
