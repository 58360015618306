import {useState} from 'react';
import styled from '@emotion/styled';

// style
import {white, white_25, white_30} from '../../theme/vars';

// components
import {BodyText} from '../typography/BodyText';

import DefaultImage from '../../assets/icons/avatar.svg';

type ContainerProps = {
  isUser?: boolean;
};

const Container = styled.tr<ContainerProps>`
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
  border-bottom: 1px solid ${white_25};
  background: ${({isUser}) => (isUser ? white_30 : 'transparent')};
  width: 100%;
  padding: 0.5rem 0;

  img {
    width: 3rem;
    height: 3rem;
    border: 1px solid ${white};
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
  }

  .rank {
    font-family: Industry-UltraItalic;
    font-size: 2rem;
    color: ${white};
    width: 20%;
    text-align: center;
  }

  .username {
    width: 40%;
    padding-left: 0.5rem;
  }

  .points {
    width: 25%;
  }
`;

type Props = {
  item: any;
  isUser?: boolean;
};

const LeaderboardTableRow = ({item, isUser}: Props) => {
  const {username, rank, profilePicPath, totalPoints} = item;
  const [image, setImage] = useState(
    profilePicPath ? profilePicPath : DefaultImage,
  );
  const BodyTextSpan = BodyText.withComponent('span');

  return (
    <Container isUser={isUser}>
      <td className="rank">{rank}</td>
      <td className="image">
        <img
          onError={() => setImage(DefaultImage)}
          src={image}
          alt={username}
        />
      </td>
      <td className="username">
        <BodyTextSpan display="block" align="left" bold={isUser}>
          {username || 'user'}
          {isUser && ' (YOU)'}
        </BodyTextSpan>
      </td>
      <td className="points">
        <BodyTextSpan display="block" bold={isUser}>
          {totalPoints}
        </BodyTextSpan>
      </td>
    </Container>
  );
};

export default LeaderboardTableRow;
