import {RootState} from '../redux/rootReducer';
import {shallowEqual, useSelector} from 'react-redux';

const useJoinedIds = () =>
  useSelector(
    ({game: {teamId, gameId}, user: {userData}}: RootState) => ({
      gameId,
      teamId,
      userId: userData?.id,
    }),
    shallowEqual,
  );

export default useJoinedIds;
