import {RootState} from '../redux/rootReducer';
import {useSelector} from 'react-redux';

const useJoinedTeam = () => {
  return (
    useSelector((state: RootState) => {
      const teamId = state.game.teamId;
      return state.teams.teamsData.find(team => team.id === teamId);
    }) || null
  );
};

export default useJoinedTeam;
