import LeaderboardBase from '../../components/leaderboard/LeaderboardBase';
import LoggedInViewWrapper from '../../wrappers/LoggedInViewWrapper';
import HomeLayout from '../../components/layouts/HomeLayout';

const GlobalLeaderboard = () => {
  return (
    <LoggedInViewWrapper>
      <HomeLayout>
        <LeaderboardBase inGame={false} />
      </HomeLayout>
    </LoggedInViewWrapper>
  );
};

export default GlobalLeaderboard;
