import {EVERYONE_CHANNEL, GAME_UPDATED} from '../../constants/Pusher.constants';
import {updateNextGameFromPusher} from '../../redux/modules/teams';
import store from '../../redux/store';
import Pusher from 'pusher-js';

const {dispatch} = store;

export const subscribeToPublicGenerator =
  ({pusherClient}: {pusherClient: Pusher}) =>
  () => {
    const channel = pusherClient.subscribe(EVERYONE_CHANNEL);
    channel.unbind_all();
    channel.bind_global((event: any, data: any) => {
      switch (event) {
        case GAME_UPDATED:
          dispatch(updateNextGameFromPusher({data}));
      }
    });
  };

export const unsubscribeFromPublicGenerator =
  ({pusherClient}: {pusherClient: any}) =>
  () => {
    pusherClient.unsubscribe(EVERYONE_CHANNEL);
  };
