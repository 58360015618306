import {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import styled from '@emotion/styled';

// components
import LeaderboardWrapper from './LeaderboardWrapper';

// state
import {
  getParticipationTeams,
  getParticipationTeamGames,
  getUsersTeamLeaderboard, getUsersTeamGameLeaderboard,
} from '../../redux/modules/leaderboard';

// styles
import {black, defaultThemeColor} from '../../theme/vars';
import {AnyAction} from '@reduxjs/toolkit';

type ContainerProps = {
  background: string;
};

export const Container = styled('div')<ContainerProps>`
  background: ${props => props.background || defaultThemeColor};
  overflow-y: scroll;
  width: 100%;
  height: 100%;
  padding-top: 2rem;
  display: flex;
`;

type Props = {
  gameId?: string | null;
  inGame?: boolean;
};

const LeaderboardBase = ({gameId, inGame}: Props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getParticipationTeamGames() as unknown as AnyAction);
    if (!gameId) {
      dispatch(getParticipationTeams() as unknown as AnyAction);
      dispatch(getUsersTeamLeaderboard() as unknown as AnyAction);
    } else {
      dispatch(getUsersTeamGameLeaderboard(gameId) as unknown as AnyAction);
    }
  }, [dispatch, gameId]);

  return (
    <Container background={black}>
      <LeaderboardWrapper inGame={inGame} gameId={gameId}/>
    </Container>
  );
};

export default LeaderboardBase;
