import { environment } from '../../environments/environment';

type Props = {
  src: string;
  path: string;
  alt: string;
  height?: string;
  width?: string;
  customStyles?: React.CSSProperties;
}

const DynamicMenuIcon = ({
  src,
  path,
  alt,
  height,
  width,
  customStyles,
}: Props) => {
  let imgSource = src;
  if (path) {
    //TODO: Move this is into a global function to determine a full source given a type and path
    const domainParts = window.location.hostname.split('.'); // will be length 2 or 3
    const domain = domainParts.slice(domainParts.length === 2 ? 0 : 1).join('.');
    let mediaUrl = `https://${environment.baseMediaUrl}.${domain}`;
    if (environment.baseMediaPort && environment.baseMediaPort !== '') {
      mediaUrl = `${mediaUrl}:${environment.baseMediaPort}`
    }
    imgSource = `${mediaUrl}/${path}`
  }

  return (
    <img
      src={imgSource} alt={alt}
      height={height || '31px'}
      width={width || '31px'}
      style={{ padding: '3px', marginRight: '1px', ...customStyles }}
    />
  );
}

export default DynamicMenuIcon;
