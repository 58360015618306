import {POINTS_EARNED, PRIVATE_CHANNEL} from '../../constants/Pusher.constants';
import {getUserId} from '../../utils/cookies';

export const subscribeToPrivateGenerator =
  ({
    pusherClient,
    showPointsEarned,
  }: {
    pusherClient: any;
    showPointsEarned: any;
  }) =>
  () => {
    const userId = getUserId();
    const channel = pusherClient.subscribe(`${PRIVATE_CHANNEL}${userId}`);
    channel.unbind_all();

    channel.bind_global((event: any, data: any) => {
      switch (event) {
        case POINTS_EARNED:
          showPointsEarned(data);
          break;
        default:
          break;
      }
    });
  };

export const unsubscribeFromPrivateGenerator =
  ({pusherClient}: {pusherClient: any}) =>
  () => {
    const userId = getUserId();
    pusherClient.unsubscribe(`${PRIVATE_CHANNEL}${userId}`);
  };
