export const US = 'United States';
export const ONE_DAY = 86400000; //24 hours in milliseconds
export const ONE_DAY_HOURS = 24;

export const USER_AUTH_TOKEN_EXPIRATION_DAYS = 30;

// Maximum distance from stadium coordinates for user to be considered "in stadium" (in kilometers)
export const STADIUM_PRESENCE_RADIUS_IN_KM = 0.402336;

export const SMS_RESEND_SUCCESS_MESSAGE = 'Code resent!';

export enum ToastContainerIds {
  Global = 'global',
  Video = 'video',
}

export const VIDEO_FEEDS = {
  Default: 'DEFAULT',
  FourUp: '4 UP',
  Feed1: 'FEED1',
  Feed2: 'FEED2',
  Feed3: 'FEED3',
  Stats: 'STATS',
  LegacyStream: 'LEGACY STREAM',
  LegacySeasonTicket: 'LEGACY SEASON TICKET',
};

export const PROFANITY_ERROR = 'Unsafe content';
export const GEOCODING_TIMEOUT_MS = 30000;
export const TRIVIAL_POLL_QUESTION_DELAY_MS = 20000;
export const GAME_ENGAGEMENT_DELAY_MS = 5000;

export const BANNER_AD_CYCLE_TiME_MS = 10000;
