import {
  JOIN_GAME_ACTION,
  JOIN_GAME_CATEGORY,
} from '../constants/analytics.constants';
import {joinGame, enterGameTime} from '../redux/modules/game';
import {RootState} from '../redux/rootReducer';
import {trackEventData} from '../utils/analytics';
import {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {AnyAction} from '@reduxjs/toolkit';

const useJoinGameCallback = (gameId?: string) => {
  const {activeAppTeamId, activeTeam, nextGame} = useSelector(
    (state: RootState) => state.teams,
  );
  const user = useSelector((state: RootState) => state.user.userData);
  const dispatch = useDispatch();

  const setUpTracking = useCallback(() => {
    if (!nextGame || !activeTeam || !gameId) return;
    trackEventData(JOIN_GAME_CATEGORY, {
      action: JOIN_GAME_ACTION,
      team_name: activeTeam.name,
      team_id: activeAppTeamId,
      game_id: gameId,
      user_id: user?.id,
      home_team_name: nextGame.homeTeam.name,
      away_team_name: nextGame.awayTeam.name,
      game_date: nextGame.gameTime,
    });
    dispatch(enterGameTime());
  }, [
    nextGame,
    activeTeam,
    activeAppTeamId,
    gameId,
    user?.id,
    dispatch,
  ]);

  return useCallback(() => {
    if (!gameId) return;
    dispatch(joinGame(gameId, setUpTracking) as unknown as AnyAction);
  }, [dispatch, gameId, setUpTracking]);
};

export default useJoinGameCallback;
