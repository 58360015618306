import styled from '@emotion/styled';
import {BodyText} from '../typography/BodyText';
import {HeaderLarge} from '../typography/HeaderLarge';
import {defaultThemeColor, white} from '../../theme/vars';
import {clampBuilder} from '../../utils/style';
import React from 'react';

const ToastContainer = styled.div`
  background-color: ${white};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  border: black 1px solid;
  border-radius: 5px;
`;

export const ClampedHeaderLarge = styled(HeaderLarge)`
  font-size: ${clampBuilder(0.75, 1.5)};
`;

export const ClampedBodyText = styled(BodyText)`
  font-size: ${clampBuilder(0.5, 1)};
`;

type Props = {
  bodyText?: string;
  rightContainer: React.ReactElement;
  children: React.ReactElement;
};

const GenericToastMessage = ({bodyText, rightContainer, children}: Props) => {
  return (
    <ToastContainer>
      <div className="label-wrapper">
        <ClampedHeaderLarge
          color={defaultThemeColor}
          align="left"
          size="1.5rem">
          {children}
        </ClampedHeaderLarge>
        {bodyText && (
          <ClampedBodyText color={defaultThemeColor}>
            {bodyText}
          </ClampedBodyText>
        )}
      </div>
      <div className="points-wrapper">
        <div className="points-earned-container">{rightContainer}</div>
      </div>
    </ToastContainer>
  );
};

export default GenericToastMessage;
