const MenuProfile = () => {
  return (
    <svg
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.9058 19.25H13.094C11.0892 19.3495 9.31301 20.5741 8.50704 22.4125C7.53491 24.321 9.47504 26.125 11.7314 26.125H21.2684C23.5262 26.125 25.4663 24.321 24.4928 22.4125C23.6868 20.5741 21.9106 19.3495 19.9058 19.25Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.625 11C20.625 13.2782 18.7782 15.125 16.5 15.125C14.2218 15.125 12.375 13.2782 12.375 11C12.375 8.72183 14.2218 6.875 16.5 6.875C17.594 6.875 18.6432 7.3096 19.4168 8.08318C20.1904 8.85677 20.625 9.90598 20.625 11Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default MenuProfile;
