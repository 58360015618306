import styled from '@emotion/styled';
import {defaultThemeColor} from '../../theme/vars';
import React from 'react';

type ContainerProps = {
  background: string;
  isWatchPartyConnected?: boolean;
};

const Container = styled('div')<ContainerProps>`
  display: flex;
  position: absolute;
  z-index: 102;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: ${props =>
    props.background ? `#${props.background}` : defaultThemeColor};
  .trivia-content {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding: 0 0.75rem 0.75rem;
    background-color: ${props =>
      props.background ? `#${props.background}` : defaultThemeColor};
  }
`;

type Props = {
  backgroundColor: string;
  content: React.ReactNode;
  isWatchPartyConnected: boolean;
};

const InteractionContainer = ({
  backgroundColor,
  content,
  isWatchPartyConnected,
}: Props) => {
  return (
    <Container
      background={backgroundColor}
      isWatchPartyConnected={isWatchPartyConnected}>
      <div className="trivia-content">{content}</div>
    </Container>
  );
};

export default InteractionContainer;
