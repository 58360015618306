import styled from '@emotion/styled';
import {white} from '../../theme/vars';

// utils
import {clampBuilder} from '../../utils/style';

type TitleStyles = {
  textTransform?: string;
};

export const Title = styled('h2')<TitleStyles>`
  font-family: Roboto-Bold;
  font-size: 1rem;
  font-size: ${clampBuilder(1, 2)};
  color: ${white};
  text-transform: ${props => props.textTransform || 'capitalize'};
  text-align: center;
`;
