import {useSelector} from 'react-redux';
import {differenceInMilliseconds} from 'date-fns';
import {Game} from '../types/game';
import {ONE_DAY} from '../constants/Global.constants';
import {RootState} from '../redux/rootReducer';

const useGameCardStatuses = (
  activeTeamId: string,
  testUser: boolean,
  game: Game,
) => {
  const {
    id: gameId,
    homeTeam,
    awayTeam,
    awayTeamId,
    awayGameState,
    homeGameState,
    homeTeamId,
  } = game;
  const isHomeTeam = homeTeamId === activeTeamId;
  const isAwayTeam = awayTeamId === activeTeamId;
  const isHomeGameStateLive = isHomeTeam && homeGameState === 'live';
  const isHomeGameStateTest = homeGameState === 'test' && testUser;
  const isAwayGameStateLive = isAwayTeam && awayGameState === 'live';
  const isAwayGameStateTest = awayGameState === 'test' && testUser;
  const {
    loading,
    error,
    gameId: joiningGameId,
    teamId: joiningTeamId,
  } = useSelector((state: RootState) => state.game);
  const canJoinGame =
    isHomeGameStateLive ||
    isHomeGameStateTest ||
    isAwayGameStateLive ||
    isAwayGameStateTest;
  const isJoiningGame = useIsJoiningGame({
    homeTeam,
    awayTeam,
    gameId,
    loading,
    joiningGameId,
    joiningTeamId,
  });
  const showCountdown = () => {
    //Users should see a countdown of any pre-game game once within 24 hours of start time
    const gameDate = new Date(game.gameTime);
    const now = new Date();
    const diff = differenceInMilliseconds(gameDate, now);
    const isPreGame = diff < ONE_DAY && diff > 0;
    if (homeTeam.id === activeTeamId && game.homeGameState === 'pre-game') {
      return isPreGame;
    } else if (
      awayTeam.id === activeTeamId &&
      game.awayGameState === 'pre-game'
    ) {
      return isPreGame;
    } else if (awayGameState === 'test' && !testUser) {
      return isPreGame;
    } else if (homeGameState === 'test' && !testUser) {
      return isPreGame;
    } else {
      return (
        isPreGame &&
        game.homeGameState === 'pre-game' &&
        game.awayGameState === 'pre-game'
      );
    }
  };

  return {
    showCountdown: showCountdown(),
    isJoiningGame: isJoiningGame,
    canJoinGame: canJoinGame,
    loading,
    error,
  };
};

const useIsJoiningGame = ({
  homeTeam: {id: homeTeamId},
  awayTeam: {id: awayTeamId},
  gameId,
  loading,
  joiningGameId,
  joiningTeamId,
}: {
  homeTeam: {id: string};
  awayTeam: {id: string};
  gameId: string;
  loading: boolean;
  joiningGameId: string | null;
  joiningTeamId: string | null;
}) => {
  return (
    loading &&
    gameId === joiningGameId &&
    joiningTeamId &&
    [homeTeamId, awayTeamId].includes(joiningTeamId)
  );
};

export default useGameCardStatuses;
