import {useEffect} from 'react';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';

import styled from '@emotion/styled';
import {grey_600, slate, white} from '../../theme/vars';

const PhoneInputStyleWrapper = styled.div`
  margin-bottom: 15px;
  width: 100%;
  .PhoneInput {
    background-color: ${slate};
    padding: 10px 12px 10px 15px;
    height: 52px;
    .PhoneInputCountry {
      .PhoneInputCountryIcon--border {
        box-shadow: none;
      }
      .PhoneInputCountrySelectArrow {
        border-color: ${white};
        opacity: 0.8;
      }
    }
    input[type='tel'] {
      background-color: transparent;
      border: none;
      box-shadow: none;
      color: ${grey_600};
      font-family: Roboto-Regular;
      font-size: 21px;
      font-weight: 400;
      letter-spacing: -0.5040000081062317px;
      text-align: left;
      ::placeholder,
      ::-webkit-input-placeholder {
        color: ${grey_600};
      }
    }
  }
`;

type Props = {
  placeholder?: string;
  phoneNumber: string;
  setPhoneNumber: (phoneNumber: string) => void;
};

export default function PhoneNumberInput({
  placeholder = '',
  phoneNumber,
  setPhoneNumber,
}: Props) {
  useEffect(() => {
    document
      .querySelector('.PhoneInputInput')
      ?.setAttribute('placeholderTextColor', 'white');
  }, []);
  return (
    <PhoneInputStyleWrapper>
      <PhoneInput
        country="US"
        defaultCountry="US"
        placeholder={placeholder}
        value={phoneNumber}
        onChange={setPhoneNumber}
      />
    </PhoneInputStyleWrapper>
  );
}
