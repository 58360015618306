import {useEffect, useCallback} from 'react';
import {useSelector} from 'react-redux';
import styled from '@emotion/styled';
import {grey, buttonGreen, buttonRed, black} from '../../theme/vars';

// components
import TriviaButton from './TriviaButton';
import Question from './Question';
import TriviaPollHeader from './TriviaPollHeader';

// hooks
import useJoinedTeam from '../../hooks/useJoinedTeam';

import {getAnswer} from '../../utils/trivia';
import {motion} from 'framer-motion';
import {ImageType} from '../media/Image';

import GreenCheck from '../../assets/images/green-check.svg';
import RedX from '../../assets/images/red-x.svg';

import {selectResultById as selectTriviaResultById} from '../../redux/modules/trivia';
import {selectResultById as selectPollResultById} from '../../redux/modules/poll';
import {RootState} from '../../redux/rootReducer';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;

  margin: 1rem;

  .questionWrapper {
    flex: 1;
    display: flex;
    flex-direction: column;

    background-color: ${black};
  }

  .buttonContainer {
    display: flex;
    flex: 1;
    align-self: center;
    flex-direction: column;
    justify-content: flex-start;
    padding: 10px;
    width: 100%;
  }

  .buttonWrapper {
    display: flex;
    padding-bottom: 1rem;
    width: 100%;
  }
`;

type Props = {
  interaction: Record<string, any>;
  trivia?: Record<string, any>;
  poll?: any;
  isCorrectAnswer?: boolean;
  activeAnswer: string | null;
  backgroundColor: string;
  clearInteractionType: any;
  onTriviaCompletion: (id: any) => void;
  isWatchPartyConnected?: boolean;
};

const TriviaResults = ({
  interaction,
  activeAnswer,
  trivia,
  isCorrectAnswer,
  clearInteractionType,
  onTriviaCompletion,
  poll,
  backgroundColor,
  isWatchPartyConnected,
}: Props) => {
  const {metadata: interactionMetaData, interactionId} = interaction;
  const joinedTeam = useJoinedTeam();

  const selectResultById = poll ? selectPollResultById : selectTriviaResultById;
  const result = useSelector((state: RootState) =>
    selectResultById(state, interactionId),
  );

  const nextStep = useCallback(() => {
    clearInteractionType();
  }, [clearInteractionType]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      nextStep();
      onTriviaCompletion(interactionId);
    }, 5000);
    return () => {
      timeout && clearTimeout(timeout);
    };
  }, [nextStep, onTriviaCompletion, interactionId]);

  const getAnimatedIcon = ({base, alt, className}: ImageType) => {
    return (
      <motion.img
        src={base}
        alt={alt}
        className={className}
        initial={{
          width: isWatchPartyConnected ? '40px' : '65px',
          height: isWatchPartyConnected ? '40px' : '65px',
          scale: '0',
        }}
        animate={{scale: 1}}
        transition={{
          type: 'tween',
          ease: 'easeOut',
          duration: 0.7,
        }}
      />
    );
  };

  const getPercent = (id: any) => {
    const matchingPollResultOption = result?.options.find((r: any) => {
      return r.id === id;
    });

    const percent = matchingPollResultOption?.percentage || 0;
    return Number(percent);
  };

  const isWrongAnswer = (id: any) => {
    return (
      id === activeAnswer &&
      activeAnswer !== getAnswer(interactionMetaData.options, interaction)
    );
  };

  const getBackgroundColor = (id: any) => {
    if (poll && result) {
      return buttonGreen;
    }
    if (trivia && result) {
      let color;
      if (getAnswer(interactionMetaData.options, interaction) === id) {
        color = buttonGreen;
      } else if (isWrongAnswer(id)) {
        color = buttonRed;
      } else {
        color = grey;
      }
      return color;
    }
  };

  return (
    <Container>
      {interactionMetaData && (
        <>
          <TriviaPollHeader
            header={'RESULTS'}
            backgroundColor={backgroundColor}
            joinedTeam={joinedTeam}
            type={poll ? 'poll' : 'trivia'}
            headerFeature={
              !poll &&
              getAnimatedIcon({base: isCorrectAnswer ? GreenCheck : RedX})
            }
            isWatchPartyConnected={isWatchPartyConnected}
          />
          <div className="questionWrapper">
            <Question text={interactionMetaData.question} />
            <div className="buttonContainer">
              {interactionMetaData.options.map((option: any) => {
                return (
                  <div key={option.id} className="buttonWrapper">
                    <TriviaButton
                      option={option}
                      disabled={true}
                      percent={getPercent(option.id)}
                      shouldAnimateWidth={true}
                      backgroundColor={getBackgroundColor(option.id) || ''}
                      animateTextColor={false}
                      shouldAnimateBackgroundColor={false}
                      showPercent={true}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </>
      )}
    </Container>
  );
};

export default TriviaResults;
