import styled from '@emotion/styled';
import Input from './Input';
import InputLabel from './InputLabel';
import InputError from './InputError';

const Container = styled.div`
  position: relative;
  margin-bottom: 1rem;
  width: 100%;
`;

type Props = {
  label?: string;
  name: string;
  type: string;
  value: string;
  setValue: (value: string) => void;
  error?: string;
  inputMode?:
    | 'text'
    | 'none'
    | 'tel'
    | 'url'
    | 'email'
    | 'numeric'
    | 'decimal'
    | 'search';
};

export default function InputContainer({
  name,
  label,
  type,
  value,
  setValue,
  error,
  inputMode,
}: Props) {
  return (
    <Container>
      <Input
        id={name}
        required
        name={name}
        type={type}
        inputMode={inputMode}
        value={value}
        onChange={e => setValue(e.target.value)}
      />
      {label && <InputLabel htmlFor={name}>{label}</InputLabel>}
      {error && <InputError>{error}</InputError>}
    </Container>
  );
}
