import {
  EVERYONE_CHANNEL,
  PRIVATE_CHANNEL,
} from '../../../constants/Pusher.constants';
import {SubscriptionChannelAndData} from '../../../types/pusher';
import {RootState} from '../../rootReducer';
import Pusher from 'pusher-js';

export const pusherChannelsToCurrentSubscriptions = (
  pusherClient: Pusher,
): SubscriptionChannelAndData[] => {
  if (!pusherClient.channels?.channels) return [];
  return Object.keys(pusherClient.channels.channels).map(channelName => {
    if ([EVERYONE_CHANNEL, PRIVATE_CHANNEL].includes(channelName)) {
      return {channel: channelName};
    }
    const [first, ...rest] = channelName.split('@');
    const [gameId, teamId] = rest.join('').split(';');
    return {
      channel: `${first}@`,
      data: {
        gameId,
        teamId,
      },
    };
  });
};

export const addCurrentSubscriptionToList = (
  currentSubscriptions: SubscriptionChannelAndData[],
  {channel, data}: SubscriptionChannelAndData,
) => {
  const tempCurrentSubscriptionsAndData = currentSubscriptions.slice(0);
  const currentIndex: number = currentSubscriptions.findIndex(
    item => item.channel === channel,
  );
  if (currentIndex < 0) {
    tempCurrentSubscriptionsAndData.push({channel, data});
  } else {
    tempCurrentSubscriptionsAndData[currentIndex] = {channel, data};
  }
  return tempCurrentSubscriptionsAndData;
};

export const removeCurrentSubscriptionFromList = (
  currentSubscriptions: SubscriptionChannelAndData[],
  channel: string,
) => {
  const currentIndex = currentSubscriptions.findIndex(
    item => item.channel === channel,
  );
  if (currentIndex < 0) return;
  else {
    const tempCurrentSubscriptionsAndData = currentSubscriptions.slice(0);
    tempCurrentSubscriptionsAndData.splice(currentIndex, 1);
    return tempCurrentSubscriptionsAndData;
  }
};

export const currentSubscriptionsInclude = (
  getState: () => RootState,
  channel: string,
) => {
  return getState().pusher.currentSubscriptions.some(
    s => s.channel === channel,
  );
};
