export const curiousBlue = '#327AC2'; //theme button color
export const curiousBlueActive = '#256EB6';
export const darkBlue = '#003087';
export const eastBay = '#4F5670';
export const slate = 'rgb(79, 86, 112)';
export const white = '#ffffff';
export const white_10 = 'rgba(255,255,255,0.10)';
export const white_25 = 'rgba(255,255,255,0.25)';
export const white_30 = 'rgba(255,255,255,0.30)';
export const warning = 'rgb(253, 34, 1)';
export const transparent = 'rgba(0,0,0,0)';
export const black = '#000000';
export const black_50 = '#00000050';
export const black_90 = '#00000090';
export const blackRussian = '#000000';
export const limeGreen = '#07ec62';

export const grey_100 = 'rgb(242,242,242)';
export const grey_200 = 'rgb(213,213,213)';
export const grey_300 = 'rgb(207,207,207)';
export const grey_400 = 'rgb(136,141,151)';
export const grey_500 = 'rgb(93, 93, 93)';
export const grey_600 = '#CDCFD7';
export const grey_700 = '#4b4b4b';
export const grey_800 = '#bfbfbf';
export const lightGrey = '#cccccc';
export const grey_1000 = 'rgb(17, 17, 17)';

export const buttonGreen = 'rgb(88, 190, 60)';
export const buttonRed = 'rgb(210, 14, 38)';
export const grey = '#777777';

export const buttonTransition = {
  type: 'tween',
  ease: 'easeOut',
  duration: 0.3,
};
export const buttonTapAnimation = {opacity: 0.5};

export const defaultThemeColor = black; // Previously defaultThemeColor
export const defaultBackgroundColor = defaultThemeColor;
export const defaultBackgroundColorForNative = '000000';

export const watchPartyPrimary = black;
export const watchPartySecondary = '#222';
