import styled from '@emotion/styled';
import {motion} from 'framer-motion';
import {buttonTapAnimation, buttonTransition} from '../../theme/vars';

const Container = styled(motion.button)`
  border: none;
  background: transparent;
`;

type Props = {
  className?: string;
  disabled?: boolean;
  handleClick?: () => void;
  type?: 'button' | 'submit' | 'reset';
  children: any;
};

const TouchableOpacity = ({
  className = '',
  handleClick,
  type,
  disabled,
  children,
}: Props) => {
  const onClick = () => {
    !disabled && handleClick?.();
  };

  return (
    <Container
      tabIndex={1}
      disabled={disabled}
      className={className}
      type={type}
      whileTap={buttonTapAnimation}
      transition={buttonTransition}
      onTap={onClick}>
      {children}
    </Container>
  );
};

export default TouchableOpacity;
