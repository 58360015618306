export const CALIBRATION_ROOT = '/calibration';

const routes = {
  initialLaunch: '/',
  preSmsAuth: '/pre-sms-auth',
  smsAuth: '/sms-auth/:gameId',
  nativeAuth0: '/native-auth0',
  missingTeam: '/missing-team',
  preLoginError: '/pre-login-error',
  accountSetup: '/account-setup/:gameId',
  postAccountSetup: '/post-account-setup',
  seasonTicketHolder: '/season-ticket-holder',
  under13: '/under-13',
  tutorial: '/tutorial',
  home: '/home',
  leaderboard: '/leaderboard',
  inGameLeaderboard: '/in-game-leaderboard',
  inGame: '/in-game/:gameId',
  inGameVideo: '/in-game/:gameId/video/:feedName',
  calibrationAuto: `${CALIBRATION_ROOT}/auto`,
  calibrationManual: `${CALIBRATION_ROOT}/manual`,
  editProfile: '/edit-profile/:gameId',
  profilePersonal: '/profile/personal',
  profileTeam: '/profile/team',
  profileAccount: '/profile/account',
  chat: '/chat',
  selectViewMode: '/select-view-mode',
  ineligibleLocation: '/ineligible-location',
};

export const IN_GAME_ROUTES = [
  routes.inGame,
  routes.calibrationAuto,
  routes.calibrationManual,
  routes.chat,
  routes.selectViewMode,
];

export const PRE_LOGIN_ROUTES = [
  routes.initialLaunch,
  routes.preSmsAuth,
  routes.smsAuth,
  routes.missingTeam,
  routes.preLoginError,
  routes.under13,
  routes.ineligibleLocation
];

export default routes;
