import styled from '@emotion/styled';
import {
  white,
  defaultThemeColor,
  curiousBlue,
  grey_300,
} from '../../theme/vars';
import TouchableOpacity from '../../containers/touchableOpacity/TouchableOpacity';

export const Container = styled.div`
  margin-bottom: 1rem;
  background-color: ${white};
  border-radius: 3px;
  width: 100%;

  .rowWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 1rem;
    padding: 1rem;
  }

  .rowWrapper--hr {
    border-bottom: 1px solid ${grey_300};
  }

  .title {
    align-self: flex-start;
    font-family: 'Industry-UltraItalic';
    font-weight: normal;
    font-size: 1rem;
    color: ${defaultThemeColor};
    text-transform: uppercase;
  }

  .childrenContainer {
    padding: 1rem;
  }

  .buttonText {
    font-family: 'Roboto-Regular';
    font-size: 1rem;
    color: ${curiousBlue};
  }

  .profileButton {
    cursor: pointer;
  }
`;

type Props = {
  title: string;
  isEditable?: boolean;
  isOpenable?: boolean;
  editing?: boolean;
  onPressEdit?: () => void;
  onPressOpen?: () => void;
  onPressCancel?: () => void;
  hrVisible?: boolean;
  children: any;
};

export default function ProfileCard({
  title,
  hrVisible = false,
  isEditable = false,
  isOpenable = false,
  editing = false,
  onPressEdit,
  onPressOpen,
  onPressCancel,
  children,
}: Props) {
  return (
    <Container>
      <div className={`rowWrapper ${hrVisible ? 'rowWrapper--hr' : ''}`}>
        <h3 className="title">{title}</h3>
        <div className="actionButtons">
          {isOpenable && editing && (
            <TouchableOpacity
              className="profileButton"
              handleClick={onPressOpen}>
              <h3 className="buttonText">OPEN</h3>
            </TouchableOpacity>
          )}
          {isEditable && (
            <>
              {editing && (
                <TouchableOpacity
                  className="profileButton"
                  handleClick={onPressCancel}>
                  <h3 className="buttonText">CANCEL</h3>
                </TouchableOpacity>
              )}
              <TouchableOpacity
                className="profileButton"
                handleClick={onPressEdit}
                type="submit">
                <h3 className="buttonText">{editing ? 'SAVE' : 'EDIT'}</h3>
              </TouchableOpacity>
            </>
          )}
        </div>
      </div>
      <div className="childrenContainer">{children}</div>
    </Container>
  );
}
