import styled from '@emotion/styled';
import { white } from '../../theme/vars';
import { Title } from './Title';

type HeaderLargeProps = {
  color?: string;
  align?: string;
  size?: string;
  textTransform?: string;
};

export const HeaderLarge = styled(Title)<HeaderLargeProps>`
  color: ${(props) => props.color || white};
  font-family: Industry-UltraItalic;
  font-size: ${(props) => props.size || '2rem'};
  line-height: 1.3;
  text-align: ${(props) => props.align || 'center'};
  text-transform: ${(props) => props.textTransform || 'uppercase'};
`;
