import { createBrowserRouter, Outlet, RouterProvider } from 'react-router-dom';
import Root from './Root';
import routes from './constants/routes.constants';
import InitialLaunchView from './containers/initialLaunchView/InitialLaunchView';
import SMSAuth from './containers/sms/SMSAuth';
import MissingTeam from './containers/missingTeam/MissingTeam';
import PreLoginError from './containers/preLoginError/PreLoginError';
import AccountSetup from './containers/accountSetup/AccountSetup';
import PostAccountSetup from './containers/accountSetup/PostAccountSetup';
import Under13 from './containers/under13/Under13';
import Home from './containers/home/Home';
import GlobalLeaderboard from './containers/leaderboard/GlobalLeaderboard';
import EditProfile from './containers/editProfile/EditProfile';
import InGame from './containers/inGame/InGame';
import InGameLeaderboard from './containers/leaderboard/InGameLeaderboard';
import IneligibleLocationView from './containers/ineligibleLocationView/IneligibleLocationView';
import AppInitWrapper from './wrappers/AppInitWrapper';
import Analytics from './components/analytics/Analytics';
import styled from '@emotion/styled';


const Container = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  height: 100%;
  background-color: black;
`;

const Layout = () => {
  return (
    <Container>
      <AppInitWrapper>
        <Analytics>
          <Outlet />
        </Analytics>
      </AppInitWrapper>
    </Container>
  )
}


const router = createBrowserRouter([
  { element: <Layout />, children: [
      { path: "*", Component: Root },
      { path: routes.initialLaunch, Component: InitialLaunchView },
      { path: routes.smsAuth, Component: SMSAuth },
      { path: routes.missingTeam, Component: MissingTeam },
      { path: routes.preLoginError, Component: PreLoginError },
      { path: routes.accountSetup, Component: AccountSetup },
      { path: routes.postAccountSetup, Component: PostAccountSetup },
      { path: routes.under13, Component: Under13 },
      { path: routes.home, Component: Home },
      { path: routes.leaderboard, Component: GlobalLeaderboard },
      { path: routes.editProfile, Component: EditProfile },
      { path: routes.inGame, element: <InGame />, children: [
          { path: routes.inGameVideo, element: <InGame /> }
        ] },
      { path: routes.inGameLeaderboard, Component: InGameLeaderboard },
      { path: routes.ineligibleLocation, Component: IneligibleLocationView }
    ]
  }
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
