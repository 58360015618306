import {useField, FieldHookConfig} from 'formik';
import styled from '@emotion/styled';

const Container = styled.div`
  .checkbox {
    position: relative;
    display: inline-block;
    width: 45px;
    height: 24px;
  }

  .checkbox input[type=checkbox] {
    -ms-transform: scale(2); /* IE */
    -moz-transform: scale(2); /* FF */
    -webkit-transform: scale(2); /* Safari and Chrome */
    -o-transform: scale(2); /* Opera */
    transform: scale(2);
    padding: 10px;
  }
`;

type BaseCheckboxFieldProps = FieldHookConfig<string> & {
  label: string;
  name: string;
  type: string;
  required?: boolean;
};

const CheckboxField = (props: BaseCheckboxFieldProps) => {
  const [field, meta] = useField(props);

  return (
    <Container>
      <label htmlFor={field.name} className="checkbox">
        <input id={field.name} required={props.required} {...field} type="checkbox" />
      </label>
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </Container>
  );
};

export default CheckboxField;
