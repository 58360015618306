// This file can be replaced during build by using the `fileReplacements` array.
// When building for production, this file is replaced with `environment.production.ts`.

export const environment = {
  production: process.env.REACT_APP_PRODUCTION,
  baseUrl: process.env.REACT_APP_BASE_URL,
  baseMediaUrl: process.env.REACT_APP_BASE_MEDIA_URL,
  basePort: process.env.REACT_APP_BASE_PORT,
  baseMediaPort: process.env.REACT_APP_BASE_MEDIA_PORT,
  geocodingKey: process.env.REACT_APP_GEOCODING_KEY,
  PUSHER_KEY: process.env.REACT_APP_PUSHER_KEY,
  PUSHER_CLUSTER: process.env.REACT_APP_PUSHER_CLUSTER,
  streamChatAPIKey: process.env.REACT_APP_HT_GETSTREAM_KEY,
  lockToStadium: process.env.REACT_APP_HT_LOCK_TO_STADIUM,
  appVersion: process.env.REACT_APP_VERSION,
};

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

export const domainMap = [
  {
    teamId: "441766b9-0f24-11e2-8525-18a905767e44",
    domain: "islesplus.com",
    title: "Isles+",
    manifestPath: "/islanders.webmanifest",
    faviconLgPath: "/favicon-islanders-lg.png",
    faviconSmPath: "/favicon-islanders-sm.png",
    appleTouchPath: "/apple-touch-icon-islanders.png",
    maskPath: "/islanders-pinned-tab.svg",
    maskColor: "#000000"

  },
  {
    teamId: "44169bb9-0f24-11e2-8525-18a905767e44",
    domain: "hometurfapp.com",
  }
]

export const domainMapDefault = {
  domain: "hometurfapp.com",
  title: "HomeTurf",
  manifestPath: "/site.webmanifest",
  faviconLgPath: "/favicon-32x32.png",
  faviconSmPath: "/favicon-16x16.png",
  appleTouchPath: "/apple-touch-icon.png",
  maskPath: "/safari-pinned-tab.svg",
  maskColor: "#5bbad5"
}

export const bypassStadiumKeyWhitelist = [
  'd6e539ad-5dab-4059-998e-9ece46983f56'
]

if (!environment.PUSHER_KEY || !environment.PUSHER_CLUSTER) {
  console.error('Missing env var. Please supply a Pusher app key and cluster.');
}
