import styled from '@emotion/styled';
import {HeaderLarge} from '../../components/typography/HeaderLarge';
import {BodyText} from '../../components/typography/BodyText';
import {DividingLine} from '../../components/dividingLine/DividingLine';
import HTHeader from '../../components/headers/HTHeader';
import bgImage from '../../assets/images/fan-background.png';
import bgImage2 from '../../assets/images/fan-background@2x.png';
import bgImage3 from '../../assets/images/fan-background@3x.png';
import {white} from '../../theme/vars';

const Container = styled.div`
  background-image: url(${bgImage});
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  background-size: cover;
  height: 100vh;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  @media screen and (min-width: 375px) {
    background-image: url(${bgImage2});
  }
  @media screen and (min-width: 750px) {
    background-image: url(${bgImage3});
  }
  .wrapper {
    max-width: 400px;
    padding: 0 2rem 2rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .text-wrapper {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
  }
  .error {
    color: red;
    font-family: 'Roboto-Bold';
  }

  a {
    color: ${white};
    font-family: Roboto-Bold;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 0.563px;
    text-decoration-line: underline;
  }
`;

const IneligibleLocationView = () => {
  return (
    <Container>
      <HTHeader backgroundColor="transparent" hideRadioToggle={true}/>
      <div className="wrapper">
        <div className="text-wrapper">
          <HeaderLarge>
            This experience is ONLY for fans in the USA and Canada.
          </HeaderLarge>
          <DividingLine />
          <BodyText>
            Visit <a href="https://www.nhl.com">NHL.com</a> for the latest news, stats,
            and highlights.
          </BodyText>
        </div>
      </div>
    </Container>
  );
};

export default IneligibleLocationView;
