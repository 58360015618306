import React from 'react';
import Modal from 'react-modal';
import styled from '@emotion/styled';
import {defaultThemeColor} from '../../theme/vars';
import X from '../../assets/icons/x.svg';
import whiteX from '../../assets/icons/white-x.svg';
import ButtonIcon from '../buttons/ButtonIcon';
import {ModalStyle, defaultModal} from '../../theme/modal';

const ModalBody = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const ModalHeader = styled.h2`
  color: ${defaultThemeColor};
  font-family: 'Industry-UltraItalic';
  font-size: 1.5rem;
  line-height: 1.375;
  letter-spacing: -0.58px;
  text-align: center;
  text-transform: uppercase;
`;

const ModalContent = styled.section`
  flex: 1;
`;

const ModalFooter = styled.section``;

const ModalCloseButton = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
`;

type Props = {
  isOpen: boolean;
  onClose?: () => void;
  isPortrait?: boolean;
  children: React.ReactElement;
  title?: string;
  footer?: React.ReactElement;
  modalStyling?: ModalStyle;
  isWebView?: boolean;
  isWhiteX?: boolean;
};

const HTModal = ({
  isOpen,
  onClose,
  isPortrait,
  title,
  children,
  footer,
  modalStyling,
  isWebView = false,
  isWhiteX = false,
}: Props) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={modalStyling || defaultModal}
      appElement={document.querySelector('#root') as HTMLElement}>
      {onClose && (
        <ModalCloseButton>
          <ButtonIcon
            icon={isWebView || isWhiteX ? whiteX : X}
            alt="Close"
            onClick={() => onClose()}
          />
        </ModalCloseButton>
      )}
      <ModalBody>
        <ModalHeader>{title}</ModalHeader>
        <ModalContent>{children}</ModalContent>
        {!!footer && <ModalFooter>{footer}</ModalFooter>}
      </ModalBody>
    </Modal>
  );
};

export default HTModal;
