import React from 'react';
import styled from '@emotion/styled';
import {useSelector} from 'react-redux';
import {RootState} from '../../redux/rootReducer';
import {Avatar, useMessageContext} from 'stream-chat-react';
import {getTeamImage} from '../../utils/media';
import defaultAvatar from '../../assets/images/default-helmet.png';
import arena from '../../assets/icons/arena.png';

const Container = styled.div`
display: flex;
height: auto;
align-items: center;
border-radius: 7px;
background-color: rgba(0, 0, 0, 0.7);
padding-top: 5px;
padding-bottom: 5px;
padding-left: 5px;
position: relative;
margin: 0px 0.75rem;

.str-chat__avatar-image {
  object-fit: cover !important;
}
`;

const MessageContainer = styled.div`
display: flex;
width: 100%;
height: 100%;
padding: 0 4px;
flex-direction: column;
`;
const UsernameContainer = styled.div`
display: flex;
`;

const UsernameText = styled.p`
font-family: Industry-Demi;
color: #FFF;
font-size: 0.875rem;
font-style: normal;
font-weight: 900;
line-height: 1.375rem; /* 157.143% */
letter-spacing: -0.024rem;
text-transform: uppercase;
`;

const MessageText = styled.p`
color: #FFF;
font-family: Roboto-Regular;
font-size: 0.875rem;
font-style: normal;
font-weight: 400;
line-height: 1.375rem;
letter-spacing: -0.024rem;
word-break: break-word;
`;

export const SimpleChatMessage = () => {
  const { message, isMyMessage } = useMessageContext();
  const { activeTeam } = useSelector((state: RootState) => state.teams);

  let avatarImage;

  if (message?.isFromAdmin && message?.teamId) {
    avatarImage = getTeamImage({teamId: message?.teamId.toString()})?.mediaUrl;
  } else if (message?.user?.profilePic) {
    avatarImage = message.user.profilePic;
  } else {
    avatarImage = defaultAvatar; // TODO: This avatar may not scale for future client use cases
  }

  return message.deleted_at || (message.shadowed && !isMyMessage()) ? null : (
    <Container color={activeTeam.color}>
      {/*<Avatar image={avatarImage} size={40} />*/}
      <MessageContainer>
        <UsernameContainer>
          <UsernameText>{message?.user?.name}</UsernameText>
          {!!message?.isInHomeStadium && (
            <img src={arena} height={17} width={17} style={{ marginLeft: '8px' }} alt='user is in home stadium' />
          )}
        </UsernameContainer>
        <MessageText>{message.text}</MessageText>
      </MessageContainer>
    </Container>
  );
};
