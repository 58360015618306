import {combineReducers} from '@reduxjs/toolkit';

import accountSetup from './modules/accountSetup';
import authorization from './modules/authorization';
import poll from './modules/poll';
import trivia from './modules/trivia';
import teams from './modules/teams';
// import teamScream from './modules/teamScream';
import pusher from './modules/pusher';
import user from './modules/user';
import game from './modules/game';
import interaction from './modules/interaction';
import scoreboard from './modules/scoreboard';
import media from './modules/media';
import leaderboard from './modules/leaderboard';
import geolocation from './modules/geolocation';
import radio from './modules/radio';
// import videoPlayer from './modules/videoPlayer';
// import audioPlayer from './modules/audioPlayer';

const rootReducer = combineReducers({
  accountSetup,
  authorization,
  pusher,
  poll,
  trivia,
  teams,
  // teamScream,
  media,
  user,
  game,
  interaction,
  scoreboard,
  leaderboard,
  geolocation,
  radio,
  // videoPlayer,
  // audioPlayer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
