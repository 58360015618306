import styled from '@emotion/styled';
import React from 'react';
import {white} from '../../theme/vars';
import {Spacer} from '../layouts/Spacer';

const NavButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;

  background-color: transparent;

  border: none;
  padding-top: 10px;
  padding-bottom: 10px;

  color: ${white};

  h2 {
    font-family: 'Roboto-Regular';
    font-size: 16px;
    letter-spacing: -0.384px;
    text-align: right;
  }
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${white};
`;

export type MenuItemProps = {
  name: string;
  icon: JSX.Element;
  route: string;
  onClick: () => void;
};

type Props = MenuItemProps & {
  showDivider: boolean;
};

const MenuItem = ({name, icon, onClick, showDivider}: Props) => {
  return (
    <div>
      <NavButton onClick={onClick}>
        <h2>{name}</h2>
        <Spacer right="11px" />
        {icon}
      </NavButton>
      {showDivider && <Divider />}
    </div>
  );
};

export default React.memo(MenuItem);
