import {Action, createSlice} from '@reduxjs/toolkit';
import {REHYDRATE} from 'redux-persist';
import {
  addCurrentSubscriptionToList,
  removeCurrentSubscriptionFromList,
} from './helpers';
import {SEASON_TICKET_STREAM_START} from '../../../constants/Pusher.constants';
import {SubscriptionChannelAndData} from '../../../types/pusher';

type PusherState = {
  currentSubscriptions: SubscriptionChannelAndData[];
  gameState: {
    lastInteraction: any | null;
    isHLSLastInteraction: boolean;
    isWaitingOnStreamRestart: boolean;
    isWaitingOnSeasonStreamRestart: boolean;
    seasonTicketStreamUrl: any | null;
    hlsStreamUrl: any | null;
  };
};

const initialState: PusherState = {
  currentSubscriptions: [],
  gameState: {
    lastInteraction: null,
    isHLSLastInteraction: false,
    isWaitingOnStreamRestart: false,
    isWaitingOnSeasonStreamRestart: false,
    seasonTicketStreamUrl: null,
    hlsStreamUrl: null,
  },
};

const pusherSlice = createSlice({
  name: 'pusher',
  initialState,
  reducers: {
    addCurrentSubscription(state, action) {
      state.currentSubscriptions = addCurrentSubscriptionToList(
        state.currentSubscriptions,
        action.payload,
      );
    },
    removeCurrentSubscription(state, action) {
      state.currentSubscriptions =
        removeCurrentSubscriptionFromList(
          state.currentSubscriptions,
          action.payload,
        ) || [];
    },
    setLastInteraction(state, action) {
      state.gameState.lastInteraction = action.payload;
    },
    setIsHLSLastInteraction(state, action) {
      state.gameState.isHLSLastInteraction = action.payload;
    },
    setIsWaitingOnStreamRestart(state, action) {
      state.gameState.isWaitingOnStreamRestart = action.payload;
    },
    setIsWaitingOnSeasonStreamRestart(state, action) {
      state.gameState.isWaitingOnSeasonStreamRestart = action.payload;
    },
    setSeasonTicketStreamUrl(state, action) {
      state.gameState.seasonTicketStreamUrl = action.payload;
    },
    setHlsStreamUrl(state, action) {
      state.gameState.hlsStreamUrl = action.payload;
    },
    resetPusherGameState: state => {
      state.gameState = initialState.gameState;
    },
    clearCurrentSubscriptions: state => {
      state.currentSubscriptions = initialState.currentSubscriptions;
    },
  },
  extraReducers: builder => {
    builder.addCase(REHYDRATE, (state, action: ActionWithPayload<any>) => {
      if (
        action?.payload?.interaction?.interactionType ===
        SEASON_TICKET_STREAM_START
      ) {
        state.gameState = {
          ...state.gameState,
          isWaitingOnSeasonStreamRestart: true,
        };
      }
    });
  },
});

export interface ActionWithPayload<T> extends Action {
  payload: T;
}

export const {
  addCurrentSubscription,
  removeCurrentSubscription,
  setLastInteraction,
  setIsHLSLastInteraction,
  setIsWaitingOnStreamRestart,
  setIsWaitingOnSeasonStreamRestart,
  setSeasonTicketStreamUrl,
  setHlsStreamUrl,
  resetPusherGameState,
  clearCurrentSubscriptions,
} = pusherSlice.actions;

export default pusherSlice.reducer;
