import styled from '@emotion/styled';
import Switch from "react-switch";
import audioIcon from '../../../assets/icons/audio.svg';
import { black, curiousBlueActive, white } from '../../../theme/vars';

const Container = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 0 0.3rem;
  font-size: 0.8rem;
`;

const Label = styled.span`
  padding: 0 0.3rem;
  color: ${white};
`;

type Props = {
  onChange: () => void;
  checked: boolean;
  disabled?: boolean;
};

const RadioToggle = ({onChange, checked, disabled = false}: Props) => {
  return (
    <Container>
      <img src={audioIcon} alt="audio icon" />
      <Label id="radio-toggle-label">Talkin' Isles</Label>
      <Switch
        onChange={onChange}
        checked={checked}
        height={18} width={28}
        checkedIcon={false}
        uncheckedIcon={false}
        handleDiameter={10}
        offHandleColor={white}
        onHandleColor={white}
        offColor={black}
        onColor={curiousBlueActive}
        activeBoxShadow='0 0 1px 1px #000'
        aria-labelledby='radio-toggle-label'
        disabled={disabled}
      />
    </Container>
  );
};

export default RadioToggle;
