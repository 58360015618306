import {createSlice} from '@reduxjs/toolkit';
import {AppThunk} from '../../store';
import {MEDIA_ROUTES, request} from '../../../api';

const initialState = {
  loading: false,
  mediaData: null,
  mediaTeamId: null,
  error: null,
};

const mediaSlice = createSlice({
  name: 'media',
  initialState,
  reducers: {
    getBootstrapMediaRequest(state) {
      state.loading = true;
      state.error = null;
    },
    getBootstrapMediaSuccess(state, action) {
      state.loading = false;
      state.mediaData = action.payload.media;
      state.mediaTeamId = action.payload.activeTeamId;
    },
    getBootstrapMediaError(state, action) {
      state.loading = false;
      state.error = action.payload.error;
    },
    clearMedia: () => initialState,
  },
});

export const {
  getBootstrapMediaRequest,
  getBootstrapMediaSuccess,
  getBootstrapMediaError,
  clearMedia,
} = mediaSlice.actions;

const apiGetBootstrapMedia = async (teamId: any) => {
  return request({
    route: MEDIA_ROUTES.BOOTSTRAP_MANIFEST_REQUEST,
    pathParams: {teamId: teamId},
  });
};

export const getBootstrapMedia =
  (activeTeamId?: string): AppThunk =>
  async (dispatch, getState) => {
    const state = getState();
    const _activeTeamId = activeTeamId || state.teams.activeAppTeamId;

    if (!_activeTeamId) {
      dispatch(getBootstrapMediaError({ error: 'Missing active team ID, unable to fetch media.' }));
      return;
    }

    dispatch(getBootstrapMediaRequest());
    try {
      const bootstrap = await apiGetBootstrapMedia(_activeTeamId);
      dispatch(
        getBootstrapMediaSuccess({media: bootstrap.media, activeTeamId: _activeTeamId}),
      );
    } catch (err) {
      dispatch(getBootstrapMediaError((err as Error).message));
    }
  };

export default mediaSlice.reducer;
