import {useSelector} from 'react-redux';
import styled from '@emotion/styled';
import {format, parseISO} from 'date-fns';

// components
import HTImage from '../media/HTImage';
import {Spacer} from '../layouts/Spacer';

// type
import {AssetType} from '../../types/assetType';

// styles
import {white_10, white, white_25} from '../../theme/vars';

// state
import {RootState} from '../../redux/rootReducer';
import {BodyText} from '../typography/BodyText';

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  .game-list-container {
    flex: 1;
    background: ${white_10};
  }

  .game-list-button {
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    width: 100%;
  }

  .game-list-symbol {
    font-family: Roboto-Bold;
    color: ${white};
    font-size: 1rem;
    padding: 0 1rem;
  }

  time {
    font-family: 'Roboto-BoldItalic';
    color: ${white};
    font-size: 1rem;
    padding: 0 1rem;
  }

  .game-list-item {
    padding: 0.5rem 0;
    border-bottom: 1px solid ${white_25};
  }
`;

// TODO: Update these types
type Props = {
  participationTeamGames: any;
  handleGameSelection: (game: any) => void;
};

const LeaderboardGameList = ({
  participationTeamGames,
  handleGameSelection,
}: Props) => {
  const {teamsData} = useSelector((state: RootState) => state.teams);

  const getTeam = (id: any) => {
    return teamsData.find(team => team.id === id);
  };

  const LeaderboardGameListRow = ({game}: {game: any}) => {
    const {teamId, homeTeamId, awayTeamId, gameTime} = game;
    const isHomeTeam = teamId === homeTeamId;
    const symbol = isHomeTeam ? 'vs' : '@';
    const opponent = getTeam(isHomeTeam ? awayTeamId : homeTeamId);

    return (
      <li className="game-list-item">
        <button
          onClick={() => handleGameSelection(game)}
          className="game-list-button">
          <span className="game-list-symbol">{symbol}</span>
          <HTImage
            width="2.5rem"
            type={AssetType.TeamLogo}
            teamId={opponent.id}
            altText={opponent.name}
          />
          <Spacer left="1rem" right="1rem" flex>
            <BodyText align="left">{opponent.homeTown}</BodyText>
          </Spacer>
          <time>{format(parseISO(gameTime), 'MMM d')}</time>
        </button>
      </li>
    );
  };

  const BodyTextSpan = BodyText.withComponent('span');

  return (
    <Container>
      <ul className="game-list-container">
        {participationTeamGames &&
          participationTeamGames.map((game: any) => {
            return <LeaderboardGameListRow key={game.gameId} game={game} />;
          })}
        {participationTeamGames && participationTeamGames.length === 0 &&
          <li style={{ display: 'flex', width: '100%' }}>
            <Spacer top={'1.5rem'} />
            <BodyTextSpan color={white} style={{ width: '100%', textAlign: 'center', padding: '20px 10px' }}>
              No participation data available.
            </BodyTextSpan>
            <Spacer top={'1.5rem'} />
          </li>
        }
      </ul>

    </Container>
  );
};

export default LeaderboardGameList;
