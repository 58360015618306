import {createSlice} from '@reduxjs/toolkit';
import {LOGIN_ROUTES, request, USER_ROUTES} from '../../../api';
import errorMessages from '../../../constants/errorMessages';
import {
  FirstLastName,
  HomeTurfUser,
  UserAccountSetup,
} from '../../../types/user';
import { clearUserCookies, setAuthToken, setUserId } from '../../../utils/cookies';
import {AppThunk} from '../../store';
import { clearAvatarState, setAvatarSourceSuccess } from '../accountSetup/avatarSource';
import { resetGameState, resetJoinGameStatus, setGetstreamToken } from '../game';
import { USER_AUTH_TOKEN_EXPIRATION_DAYS } from '../../../constants/Global.constants';
import { clearPhoneNumberState } from '../authorization/standaloneSMS';
import { clearAccessToken } from '../authorization/accessToken';
import { trackUserId, trackUserProperties } from '../../../utils/analytics';


type SliceState = {
  loading: boolean;
  settingAccountDetails: boolean;
  userData: HomeTurfUser | null;
  getUserError: string | null;
  getGuestUserError: string | null;
  updateUserError: string | null;
  hasSeenTutorial: boolean;
  hasSeenValueProp: boolean;
  hasSeenWelcomeVideo: boolean;
};

const initialState: SliceState = {
  loading: false,
  settingAccountDetails: false,
  userData: null,
  getUserError: null,
  getGuestUserError: null,
  updateUserError: null,
  hasSeenTutorial: false,
  hasSeenValueProp: false,
  hasSeenWelcomeVideo: false,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    getUserRequest(state) {
      state.loading = true;
      state.getUserError = null;
    },
    getGuestUserRequest(state) {
      state.loading = true;
      state.getGuestUserError = null;
    },
    getUserSuccess(state, action) {
      state.loading = false;
      state.userData = action.payload.user;
    },
    getUserError(state, action) {
      state.loading = false;
      state.getUserError = action.payload;
    },
    getUserAndAppTokensRequest(state) {
      state.loading = true;
      state.getUserError = null;
    },
    getUserAndAppTokensSuccess(state, action) {
      state.loading = false;
      state.userData = action.payload.user;
    },
    getUserAndAppTokensError(state, action) {
      state.loading = false;
      state.getUserError = action.payload.error;
    },
    putAccountDetailsRequest(state) {
      state.loading = true;
      state.updateUserError = null;
    },
    putAccountDetailsSuccess(state, action) {
      state.loading = false;
      state.userData = action.payload.user;
    },
    putAccountDetailsError(state, action) {
      state.loading = false;
      state.updateUserError = action.payload;
    },
    setHasSeenTutorial(state, action) {
      state.hasSeenTutorial = action.payload;
    },
    setHasSeenValueProp(state, action) {
      state.hasSeenValueProp = action.payload;
    },
    setHasSeenWelcomeVideo(state, action) {
      state.hasSeenWelcomeVideo = action.payload;
    },
    setUser(state, action) {
      state.userData = action.payload.user;
    },
    updateUserProfilePicPath(state, action) {
      if (state.userData) {
        state.userData.profilePicPath = action.payload.profilePicPath;
      }
    },
    clearUser(state) {
      state.userData = null;
      state.loading = false;
      state.getUserError = null;
    },
    clearUpdateUserError(state) {
      state.updateUserError = null;
    },
  },
});

export const {
  getUserRequest,
  getGuestUserRequest,
  getUserSuccess,
  getUserError,
  getUserAndAppTokensRequest,
  getUserAndAppTokensSuccess,
  getUserAndAppTokensError,
  putAccountDetailsRequest,
  putAccountDetailsSuccess,
  putAccountDetailsError,
  setHasSeenTutorial,
  setHasSeenWelcomeVideo,
  setHasSeenValueProp,
  setUser,
  updateUserProfilePicPath,
  clearUser,
  clearUpdateUserError,
} = userSlice.actions;

const apiGetUser = async () => {
  return request({
    route: USER_ROUTES.GET_USER,
  });
};

const apiGenerateGuestUser = async (
  teamId: string,
) => {
  return request({
    route: USER_ROUTES.GENERATE_GUEST_USER,
    body: {
      teamId,
    }
  });
};


const apiGetUserTokens = async (
  accessToken: string,
  userSub: string,
  teamId: string,
) => {
  return await request({
    route: LOGIN_ROUTES.GET_USER_AND_APP_TOKENS,
    body: {
      accessToken,
      user: userSub,
      teamId,
    },
  });
};

const apiPutAccountDetails = async (details: any) => {
  return request({
    route: USER_ROUTES.SUBMIT_ACCOUNT_DETAILS,
    body: details,
  });
};

const apiPatchUserAnalytics = async (analytics: any) => {
  return request({
    route: USER_ROUTES.UPDATE_USER_ANALYTICS,
    body: analytics,
  });
};

const apiPatchUserPrivacy = async (privacy: any) => {
  return request({
    route: USER_ROUTES.UPDATE_USER_PRIVACY,
    body: privacy,
  });
};

const apiPatchUserPersonalInfo = async (personalInfo: any) => {
  return request({
    route: USER_ROUTES.UPDATE_USER_PERSONAL_INFO,
    body: personalInfo,
  });
};

const apiPatchUserSeasonTicketNumber = async (seasonTicketInfo: any) => {
  return request({
    route: USER_ROUTES.UPDATE_USER_SEASON_TICKET,
    body: seasonTicketInfo,
  });
};

const apiPatchAccountDetails = async (details: any) => {
  return request({
    route: USER_ROUTES.UPDATE_USER_ACCOUNT_INFO,
    body: details,
  });
};

export const getUserAndAppTokens =
  (accessToken: string, userSub: string): AppThunk =>
  async (dispatch, getState) => {
    dispatch(getUserAndAppTokensRequest());
    try {
      const teamId = getState().teams.activeAppTeamId;
      const {user, authToken} = await apiGetUserTokens(
        accessToken,
        userSub,
        teamId,
      );
      setUserToken({user, authToken});
      dispatch(getUserAndAppTokensSuccess({user}));
      dispatch(setAvatarSourceSuccess(user.profilePicPath));
    } catch (err) {
      dispatch(getUserAndAppTokensError((err as Error).message));
    }
  };

export const logoutUser = (): AppThunk => (dispatch, getState) => {
    clearUserCookies();
    dispatch(clearUser());
    dispatch(clearPhoneNumberState());
    dispatch(clearAccessToken());
    dispatch(clearAvatarState());
    dispatch(resetGameState());
    dispatch(resetJoinGameStatus());
};

export function setUserToken({
  user,
  authToken,
}: {
  user: HomeTurfUser;
  authToken: string;
}) {
  setAuthToken(authToken, USER_AUTH_TOKEN_EXPIRATION_DAYS);
  setUserId(user.id, USER_AUTH_TOKEN_EXPIRATION_DAYS);
  // trackUserId(user.id);
}

type AccountDetailsArgs = {
  details: UserAccountSetup;
  completionCallback: (isPutting: boolean) => void;
};

type UserAnalyticsArgs = {
  analytics: any;
  completionCallback: (isPutting: boolean) => void;
};

type UserPrivacyArgs = {
  privacy: any;
  completionCallback: (isPutting: boolean) => void;
};

type UserPersonalInfoArgs = {
  details: FirstLastName;
  completionCallback: (isPutting: boolean) => void;
};

type UserSeasonTicketArgs = {
  seasonTicketInfo: any;
  completionCallback: (isPutting: boolean) => void;
};

type UserAccountInfoArgs = {
  accountInfo: any;
  completionCallback: (isPutting: boolean) => void;
};

const getUpdateUserErrorMessageString = (err: Error) => {
  let errorContent;
  const splitAndTrimMessage = (str: string) => {
    const n = str.split(' ');
    return n[n.length - 1].split('"')[0];
  };
  if (err.message.includes('Unsafe content:')) {
    errorContent = splitAndTrimMessage(err.message);
    return `The ${errorContent} is inappropriate, please enter a different ${errorContent} to continue.`;
  } else if (err.message.includes('UniqueConstraint Error:')) {
    errorContent = splitAndTrimMessage(err.message);
    return `The ${errorContent} is already in use, please enter a different ${errorContent} to continue.`;
  } else {
    return err.message;
  }
};

export const updateUserAccountInfo =
  ({accountInfo, completionCallback}: UserAccountInfoArgs): AppThunk =>
  async dispatch => {
    dispatch(putAccountDetailsRequest());
    try {
      const user = await apiPatchAccountDetails(accountInfo);
      dispatch(putAccountDetailsSuccess({user}));
      completionCallback(true);
    } catch (err) {
      const errorMessage = getUpdateUserErrorMessageString(err as Error);

      completionCallback(false);
      dispatch(putAccountDetailsError(errorMessage));
    }
  };

export const updateUserSeasonTicketNumber =
  ({seasonTicketInfo, completionCallback}: UserSeasonTicketArgs): AppThunk =>
  async dispatch => {
    dispatch(putAccountDetailsRequest());
    try {
      const user = await apiPatchUserSeasonTicketNumber(seasonTicketInfo);

      dispatch(putAccountDetailsSuccess({user}));
      completionCallback(true);
    } catch (err) {
      let errorContent = errorMessages.putAccountDetailsRequestError;

      completionCallback(false);

      const splitAndTrimMessage = (str: string) => {
        const n = str.split('"message":"');
        return n[n.length - 1].split('"')[0];
      };

      if ((err as Error).message.includes('"Validation"')) {
        errorContent =
          splitAndTrimMessage((err as Error).message) || errorContent;
      }

      dispatch(putAccountDetailsError(errorContent));
    }
  };

export const updateUserPersonalInfo =
  ({details, completionCallback}: UserPersonalInfoArgs): AppThunk =>
  async dispatch => {
    dispatch(putAccountDetailsRequest());
    try {
      const user = await apiPatchUserPersonalInfo(details);
      dispatch(putAccountDetailsSuccess({user}));
      completionCallback(true);
    } catch (err) {
      const errorMessage = getUpdateUserErrorMessageString(err as Error);

      completionCallback(false);
      dispatch(putAccountDetailsError(errorMessage));
    }
  };

export const updateUserPrivacy =
  ({privacy, completionCallback}: UserPrivacyArgs): AppThunk =>
  async dispatch => {
    dispatch(putAccountDetailsRequest());
    try {
      const user = await apiPatchUserPrivacy(privacy);

      dispatch(putAccountDetailsSuccess({user}));
      completionCallback(true);
    } catch (err) {
      completionCallback(false);
      dispatch(
        putAccountDetailsError(errorMessages.putAccountDetailsRequestError),
      );
    }
  };

export const updateUserAnalytics =
  ({analytics, completionCallback}: UserAnalyticsArgs): AppThunk =>
  async dispatch => {
    dispatch(putAccountDetailsRequest());
    try {
      const user = await apiPatchUserAnalytics(analytics);

      dispatch(putAccountDetailsSuccess({user}));
      completionCallback(true);
    } catch (err) {
      completionCallback(false);
      dispatch(
        putAccountDetailsError(errorMessages.putAccountDetailsRequestError),
      );
    }
  };

export const putAccountDetails =
  ({details, completionCallback}: AccountDetailsArgs): AppThunk =>
  async dispatch => {
    dispatch(putAccountDetailsRequest());
    try {
      const user = await apiPutAccountDetails(details);
      dispatch(putAccountDetailsSuccess({user}));
      completionCallback(true);
    } catch (err) {
      const errorMessage = getUpdateUserErrorMessageString(err as Error);

      completionCallback(false);
      dispatch(putAccountDetailsError(errorMessage));
    }
  };

export const getUser = (): AppThunk => async dispatch => {
  dispatch(getUserRequest());
  try {
    const user = await apiGetUser();
    dispatch(getUserSuccess({user}));
    trackUserId(user.id);
    trackUserProperties({phoneNumber: user.phoneNumber, emailAddress: user.emailAddress});
  } catch (err) {
    dispatch(getUserError((err as Error).message));
  }
};

export const getGuestUser = (teamId: string): AppThunk => async dispatch => {
  dispatch(getGuestUserRequest());
  try {
    console.log('getGuestUser');
    const {user, authToken, getstreamToken}  = await apiGenerateGuestUser(teamId);
    setUserToken({user, authToken});
    dispatch(getUserSuccess({user}));
    dispatch(setGetstreamToken(getstreamToken));
    trackUserId(user.id);
    trackUserProperties({guestUser: true});
  } catch (err) {
    dispatch(getUserError((err as Error).message));
  }
};



export default userSlice.reducer;
