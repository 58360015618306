import { useCallback, useEffect } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import styled from '@emotion/styled';

// state
import {RootState} from '../../redux/rootReducer';

// components
import GameCard from '../../components/gameCard/GameCard';
import {HeaderLarge} from '../../components/typography/HeaderLarge';
import {BodyText} from '../../components/typography/BodyText';

// style
import {black, defaultThemeColor} from '../../theme/vars';

// type
import { AnyAction } from 'redux';
import { TailSpin } from 'react-loader-spinner';
import LoggedInViewWrapper from '../../wrappers/LoggedInViewWrapper';
import { getTeamGameSchedule } from '../../redux/modules/teams';
// import HomeTurf from '../../assets/images/hometurf.svg';
import { geolocateUser} from '../../redux/modules/geolocation';
import HomeLayout from '../../components/layouts/HomeLayout';
import DefaultLayout from '../../components/layouts/DefaultLayout';
import { useLocation } from 'react-router-dom';
import useQuery from '../../hooks/useQuery';

type Props = {
  background: {color?: string; image?: string};
};

export const Container = styled.div<Props>`
  background: ${({background}) => background.color || defaultThemeColor};
  //height: calc(100vh - 60px);
  flex: 1;
  overflow-y: scroll;
  width: 100%;
  padding-top: 2rem;
  //padding-top: 134px;
  &:before {
    content: '';
    background: url(${({background}) => background.image}) no-repeat;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-size: contain;
    background-position: center;
    opacity: 0.3;
    z-index: 0;
    pointer-events: none;
  }

  .card-wrapper {
    padding: 0 0.75rem;
    max-width: 600px;
    margin: 0 auto;
    padding-bottom: 5rem;
    position: relative;
    z-index: 1;
    text-align: center;
  }
`;

const CenterContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
`;


// const PoweredByContainer = styled.div`
//   margin-top: 2rem;
// `;


const Home = () => {
  const dispatch = useDispatch();
  const {userData: user} = useSelector((state: RootState) => state.user);
  const {
    nextGame,
    activeTeam,
    loadingNextGame,
  } = useSelector((state: RootState) => state.teams);

  const {
    geolocating,
    geocodingData,
    geocodingError,
  } = useSelector((state: RootState) => state.geolocation);

  const {search} = useLocation();
  const query = useQuery(search);
  const bypassStadiumKey = query.get('bypassKey');

  useEffect(() => {
    console.log('home - requesting team game schedule');
    dispatch(getTeamGameSchedule() as unknown as AnyAction);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect( () => {
    if (!geocodingError && !geolocating && !geocodingData && !loadingNextGame && !!nextGame) {
      dispatch(geolocateUser(bypassStadiumKey) as unknown as AnyAction);
    }
  }, [dispatch, geocodingData, geolocating, loadingNextGame, nextGame, geocodingError, bypassStadiumKey]);

  const isReadyToRender = useCallback(() => {
    return !(geolocating || !geocodingData || loadingNextGame || !activeTeam);
  }, [geocodingData, geolocating, loadingNextGame, activeTeam]);



  const HomeContent = () => {
    if (!isReadyToRender()) {
      return (
        <DefaultLayout>
          <CenterContainer>
            <TailSpin color="#00BFFF" />
          </CenterContainer>
        </DefaultLayout>
      )
    } else {
      return (
        <LoggedInViewWrapper>
          <HomeLayout>
            <Container background={{color: black}}>
              <div className="card-wrapper">
                {activeTeam && nextGame && (
                  <GameCard
                    key={nextGame.id}
                    game={nextGame}
                    activeTeamId={activeTeam.id}
                    user={user}
                  />
                )}
                { activeTeam &&
                !nextGame && (
                  <>
                    <HeaderLarge>Thank You!</HeaderLarge>
                    <BodyText>
                      There are no upcoming games scheduled. Please check back soon!
                    </BodyText>
                  </>
                )
                }
                {/*<PoweredByContainer>*/}
                {/*  <BodyText size={'.8em'}>powered by</BodyText>*/}
                {/*  <img width='84px' height='11px' style={{ marginTop: '18px', marginLeft: '5px' }} src={HomeTurf} alt='HomeTurf logo' />*/}
                {/*</PoweredByContainer>*/}
              </div>
            </Container>
          </HomeLayout>
        </LoggedInViewWrapper>
      )
    }
  };

  return (
    <HomeContent />
  );
};

export default Home;
