import React, {ReactNode} from 'react';
import styled from '@emotion/styled';
import {Button} from './Button';

type SVGIconButtonContainerProps = {
  fill?: string;
};

const SVGIconButtonContainer = styled(Button)<SVGIconButtonContainerProps>`
  display: inline-block;
  background: transparent;
  flex: 0;
  margin: 0;
  padding: 0;
  fill: ${({fill}) => fill || 'auto'};

  svg path {
    ${({fill}) => fill && {fill: fill}};
  }

  svg circle {
    ${({fill}) => fill && {stroke: fill}};
  }

  &:hover,
  &:active {
    background-color: inherit;
  }
`;

type Props = {
  icon: ReactNode;
  onClick: () => void;
  fill?: string;
  disabled?: boolean;
};

const SVGIconButton = ({icon, fill, onClick, disabled = false}: Props) => {
  return (
    <SVGIconButtonContainer
      type="button"
      fill={fill}
      onClick={onClick}
      disabled={disabled}>
      {icon}
    </SVGIconButtonContainer>
  );
};

export default SVGIconButton;
