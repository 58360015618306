import { useCallback, useEffect, useState } from 'react';
import {useSelector, useDispatch} from 'react-redux';
import styled from '@emotion/styled';
import ReactCardFlip from 'react-card-flip';
import {RootState} from '../../redux/rootReducer';
import {format, parseISO} from 'date-fns';
import {TailSpin} from 'react-loader-spinner';

// components
import LeaderboardList from './LeaderboardList';
import LeaderboardGameList from './LeaderboardGameList';
import {Spacer} from '../layouts/Spacer';

// state
import {getUsersTeamGameLeaderboard} from '../../redux/modules/leaderboard';

// style
import {white} from '../../theme/vars';
import {AnyAction} from '@reduxjs/toolkit';
import {UserLeaderboardHeader} from '../../types/leaderboard';
import LeaderboardHeader from './LeaderboardHeader';

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 0 0.5rem;
  margin: 0 auto;
  max-width: 600px;
  align-items: center;
  position: relative;

  .game-leaderboard {
    position: absolute;
    z-index: 1;
    width: 100%;
    padding: 0 0.5rem;
  }
`;

type Props = {
  gameId?: string | null;
  inGame?: boolean;
};

const LeaderboardWrapper = ({gameId, inGame}: Props) => {
  const [isFlipped, setIsFlipped] = useState(false);
  const [_gameId, setGameId] = useState(gameId);
  const {teamsData, nextGame} = useSelector((state: RootState) => state.teams);
  const [teamGameUserLeaderboardHeader, setTeamGameUserLeaderboardHeader] =
    useState<UserLeaderboardHeader | undefined>(undefined);
  const dispatch = useDispatch();
  const {
    participationTeamGames,
    usersTeamLeaderboard,
    usersTeamGameLeaderboard,
    isRequestingUsersTeamLeaderboard,
    isRequestingParticipationTeamGames,
    isRequestingUsersTeamGameLeaderboard,
  } = useSelector((state: RootState) => state.leaderboard);

  const getTeam = useCallback((id: string) => {
    return teamsData.find(team => team.id === id);
  },[teamsData]);

  const getGameHeader = useCallback((game: any) =>  {
    const {awayTeamId, homeTeamId} = game;
    return `${getTeam(awayTeamId).homeTown} at ${getTeam(homeTeamId).homeTown}`;
  },[getTeam]);

  const handleGameSelection = useCallback((game: any) => {
    const {gameId} = game;
    setGameId(gameId);
    dispatch(getUsersTeamGameLeaderboard(gameId) as unknown as AnyAction);
    const header = {
      header: getGameHeader(game),
      date: format(parseISO(game.gameTime), 'iiii M/dd/yy'),
    };
    setTeamGameUserLeaderboardHeader(header);
  },[dispatch, getGameHeader]);

  const clearGameSelection = () => {
    setTeamGameUserLeaderboardHeader(undefined);
    setGameId(null);
  }

  useEffect(() => {
    if (!isRequestingParticipationTeamGames && _gameId) {
      const activeGame = participationTeamGames.find((game: any) => game.gameId === _gameId);
      if (activeGame) {
        handleGameSelection(activeGame);
      } else if (_gameId === nextGame?.id) {
        handleGameSelection({gameId: nextGame.id, ...nextGame});
      }
    }
  },[_gameId, isRequestingParticipationTeamGames, handleGameSelection, participationTeamGames, nextGame])

  useEffect(() => {
    return () => {
      setGameId(null);
      setTeamGameUserLeaderboardHeader(undefined);
      setIsFlipped(false);
    }
  },[])

  return (
    <Container>
      {isRequestingUsersTeamGameLeaderboard || isRequestingUsersTeamLeaderboard || isRequestingParticipationTeamGames ? (
        <Spacer flex center>
          <TailSpin color={white} height={75} width={75} />
        </Spacer>
        ) : _gameId && teamGameUserLeaderboardHeader ? (
          <>
            <LeaderboardHeader
              textTransform="none"
              subheader={teamGameUserLeaderboardHeader.date}
              inGame={inGame}
              onClose={clearGameSelection}

            >
              {teamGameUserLeaderboardHeader.header}
            </LeaderboardHeader>
            <LeaderboardList
              leaderboardData={usersTeamGameLeaderboard}
            />
          </>
        )
       : (
          <ReactCardFlip
            containerStyle={{width: '100%', height: '100%'}}
            isFlipped={isFlipped}
            flipDirection="horizontal">
            <>
              <LeaderboardHeader
                textTransform="none"
                inGame
                onClose={clearGameSelection}
              >
                SEASON LEADERBOARD
              </LeaderboardHeader>
              <LeaderboardList
                leaderboardData={usersTeamLeaderboard}
                setIsFlipped={setIsFlipped}
              />
            </>

            {isRequestingParticipationTeamGames ? (
              <Spacer flex center>
                <TailSpin color={white} height={75} width={75} />
              </Spacer>
            ) : (
              <>
                <LeaderboardHeader onClose={() => setIsFlipped(!isFlipped)}>
                  GAME PARTICIPATION
                </LeaderboardHeader>
                <LeaderboardGameList
                  participationTeamGames={participationTeamGames}
                  handleGameSelection={handleGameSelection}
                />
              </>

            )}
          </ReactCardFlip>
        )
      }
    </Container>
  );
};

export default LeaderboardWrapper;
