import {darkBlue, white} from '../../theme/vars';
import styled from '@emotion/styled';
import Drawer from 'react-modern-drawer';
import 'react-modern-drawer/dist/index.css';
import {useNavigate} from 'react-router-dom';
import MenuItem, {MenuItemProps} from './MenuItem';
import HomeTurf from '../../assets/images/hometurf.svg';
import routes from '../../constants/routes.constants';
import { trackEventData } from '../../utils/analytics';
import { NAVIGATE_FROM_MENU } from '../../constants/analytics.constants';
import { environment } from '../../environments/environment';
import { BodyText } from '../typography/BodyText';
import { Spacer } from '../layouts/Spacer';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-height: (calc(100dvh - 86px));
  width: 100%;
  background-color: ${darkBlue};
  padding: 20px 16px 20px 24px;
  color: ${white};
  overflow-y: scroll;

  h1 {
    font-family: 'Industry-Bold';
    font-size: 21px;
    line-height: 25px;
    display: flex;
    letter-spacing: -0.288px;
  }
`;

const Header = styled.button`
  background-color: transparent;
  border: none;
  color: ${white};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  height: min-content;
  max-width: fit-content;
  align-self: flex-end;

  padding-bottom: 5px;

  img {
    width: 41px;
    height: 41px;
    border: 1px solid ${white};
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
  }
`;

type Props = {
  open: boolean;
  onClose: () => void;
  username: string;
  avatar: string;
  isGuestUser: boolean;
  menuItems: MenuItemProps[];
  gameId?: string;
};

const Menu = ({open, onClose, username, avatar, isGuestUser, menuItems, gameId}: Props) => {
  const navigate = useNavigate();

  const onClickUser = () => {
    if (!isGuestUser && gameId) {
      navigate(routes.editProfile.replace(':gameId', gameId));
    }
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      direction="right"
      lockBackgroundScroll
      style={{
        top: '87px',
        width: '257px',
        backgroundColor: 'transparent',
        boxShadow: 'none',
        height: 'inherit'
      }}>
      <Container>
        <Header onClick={onClickUser} disabled={isGuestUser}>
          <h1>{isGuestUser ? 'Guest User' : username ? username : 'User'}</h1>
          {/*<Spacer right="11px" />*/}
          {/*<img src={avatar} alt={username} />*/}
        </Header>
        {menuItems.map((item, index) => (
          <MenuItem
            key={`menu-item-${index}`}
            name={item.name}
            icon={item.icon}
            route={item.route}
            onClick={()=> {
              trackEventData(NAVIGATE_FROM_MENU, {name: item.name, route: item.route});
              item.onClick();
            }}
            showDivider={index !== menuItems.length - 1}
          />
        ))}
        <img width='84px' height='11px' style={{ marginTop: '18px' }} src={HomeTurf} alt='HomeTurf logo' />
        {environment.appVersion &&
          <>
            <Spacer top={'20px'}/>
            <BodyText align={'right'} size={'.7rem'}>version: {environment.appVersion}</BodyText>
          </>
        }
      </Container>
    </Drawer>
  );
};

export default Menu;
