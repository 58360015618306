import {createSlice} from '@reduxjs/toolkit';
import {AppThunk} from '../../store';
// import {clearPlayer, playVideo} from '../videoPlayer';
// import {triggerScoreboard} from '../scoreboard';
// import {US} from '../../../constants/Global.constants';
import {clearTrivia} from '../trivia';
import {clearPoll} from '../poll';
import {showInteraction, showScoreboard, videoReady} from '../../actions';

type Interaction = {
  interactionId: string | null;
  interactionType: string | null;
  mediaId: string | null;
  mediaType: string | null;
  secureUrl: string | null;
  mediaPath: string | null;
  over21: boolean | null;
  repeat: boolean | null;
  statKey: string | null;
  gameId: string | null;
  teamId: string | null;
  metadata: any | null;
  visible: boolean;
};

const initialState: Interaction = {
  interactionId: null,
  interactionType: null,
  mediaId: null,
  mediaType: null,
  secureUrl: null,
  mediaPath: null,
  over21: null,
  repeat: null,
  statKey: null,
  gameId: null,
  teamId: null,
  metadata: null,
  visible: false,
};

const interactionSlice = createSlice({
  name: 'interaction',
  initialState,
  reducers: {
    setInteraction(state, action) {
      state.interactionId = action.payload.interactionId;
      state.interactionType = action.payload.interactionType;
      state.mediaId = action.payload.mediaId;
      state.mediaType = action.payload.mediaType;
      state.secureUrl = action.payload.secureUrl;
      state.mediaPath = action.payload.mediaPath;
      state.over21 = action.payload.over21;
      state.repeat = action.payload.repeat;
      state.metadata = action.payload.metadata;
      state.statKey = action.payload.statKey;
      state.gameId = action.payload.gameId;
      state.teamId = action.payload.teamId;
    },
    clearInteraction(state) {
      state.interactionId = initialState.interactionId;
      state.interactionType = initialState.interactionType;
      state.mediaId = initialState.mediaId;
      state.mediaType = initialState.mediaType;
      state.secureUrl = initialState.secureUrl;
      state.mediaPath = initialState.mediaPath;
      state.over21 = initialState.over21;
      state.repeat = initialState.repeat;
      state.metadata = initialState.metadata;
      state.statKey = initialState.statKey;
      state.gameId = initialState.gameId;
      state.teamId = initialState.teamId;
      state.visible = initialState.visible;
    },
    hideInteraction(state) {
      state.visible = false;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(showScoreboard, state => {
        interactionSlice.caseReducers.clearInteraction(state);
      })
      .addCase(showInteraction, state => {
        state.visible = true;
      })
      .addCase(videoReady, state => {
        interactionSlice.caseReducers.hideInteraction(state);
      });
  },
});

export const {setInteraction, clearInteraction, hideInteraction} =
  interactionSlice.actions;

export const triggerInteraction =
  (interaction: Interaction): AppThunk =>
  async (dispatch, getState) => {
    // const state = getState();
    // const {over21, country} = state.user.userData;
    // const {mediaId: lastMediaId} = state.interaction;
    // const {
    //   over21: interactionOver21,
    //   interactionType,
    //   mediaType,
    //   repeat,
    //   mediaId,
    // } = interaction;
    // const {watchPartyConnected} = state.game;

    // if (!(interactionOver21 && (!over21 || country !== US))) {
    // set new interaction
    // media interactions need special handling
    // TODO: create enum for interaction types and media types

    dispatch(setInteraction(interaction));
    // if (interactionType === 'media' && mediaType === 'video') {
    //   if (watchPartyConnected) {
    //     //bypass video interactions when connected to a watchparty
    //     //potentially do this only for ios?
    //     dispatch(clearInteraction());
    //     dispatch(triggerScoreboard());
    //   } else if (repeat) {
    //     // This addresses issues where the wrong first frame is shown in IOS 13 due to weird seeking issues
    //     if (
    //       (OS.userOS === 'iOS' && OS.userOSver.startsWith('13')) ||
    //       lastMediaId === mediaId
    //     ) {
    //       dispatch(clearPlayer());
    //     }
    //     dispatch(
    //       playVideo({
    //         name: mediaId,
    //         repeat,
    //       }),
    //     );
    //   } else {
    //     if (
    //       (OS.userOS === 'iOS' && OS.userOSver.startsWith('13')) ||
    //       lastMediaId === mediaId
    //     ) {
    //       dispatch(clearPlayer());
    //     }
    //     dispatch(
    //       playVideo({
    //         name: mediaId,
    //         repeat,
    //         onEnd: () => {
    //           dispatch(clearInteraction());
    //           dispatch(triggerScoreboard());
    //         },
    //       }),
    //     );
    //   }
    // } else {
    dispatch(showInteraction());
    // }
    // } else {
    //   dispatch(triggerScoreboard());
    // }
  };

export const resetInteraction = (): AppThunk => async dispatch => {
  dispatch(clearInteraction());
  // dispatch(clearPlayer());
  dispatch(clearTrivia());
  dispatch(clearPoll());
  //dispatch(triggerScoreboard());
};
export default interactionSlice.reducer;
