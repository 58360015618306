import {createSlice} from '@reduxjs/toolkit';
import {AppThunk} from '../../../store';
import {getUserAndAppTokens} from '../../user';

const initialState = {
  loadingAccessToken: false,
  accessToken: null,
  error: null,
};

const authorizationSlice = createSlice({
  name: 'authorization',
  initialState,
  reducers: {
    getAndSetAccessTokenRequest(state) {
      state.loadingAccessToken = true;
      state.error = null;
    },
    getAndSetAccessTokenSuccess(state, action) {
      state.loadingAccessToken = false;
      state.accessToken = action.payload.accessToken;
    },
    getAndSetAccessTokenError(state, action) {
      state.loadingAccessToken = false;
      state.error = action.payload.error;
    },
    clearAccessToken: () => initialState,
  },
});

export const {
  getAndSetAccessTokenRequest,
  getAndSetAccessTokenSuccess,
  getAndSetAccessTokenError,
  clearAccessToken,
} = authorizationSlice.actions;

type GetTokenSilentlyOptions = {
  audience: string;
};

type GetAndSetUserTokensProps = {
  userSub: string;
  getAccessTokenSilently: (
    options?: GetTokenSilentlyOptions,
  ) => Promise<string>;
};

export const getAccessTokenFollowedByUser =
  ({userSub, getAccessTokenSilently}: GetAndSetUserTokensProps): AppThunk =>
  async (dispatch, getState, extraArgument) => {
    dispatch(getAndSetAccessTokenRequest());
    try {
      const accessToken = await getAccessTokenSilently({
        audience: `https://api.hometurfapp.com/v1/api/`,
      });
      dispatch(getAndSetAccessTokenSuccess(accessToken));
      await getUserAndAppTokens(accessToken, userSub)(
        dispatch,
        getState,
        extraArgument,
      );
    } catch (err) {
      dispatch(getAndSetAccessTokenError((err as Error).message));
    }
  };

export default authorizationSlice.reducer;
