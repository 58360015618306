import {createSlice} from '@reduxjs/toolkit';
import {BASE_ROUTES, request} from '../../../../api';
import {AppThunk} from '../../../store';

type SliceState = {
  loading: boolean;
  available: boolean | null;
  error: string | null;
};

const checkUsernameSlice = createSlice({
  name: 'checkUsername',
  initialState: {
    loading: false,
    available: null,
    error: null,
  } as SliceState,
  reducers: {
    setCheckUsernameRequest(state) {
      state.loading = true;
      state.available = null;
      state.error = null;
    },
    setCheckUsernameSuccess(state, action) {
      state.loading = false;
      state.available = !!action.payload.ok;
    },
    setCheckUsernameError(state, action) {
      state.loading = false;
      state.available = false;
    },
    clearCheckUsernameState(state) {
      state.loading = false;
      state.available = null;
      state.error = null;
    },
  },
});

export const {
  setCheckUsernameRequest,
  setCheckUsernameSuccess,
  setCheckUsernameError,
  clearCheckUsernameState,
} = checkUsernameSlice.actions;

const apiGetUser = async (username: string) => {
  return request({
    route: BASE_ROUTES.CHECK_USERNAME,
    pathParams: {username},
  });
};

export const setUsername =
  (username: string): AppThunk =>
  async dispatch => {
    dispatch(setCheckUsernameRequest());
    if (username) {
      try {
        const data = await apiGetUser(username);
        dispatch(setCheckUsernameSuccess(data));
      } catch (err) {
        dispatch(setCheckUsernameError((err as Error).message));
      }
    } else {
      dispatch(clearCheckUsernameState());
    }
  };

export default checkUsernameSlice.reducer;
