const errorMessages = {
  verifyCodeError:
    'An invalid verification code was entered. Enter the HomeTurf one-time 6 digit code sent to',
  verifyCodeRequestError:
    'Request to send a verification code was unsuccessful. Please try again',
  phoneNumberError:
    'The phone number is invalid. Enter a valid phone number to continue with login.',
  phoneNumberRequestError:
    'Attempt to login with phone number was unsuccessful. Please try again',
  userError: 'We experienced an issue getting your profile information',
  userRequestError:
    'Request to get your profile information was unsuccessful. Please try again.',
  joinGameError: 'We experienced an issue joining this game.',
  setAvatarSourceError:
    'Request to upload the avatar failed. Please try again.',
  checkUserNameSuccess: 'Username is taken, please enter a different one.',
  checkUserNameRequestError: 'Request to check the username was unsuccessful.',
  putAccountDetailsRequestError:
    'Request to set account details was unsuccessful.',
  gameScheduleRequestError:
    'Request to retrieve the game schedule was unsuccessful.',
  leaderboardRequestError: 'Request to load the leaderBoard was unsuccessful.',
  teamUserleaderboardRequestError:
    'Request to load the team leaderBoard was unsuccessful.',
  teamGameUserleaderboardRequestError:
    'Request to load the team game leaderBoard was unsuccessful.',
  submitChatMessageRequestError:
    'We were unable submit your chat message.',
  submitChatMessageProfanityError:
    'Your message contains restricted content.  Please edit and try again.',
  participationTeamsRequestError:
    'Request to retrieve participating teams was unsuccessful.',
  participationTeamGamesRequestError:
    'Request to retrieve participating team games was unsuccessful.',
  getTeamsRequestError: 'Request to load teams was unsuccessful.',
  preloadImagesRequestError: 'Request to load media was unsuccessful.',
  setUserPrivacyRequestError: 'Request to set the privacy was unsuccessful',
  setUserPrivacyError:
    'Unable to set the privacy. Please try the toggling again.',
  updateUserAccountInfoError:
    'Unable to update account info. Please try again.',
  updateUserAccountInfoRequestError:
    'Request to update account info was unsuccessful.',
  updateUserPersonalInfoError:
    'Unable to update personal info. Please try again.',
  updateUserPersonalInfoRequestError:
    'Request to update personal info was unsuccessful.',
  updateUserHomeTurfsError:
    'Unable to update hometurf team selection. Please try again.',
  updateUserHomeTurfsRequestError:
    'Request to update hometurf team selection was unsuccessful.',
  updateUserSeasonTicketError:
    'Unable to update season ticket. Please try again.',
  updateUserSeasonTicketRequestError:
    'Request to update season ticket was unsuccessful.',
  userExpiredError:
    'Your session has ended.'
};

export default errorMessages;
