import GenericHeader from './GenericHeader';
import HTLogo from '../media/HTLogo';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/rootReducer';
import RadioToggle from '../media/radio/RadioToggle';
import { toggleIsPlaying } from '../../redux/modules/radio';
import { AnyAction } from 'redux';

type Props = {
  backButton?: boolean;
  customBackAction?: () => void;
  menuButton?: JSX.Element;
  backgroundColor?: string;
  hideRadioToggle?: boolean;
};

const HTHeader = ({
  backButton,
  customBackAction,
  menuButton,
  backgroundColor,
  hideRadioToggle,
}: Props) => {
  const {activeTeam} = useSelector((state: RootState) => state.teams);
  const {isPlaying} = useSelector((state: RootState) => state.radio);
  const dispatch = useDispatch();

  //dispatch(getParticipationTeamGames() as unknown as AnyAction);
  return (
    <GenericHeader
      height="88px"
      backButton={backButton}
      customBackAction={customBackAction}
      menuButton={menuButton}
      backgroundColor={backgroundColor}
      padding="20px">
      <>
        <HTLogo width="168px" />
        {!!activeTeam?.radioUrl && !hideRadioToggle && (
          <RadioToggle checked={isPlaying} onChange={() => { dispatch(toggleIsPlaying() as unknown as AnyAction)}} />
        )}
      </>
    </GenericHeader>
  );
};

export default HTHeader;
