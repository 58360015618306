import styled from '@emotion/styled';
import {useNavigate} from 'react-router-dom';
import chevron1 from '../../assets/icons/chevronLeft.png';
import chevron2 from '../../assets/icons/chevronLeft@2x.png';
import chevron3 from '../../assets/icons/chevronLeft@3x.png';

const Container = styled.button`
  background: none;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 2.5rem;
  height: 2.5rem;
  img {
    width: 20px;
    height: 20px;
  }
`;

type Props = {
  customBackAction?: () => void;
};

const BackButton = ({customBackAction}: Props) => {
  const navigate = useNavigate();
  return (
    <Container
      onClick={() =>
        customBackAction ? customBackAction() : navigate(-1)
      }>
      <img
        src={chevron1}
        alt="Back"
        srcSet={`
          ${chevron2} 2x,
          ${chevron3} 3x,
        `}
      />
    </Container>
  );
};

export default BackButton;
