import {
  MEDIA_ADD,
  INTERACTION,
  INTERACTION_RESULTS,
  TRIVIA,
  POLL,
  GAME_TIME_CHANNEL,
} from '../../constants/Pusher.constants';
import {trackEventData} from '../../utils/analytics';
import {
  INTERACTION_CATEGORY,
  INTERACTION_RECEIVED_ACTION,
} from '../../constants/analytics.constants';
import {triviaResults} from '../../redux/modules/trivia';
import {pollResults} from '../../redux/modules/poll';
import store from '../../redux/store';
import {
  setLastInteraction,
} from '../../redux/modules/pusher';
import {getUserId} from '../../utils/cookies';
import {triggerInteraction} from '../../redux/modules/interaction';

const {dispatch} = store;

export const subscribeToGameTimeGenerator =
  ({
    pusherClient
  }: {
    pusherClient: any;
  }) =>
  ({gameId, teamId}: {gameId: string; teamId: string}) => {
    const userId = getUserId();
    const channel = pusherClient.subscribe(
      `${GAME_TIME_CHANNEL}${gameId};${teamId}`,
    );
    channel.unbind_all();
    channel.bind_global((event: any, data: any) => {
      switch (event) {
        case MEDIA_ADD:
          return;
        case INTERACTION: {
          const interaction = {
            gameId: data.gameId,
            teamId: data.teamId,
            interactionId: data.interactionId,
            interactionType: data.interactionType,
            mediaId: data.media?.id,
            mediaType: data.media?.mediaType,
            mediaPath: data.media?.mediaPath,
            secureUrl: data.media?.secureUrl,
            over21: data.media?.over21,
            repeat: data.media?.repeat,
            statKey: data.statKey,
            metadata: data.metadata,
          };

          // Track event data with Analytics

          trackEventData(INTERACTION_CATEGORY, {
            action: INTERACTION_RECEIVED_ACTION,
            interaction_type: data.interactionType,
            media_id: data.media?.id || '',
            media_title: data.media?.title || '',
            user_id: userId,
            interaction_id: data.interactionId,
            game_id: gameId,
            team_id: teamId,
          });

          dispatch(setLastInteraction(data.interactionType));
          dispatch(triggerInteraction({...interaction, visible: true}));
          break;
        }

        case INTERACTION_RESULTS: {
          switch (data.interactionType) {
            case TRIVIA:
              dispatch(triviaResults(data));
              break;
            case POLL:
              dispatch(pollResults(data));
              break;
            default:
              break;
          }
          break;
        }
        default:
          return;
      }
    });
  };

export const unsubscribeFromGameTimeGenerator =
  ({pusherClient}: {pusherClient: any}) =>
  ({gameId, teamId}: {gameId: string; teamId: string}) => {
    const channelName = `${GAME_TIME_CHANNEL}${gameId};${teamId}`;
    pusherClient.unsubscribe(channelName);
  };
