import PusherProvider from '../providers/PusherProvider';

type Props = {
  children: React.ReactNode;
};

const EventWrapper = ({children}: Props) => {
  return <PusherProvider>{children}</PusherProvider>;
};

export default EventWrapper;
