import * as Cookies from 'es-cookie';

const COOKIE_PREFIX = 'homeTurf'; // TODO: Possibly add env?
export const AUTH_TOKEN = `${COOKIE_PREFIX}/authToken`;
export const USER_ID = `${COOKIE_PREFIX}/userId`;

const setToken = (tokenName: string, token: string, expires?: number) => {
  if (expires) return Cookies.set(tokenName, token, { expires });
  return Cookies.set(tokenName, token);
};

export const setAuthToken = (authToken: string, expires?: number) => {
  return setToken(AUTH_TOKEN, authToken, expires);
};
export const getAuthToken = () => {
  return Cookies.get(AUTH_TOKEN);
};
export const clearAuthToken = () => {
  return Cookies.remove(AUTH_TOKEN);
};

export const setUserId = (userId: string, expires?: number) => {
  return setToken(USER_ID, userId, expires);
};
export const getUserId = () => {
  return Cookies.get(USER_ID);
};
export const clearUserId = () => {
  return Cookies.remove(USER_ID);
};

export const clearUserCookies = () => {
  clearAuthToken();
  clearUserId();
};
