import {createSlice} from '@reduxjs/toolkit';
import {AppThunk} from '../../store';
import {GeocodeData, checkIsInStadium, getGeocodeCountry} from '../../../utils/geocoding';
import { GEOCODING_TIMEOUT_MS } from '../../../constants/Global.constants';
import { bypassStadiumKeyWhitelist, environment } from '../../../environments/environment';

export type GeolocationType = {
  geolocating: boolean;
  geocodingData: GeocodeData | null;
  geocodingError: string | null;
  isInHomeStadium: boolean;
  bypassStadiumCheck: boolean;
};

const initialState: GeolocationType = {
  geolocating: false,
  geocodingData: null,
  geocodingError: null,
  isInHomeStadium: false,
  bypassStadiumCheck: false,
};

const geolocationSlice = createSlice({
  name: 'geolocation',
  initialState,
  reducers: {
    setGeolocating(state, action) {
      state.geolocating = action.payload.geolocating;
    },
    setGeocodingData(state, action) {
      state.geocodingData = action.payload.geocodingData;
    },
    setGeocodingError(state, action) {
      state.geocodingError = action.payload.geocodingError;
    },
    setIsInHomeStadium(state, action) {
      state.isInHomeStadium = action.payload.isInHomeStadium;
    },
    setBypassStadiumCheck(state, action) {
      state.bypassStadiumCheck = action.payload;
    },
    resetGeolocationState: () => initialState,
  },
});

export const {
  setGeolocating,
  setGeocodingData,
  setGeocodingError,
  setIsInHomeStadium,
  setBypassStadiumCheck,
  resetGeolocationState,
} = geolocationSlice.actions;

// Wrap browser geolocation in an async Promise 
function getPosition(options?: any): Promise<GeolocationPosition> {
  return new Promise((resolve, reject) =>
    navigator.geolocation.getCurrentPosition(resolve, reject, options)
  );
}

export const geolocateUser =
  (bypassStadiumKey?: string | null | undefined): AppThunk =>
  async (dispatch, getState) => {
    const state = getState();
    if (state.geolocation.geolocating) return;

    dispatch(setGeolocating({geolocating: true}));

    if (navigator.geolocation) {
      const timeout = setTimeout(() => {
        dispatch(setGeocodingError({geocodingError: 'Geolocation timed out'}));
        dispatch(setGeolocating({geolocating: false}));
      }, GEOCODING_TIMEOUT_MS);
      try {
        console.time('geolocation');
        const position = await getPosition({ 
          enableHighAccuracy: true, 
          timeout: 15000, 
          maximumAge: 300000 
        });
      
        console.timeLog('geolocation', [{event: 'getCurrentPosition complete'}]);
        const latitude = position.coords.latitude.toString();
        const longitude = position.coords.longitude.toString();
        console.log(`geolocation: latitude=${latitude}`);
        console.log(`geolocation: longitude=${longitude}`);
        const country = await getGeocodeCountry({latitude, longitude});
        console.timeLog('geolocation', [{ event: 'getGeocodeCountry complete' }]);
        if (!country) {
          dispatch(setGeocodingError({geocodingError: 'Geolocation error occurred'}));
          dispatch(setGeolocating({geolocating: false}));
          clearTimeout(timeout);
          console.timeLog('geolocation', [{ event: 'no country' }]);
          console.timeEnd('geolocation');
          return;
        }

        const { stadiumCoordinates } = state.teams.nextGame || {};
        const { bypassStadiumCheck } = state.geolocation;

        if (bypassStadiumCheck || (environment.lockToStadium === 'false') || (bypassStadiumKey && bypassStadiumKeyWhitelist.includes(bypassStadiumKey)) || (!!position && !!stadiumCoordinates && checkIsInStadium({latitude, longitude, country}, stadiumCoordinates, true))) {
          console.log(`geolocation: user is in home stadium`);
          dispatch(setIsInHomeStadium({isInHomeStadium: true}));
          dispatch(setBypassStadiumCheck(true));
        } else {
          console.log(`geolocation: user is NOT in home stadium`);
          dispatch(setIsInHomeStadium({isInHomeStadium: false}));
        }
        dispatch(setGeocodingData({geocodingData: {latitude, longitude, country}}));
        dispatch(setGeocodingError({geocodingError: null}));
        dispatch(setGeolocating({geolocating: false}));
        clearTimeout(timeout);
        console.timeEnd('geolocation');
      } catch (error) {
          dispatch(setGeocodingError({geocodingError: 'Geolocation error occurred'}));
          dispatch(setGeolocating({geolocating: false}));
          clearTimeout(timeout);
          console.timeLog('geolocation', [{ event: 'error' }]);
          console.timeEnd('geolocation');
      }
    } else {
      dispatch(setGeolocating({geolocating: false}));
      dispatch(setGeocodingError({geocodingError: 'Geolocation not supported'}));
    }
  };

export default geolocationSlice.reducer;
