import React, { ReactNode, useCallback, useEffect, useMemo } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useLocation} from 'react-router-dom';
import LiveGameContext from '../contexts/LiveGameContext';
import {RootState} from '../redux/rootReducer';
import {trackEventData} from '../utils/analytics';
import {
  EXIT_GAME_CATEGORY,
  EXIT_GAME_ACTION,
} from '../constants/analytics.constants';
import {
  resetAllWatchPartyVars,
  resetGameState,
} from '../redux/modules/game';
import routes, {IN_GAME_ROUTES} from '../constants/routes.constants';
import {resetPusherGameState} from '../redux/modules/pusher';
import {exitGameTime} from '../redux/modules/game';

export default function LiveGameProvider({children}: {children: ReactNode}) {
  // Dates cannot be stored on their own in useState - using an object wrapper
  const {gameId, teamId, activeMillis} = useSelector(
    (state: RootState) => state.game,
  );
  const user = useSelector((state: RootState) => state.user.userData);
  const {activeTeam, nextGame} = useSelector((state: RootState) => state.teams);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const trackGameExit = useCallback(() => {
    trackEventData(EXIT_GAME_CATEGORY, {
      action: EXIT_GAME_ACTION,
      home_turf_team_name: activeTeam?.name,
      home_turf_team_id: teamId,
      time_in_game: activeMillis,
      user_id: user?.id,
      game_id: gameId,
    });
  }, [activeMillis, activeTeam?.name, gameId, teamId, user?.id]);

  const onExitGame = useCallback(() => {
    trackGameExit();
    dispatch(exitGameTime());
    dispatch(resetPusherGameState());
    dispatch(resetAllWatchPartyVars());
    navigate(routes.home);
  }, [trackGameExit, dispatch, navigate]);

  const location = useLocation();

  useEffect(() => {
    if (
      !gameId ||
      !teamId ||
      !IN_GAME_ROUTES.some(route => {
        if (location.pathname === route)
          return true;
        if (gameId && location.pathname === route.replace(':gameId', gameId))
          return true;
        return false;
      })
    ) {
      return;
    }
    const getGameStatus = () => {
      if (!nextGame) return null;
      if (teamId === nextGame.homeTeamId) return nextGame.homeGameState;
      else if (teamId === nextGame.awayTeamId) return nextGame.awayGameState;
      else return null;
    };
    const status = getGameStatus();
    if (status === 'post-game') {
      onExitGame();
      dispatch(resetGameState());
    }
  }, [nextGame, onExitGame, teamId, gameId, location, dispatch]);

  const context = useMemo(() => {
    return {
      onExitGame,
    };
  }, [onExitGame]);

  if (gameId && teamId) {
    return (
      <LiveGameContext.Provider value={context}>
        {children}
      </LiveGameContext.Provider>
    );
  }
  return null;
}
