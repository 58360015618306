import React, {forwardRef, useRef, useImperativeHandle} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Form, Formik} from 'formik';

// components
import InputField from '../fields/InputField';

//utils
import {RootState} from '../../redux/rootReducer';
import {FormStyle} from '../../theme/form';
import {updateUserPersonalInfo} from '../../redux/modules/user';
import {AnyAction} from '@reduxjs/toolkit';

type Props = {
  isEditable?: boolean;
  onUpdate: () => void;
  formStyle?: FormStyle;
};

const EditPersonalForm = forwardRef(
  ({isEditable = true, onUpdate, formStyle}: Props, ref) => {
    const dispatch = useDispatch();

    const {userData: user} = useSelector((state: RootState) => state.user);

    const inputRef = useRef<any>(null);
    useImperativeHandle(ref, () => ({
      handleSubmit: () => {
        inputRef.current?.handleSubmit();
      },
      handleReset: () => {
        inputRef.current?.handleReset();
      },
    }));

    return (
      <Formik
        initialValues={{
          firstName: user?.firstName || '',
          lastName: user?.lastName || '',
        }}
        onSubmit={(values, actions) => {
          const details = {...user, ...values};
          
          dispatch(
            updateUserPersonalInfo({
              details,
              completionCallback: success => {
                if (!success) actions.setSubmitting(false);
                else {
                  onUpdate();
                }
              },
            }) as unknown as AnyAction,
          );
        }}
        validateOnChange={false}
        validateOnBlur={false}
        innerRef={inputRef}>
        {props => (
          <Form noValidate={true}>
            <InputField
              name="firstName"
              type="text"
              label="First Name"
              onChange={(e: any) => props.handleChange(e)}
              inputStyle={formStyle?.field?.input}
              isEditable={isEditable}
            />
            <InputField
              name="lastName"
              type="text"
              label="Last Name"
              onChange={(e: any) => props.handleChange(e)}
              inputStyle={formStyle?.field?.input}
              isEditable={isEditable}
            />
          </Form>
        )}
      </Formik>
    );
  },
);

export default EditPersonalForm;
