import React, {useEffect, useState, useRef, useCallback} from 'react';
import styled from '@emotion/styled';
import {ONE_DAY} from '../../constants/Global.constants';
import {black, grey_100, white} from '../../theme/vars';
import {msToTime} from '../../utils/formatters';

type Props = {
  startTime: string;
};

const Container = styled.div`
  background: ${black};
  color: ${white};
  width: 100%;
  padding-top: 25px;

  p:first-of-type {
    font-size: 1rem;
    font-family: Roboto-Bold;
    color: ${grey_100};
    margin-bottom: 0.25rem;
  }
  p:last-of-type {
    font-size: 1.5rem;
    font-family: Roboto-Bold;
    color: ${white};
  }
`;

const GameCardCountDown = ({startTime}: Props) => {
  const [time, setTime] = useState<string | null>(null);
  const [timeDiff, setTimeDiff] = useState<number | null>(null);
  const timeout = useRef<NodeJS.Timeout | null>(null);

  const getDiff = useCallback(() => {
    const msStartTime = new Date(startTime).getTime();
    const msNow = new Date().getTime();
    return msStartTime - msNow;
  }, [startTime]);

  useEffect(() => {
    setTime(msToTime(getDiff()));

    return () => {
      setTime(null);
    };
  }, [getDiff]);

  useEffect(() => {
    timeout.current = setTimeout(() => {
      const diff = getDiff();
      setTimeDiff(diff);
      if (diff <= 0) {
        setTime(msToTime(0));
      } else {
        setTime(msToTime(diff));
      }
    }, 1000);

    return () => {
      timeout.current && clearTimeout(timeout.current);
    };
  }, [time, startTime, getDiff]);

  if (timeDiff && timeDiff < ONE_DAY && timeDiff > 0) {
    return (
      <Container>
        {/* <p>Game Starts In</p> */}
        <p>{time}</p>
      </Container>
    );
  }
  return null;
};

export default GameCardCountDown;
