import { useLocation } from 'react-router-dom';
import { trackScreen } from '../../utils/analytics';
import React, { useEffect } from 'react';

type Props = {
  children?: React.ReactNode;
};


const Analytics = ({children}: Props) => {
  const location = useLocation();

  useEffect(() => {
   trackScreen(location.pathname);
  }, [location, location.pathname]);

  return <>{children}</>;
}


export default Analytics;
