import React from 'react';
import styled from '@emotion/styled';

const Container = styled.button`
  background: transparent;
  border: none;
  position: absolute;
  padding: 0;
  right: 0.5rem;
  top: 9px;
  img {
    width: 30px;
    height: 30px;
  }
`;

type Props = {
  handleClick: () => void;
};

const ErrorButton = ({handleClick}: Props) => {
  return (
    <Container onClick={handleClick}>
      <img src="../../../assets/icons/red-x.png" alt="Clear error" />
    </Container>
  );
};

export default ErrorButton;
