import styled from '@emotion/styled';
import {black} from '../../theme/vars';

type BackgroundImageProps = {
  backgroundImage: string;
  backgroundColor?: string;
};

const BackgroundImage = styled('div')<BackgroundImageProps>`
  background-image: url(${props => props.backgroundImage});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: ${props => props.backgroundColor || black};
  width: 100%;
  height: 100%;
`;

export default BackgroundImage;
