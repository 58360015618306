import {createContext} from 'react';

type PusherContextType = {
  subscribeToGameTimeExperience: (gameId: any, teamId: any) => void;
  unsubscribeFromGameTimeExperience: (gameId: any, teamId: any) => void;
  subscribeToPrivateChannel: () => void;
  unsubscribeFromPrivateChannel: () => void;
};

const PusherContext = createContext<PusherContextType>({
  subscribeToGameTimeExperience: (gameId: any, teamId: any) => {},
  unsubscribeFromGameTimeExperience: (gameId: any, teamId: any) => {},
  subscribeToPrivateChannel: () => {},
  unsubscribeFromPrivateChannel: () => {},
});

export default PusherContext;
