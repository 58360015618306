import {createContext} from 'react';

type LiveGameContextType = {
  onExitGame: () => void;
};

const LiveGameContext = createContext<LiveGameContextType>({
  onExitGame: () => {},
});

export default LiveGameContext;
