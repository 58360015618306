import {useRef} from 'react';
import styled from '@emotion/styled';
import {defaultInput} from '../../theme/form';

type ContainerProps = {
  inputStyle: any;
};

const Container = styled.input<ContainerProps>`
  ${props => props.inputStyle};
`;

type Props = {
  id: string;
  label?: string;
  name: string;
  type: string;
  placeholder?: string;
  value?: string;
  onChange?: (e: any) => void;
  onBlur?: (e: any) => void;
  required?: boolean;
  inputStyle?: any;
  inputMode?:
    | 'text'
    | 'none'
    | 'tel'
    | 'url'
    | 'email'
    | 'numeric'
    | 'decimal'
    | 'search';
  disabled?: boolean;
};

const Input = ({
  name,
  type,
  placeholder = '',
  value,
  onChange,
  onBlur,
  required,
  inputMode = 'text',
  inputStyle = defaultInput,
  disabled = false,
}: Props) => {
  const inputRef = useRef<any>(null);

  return (
    <Container
      inputStyle={inputStyle}
      inputMode={inputMode}
      ref={inputRef}
      id={name}
      required={required}
      disabled={disabled}
      name={name}
      type={type}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
    />
  );
};

export default Input;
