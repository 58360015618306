import {createSlice} from '@reduxjs/toolkit';
import {LEADERBOARD_ROUTES, PARTICIPATION_ROUTES, request} from '../../../api';
import {AppThunk} from '../../store';

const initialState = {
  usersTeamLeaderboard: [],
  isRequestingUsersTeamLeaderboard: false,
  usersTeamLeaderboardError: null,
  usersTeamGameLeaderboard: [],
  isRequestingUsersTeamGameLeaderboard: false,
  usersTeamGameLeaderboardError: null,
  isRequestingParticipationTeams: false,
  participationTeams: [],
  participationTeamsError: null,
  isRequestingParticipationTeamGames: false,
  participationTeamGames: [],
  participationTeamGamesError: null,
};

const leaderboardSlice = createSlice({
  name: 'leaderboard',
  initialState,
  reducers: {
    getUsersTeamLeaderboardRequest(state) {
      state.isRequestingUsersTeamLeaderboard = true;
    },
    getUsersTeamLeaderboardSuccess(state, action) {
      state.isRequestingUsersTeamLeaderboard =
        initialState.isRequestingUsersTeamLeaderboard;
      state.usersTeamLeaderboard = action.payload;
    },
    getUsersTeamLeaderboardError(state, action) {
      state.isRequestingUsersTeamLeaderboard =
        initialState.isRequestingUsersTeamLeaderboard;
      state.usersTeamLeaderboardError = action.payload.error;
    },
    getUsersTeamGameLeaderboardRequest(state) {
      state.isRequestingUsersTeamGameLeaderboard = true;
    },
    getUsersTeamGameLeaderboardSuccess(state, action) {
      state.isRequestingUsersTeamGameLeaderboard =
        initialState.isRequestingUsersTeamGameLeaderboard;
      state.usersTeamGameLeaderboard = action.payload;
    },
    getUsersTeamGameLeaderboardError(state, action) {
      state.isRequestingUsersTeamGameLeaderboard =
        initialState.isRequestingUsersTeamGameLeaderboard;
      state.usersTeamGameLeaderboardError = action.payload.error;
    },
    getParticipationTeamsRequest(state) {
      state.isRequestingParticipationTeams = true;
    },
    getParticipationTeamsSuccess(state, action) {
      state.isRequestingParticipationTeams =
        initialState.isRequestingParticipationTeams;
      state.participationTeams = action.payload;
    },
    getParticipationTeamsError(state, action) {
      state.isRequestingParticipationTeams =
        initialState.isRequestingParticipationTeams;
      state.participationTeamsError = action.payload.error;
    },
    getParticipationTeamGamesRequest(state) {
      state.isRequestingParticipationTeamGames = true;
    },
    getParticipationTeamGamesSuccess(state, action) {
      state.isRequestingParticipationTeamGames =
        initialState.isRequestingParticipationTeamGames;
      state.participationTeamGames = action.payload;
    },
    getParticipationTeamGamesError(state, action) {
      state.isRequestingParticipationTeamGames =
        initialState.isRequestingParticipationTeamGames;
      state.participationTeamGamesError = action.payload.error;
    },
  },
});

export const {
  getUsersTeamLeaderboardRequest,
  getUsersTeamLeaderboardSuccess,
  getUsersTeamLeaderboardError,
  getUsersTeamGameLeaderboardRequest,
  getUsersTeamGameLeaderboardSuccess,
  getUsersTeamGameLeaderboardError,
  getParticipationTeamsRequest,
  getParticipationTeamsSuccess,
  getParticipationTeamsError,
  getParticipationTeamGamesRequest,
  getParticipationTeamGamesSuccess,
  getParticipationTeamGamesError,
} = leaderboardSlice.actions;

const apiGetUsersTeamLeaderboard = async (teamId: string) => {
  return request({
    route: LEADERBOARD_ROUTES.USERS_TEAM_LEADERBOARD,
    pathParams: {teamId},
  });
};

export const getUsersTeamLeaderboard =
  (): AppThunk => async (dispatch, getState) => {
    dispatch(getUsersTeamLeaderboardRequest());
    try {
      const state = getState();
      const activeTeamId = state.teams.activeAppTeamId;
      const data = await apiGetUsersTeamLeaderboard(activeTeamId);
      dispatch(getUsersTeamLeaderboardSuccess(data));
    } catch (err) {
      dispatch(getUsersTeamLeaderboardError(err));
    }
  };

const apiGetUsersTeamGameLeaderboard = async (
  teamId: string,
  gameId: string,
) => {
  return request({
    route: LEADERBOARD_ROUTES.USERS_TEAM_GAME_LEADERBOARD,
    pathParams: {teamId, gameId},
  });
};

export const getUsersTeamGameLeaderboard =
  (gameId: string): AppThunk =>
  async (dispatch, getState) => {
    dispatch(getUsersTeamGameLeaderboardRequest());
    try {
      const state = getState();
      const activeTeamId = state.teams.activeAppTeamId;
      const data = await apiGetUsersTeamGameLeaderboard(activeTeamId, gameId);
      dispatch(getUsersTeamGameLeaderboardSuccess(data));
    } catch (err) {
      dispatch(getUsersTeamGameLeaderboardError(err));
    }
  };

const apiParticipationTeams = async () => {
  return request({
    route: PARTICIPATION_ROUTES.PARTICIPATION_TEAMS,
  });
};

export const getParticipationTeams = (): AppThunk => async dispatch => {
  dispatch(getParticipationTeamsRequest());

  try {
    const data = await apiParticipationTeams();
    dispatch(getParticipationTeamsSuccess(data));
  } catch (err) {
    dispatch(getParticipationTeamsError(err));
  }
};

const apiParticipationTeamGames = async (teamId: string) => {
  return request({
    route: PARTICIPATION_ROUTES.PARTICIPATION_TEAM_GAMES,
    pathParams: {teamId: teamId},
  });
};

export const getParticipationTeamGames =
  (): AppThunk => async (dispatch, getState) => {
    dispatch(getParticipationTeamGamesRequest());
    try {
      const state = getState();
      const activeTeamId = state.teams.activeAppTeamId;
      const data = await apiParticipationTeamGames(activeTeamId);

      dispatch(getParticipationTeamGamesSuccess(data));
    } catch (err) {
      dispatch(getParticipationTeamGamesError(err));
    }
  };

export default leaderboardSlice.reducer;
