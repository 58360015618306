import styled from '@emotion/styled';
import {
  curiousBlue,
  white,
  curiousBlueActive,
  white_30,
} from '../../theme/vars';

type ButtonProps = {
  backgroundColor?: string;
  backgroundColorActive?: string;
  textColor?: string;
  colorActive?: string;
  href?: string;
  borderRadius?: string;
  border?: string;
  textTransform?: string;
  maxWidth?: string;
  padding?: string;
};

export const Button = styled('button')<ButtonProps>`
  background-color: ${props => props.backgroundColor || curiousBlue};
  border: ${props => props.border || 'none'};
  border-radius: ${props => props.borderRadius || '3px'};
  color: ${props => props.textColor || white};
  font-family: Roboto-Bold;
  font-size: 1rem;
  padding: ${props => props.padding || '1rem'};
  text-transform: ${props => props.textTransform || 'uppercase'};
  width: 100%;
  text-align: center;
  text-decoration: none;
  margin: 0;
  max-width: ${props => props.maxWidth || 'inherit'};
  &:hover,
  &:active {
    background-color: ${props =>
      props.backgroundColorActive || curiousBlueActive};
    color: ${props => props.colorActive || white};
  }
  &:disabled {
    pointer-events: none;
    color: ${white_30};
  }
`;
