export const defaultModal: ModalStyle = {
  content: {
    top: '50%',
    right: 'auto',
    bottom: 'auto',
    left: '50%',
    position: 'absolute',
    padding: '2rem',
    width: '100%',
    maxWidth: '600px',
    transform: 'translate(-50%, -50%)',
  },
  overlay: {
    zIndex: 1000,
  },
};

export type ModalStyle = {
  content: Record<string, unknown>;
  overlay: Record<string, unknown>;
  footer?: Record<string, unknown>;
};
