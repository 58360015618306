import {BodyText} from '../typography/BodyText';
import {
  PRIVACY_POLICY_URL,
  TERMS_OF_SERVICE_URL,
} from '../../constants/Url.constants';
import Link from '../typography/Link';
import {white} from '../../theme/vars';
import styled from '@emotion/styled';
import { Spacer } from '../layouts/Spacer';

const Container = styled.div`
  width: 75%;
  text-align: center;
`;

const TermsServicePrivacyPolicy = () => {
  return (
    <Container>
      <BodyText size=".75rem">
        By continuing, you agree to be bound by our{' '}
        <Link url={TERMS_OF_SERVICE_URL} color={white} fontFamily="Roboto-Bold">
          Terms of Service
        </Link>{' '}
        and{' '}
        <Link url={PRIVACY_POLICY_URL} color={white} fontFamily="Roboto-Bold">
          Privacy Policy.
        </Link>
      </BodyText>
      <Spacer top="10px" />
      <BodyText size=".75rem">
        You also agree to be bound by The New York Islanders Messaging{' '}
        <Link url={'https://attnl.tv/t/g9U'} color={white} fontFamily="Roboto-Bold">
          Terms and Conditions
        </Link>{' '}
        and{' '}
        <Link url={'https://attnl.tv/p/g9U'} color={white} fontFamily="Roboto-Bold">
          Privacy Policy
        </Link>
      </BodyText>
    </Container>
  );
};

export default TermsServicePrivacyPolicy;
