import React, {forwardRef, useRef, useImperativeHandle} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Form, Formik} from 'formik';

// components
import InputField from '../fields/InputField';

//utils
import {RootState} from '../../redux/rootReducer';
import {updateUserAccountInfo} from '../../redux/modules/user';
import {FormStyle} from '../../theme/form';
import {AnyAction} from '@reduxjs/toolkit';
import {updateAccountInfoValidation} from '../../utils/validation';

type Props = {
  isEditable?: boolean;
  onUpdate: () => void;
  formStyle?: FormStyle;
};

const EditAccountForm = forwardRef(
  ({isEditable = true, onUpdate, formStyle}: Props, ref) => {
    const dispatch = useDispatch();

    const {userData} = useSelector((state: RootState) => state.user);

    const inputRef = useRef<any>(null);

    useImperativeHandle(ref, () => ({
      handleSubmit: () => {
        inputRef.current?.handleSubmit();
      },
      handleReset: () => {
        inputRef.current?.handleReset();
      },
    }));

    return (
      <Formik
        initialValues={{
          username: userData?.username || '',
          emailAddress: userData?.emailAddress || '',
        }}
        onSubmit={(values, actions) => {
          dispatch(
            updateUserAccountInfo({
              accountInfo: values,
              completionCallback: success => {
                if (!success) actions.setSubmitting(false);
                else {
                  onUpdate();
                }
              },
            }) as unknown as AnyAction,
          );
        }}
        validationSchema={updateAccountInfoValidation}
        validateOnChange={false}
        validateOnBlur={false}
        innerRef={inputRef}>
        {props => (
          <Form noValidate={true}>
            <InputField
              name="username"
              type="text"
              label="Username"
              onChange={(e: any) => props.handleChange(e)}
              inputStyle={formStyle?.field?.input}
              isEditable={isEditable}
            />
            <InputField
              name="emailAddress"
              type="text"
              label="Email Address"
              onChange={(e: any) => props.handleChange(e)}
              inputStyle={formStyle?.field?.input}
              isEditable={isEditable}
            />
          </Form>
        )}
      </Formik>
    );
  },
);

export default EditAccountForm;
