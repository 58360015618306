import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Provider} from 'react-redux';
import store, {persistor} from './redux/store';
import GlobalStyles from './theme/global';
import {PersistGate} from 'redux-persist/integration/react';
import HTToastContainer from './components/toast/HTToastContainer';
import { ToastContainerIds } from './constants/Global.constants';

import 'react-toastify/dist/ReactToastify.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <HTToastContainer containerId={ToastContainerIds.Global} />
      <GlobalStyles />
      <App />
    </PersistGate>
  </Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
