const GameMenuIcon = () => {
  return (
    <svg
      width="31"
      height="31"
      viewBox="0 0 31 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.04167 7.75H15.5C18.3535 7.75 20.6667 10.0632 20.6667 12.9167V13.0781L23.1725 11.5113C23.9689 11.0136 24.9726 10.9873 25.794 11.4425C26.6154 11.8978 27.125 12.7629 27.125 13.702V17.298C27.1269 18.238 26.618 19.1049 25.7962 19.5614C24.9745 20.0179 23.9696 19.9921 23.1725 19.4938L20.6667 17.9219V18.0833C20.6667 20.9368 18.3535 23.25 15.5 23.25H9.04167C6.18819 23.25 3.875 20.9368 3.875 18.0833V12.9167C3.875 10.0632 6.18819 7.75 9.04167 7.75Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.2708 18.0834C10.8441 18.0834 9.6875 16.9268 9.6875 15.5001C9.6875 14.0733 10.8441 12.9167 12.2708 12.9167C13.6976 12.9167 14.8542 14.0733 14.8542 15.5001C14.8542 16.1852 14.582 16.8423 14.0975 17.3268C13.6131 17.8112 12.956 18.0834 12.2708 18.0834Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.6666 13.0781V17.9219"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default GameMenuIcon;
