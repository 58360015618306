import styled from '@emotion/styled';
import BackgroundImage from '../headers/BackgroundImage';
import {getGameCardImage, getTeamImage} from '../../utils/media';
import {AssetType} from '../../types/assetType';

type ContainerProps = {
  width?: string;
};

const Container = styled.img<ContainerProps>`
  width: ${props => props.width || '100%'};
  object-fit: contain;
`;

type HTAssetProps = {
  type: AssetType;
  teamId?: string;
  gameId?: string;
  altText?: string;
  width?: string;
  children?: any;
};

const HTImage = ({
  type,
  teamId,
  gameId,
  altText,
  width,
  children,
}: HTAssetProps) => {
  const imageAsset =
    type === AssetType.GameCard
      ? getGameCardImage({teamId, gameId})
      : getTeamImage({type, teamId});
  const defaultBackground = '../../../assets/images/HT@3x.png';
  const defaultImage = '../../../assets/icons/hometurf.png';

  return imageAsset ? (
    type === AssetType.GameCard ? (
      <BackgroundImage backgroundImage={imageAsset.mediaUrl}>
        {children}
      </BackgroundImage>
    ) : (
      <Container width={width} src={imageAsset.mediaUrl} alt={altText} />
    )
  ) : type === AssetType.GameCard ? (
    <BackgroundImage backgroundImage={defaultBackground}>
      {children}
    </BackgroundImage>
  ) : (
    <Container width={width} src={defaultImage} alt={altText} />
  );
};

export default HTImage;
