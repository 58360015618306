export const header = 'FAN TRIVIA';
export const loadingText = 'Tabulating results';
export const results = 'Results';
export enum triviaStep {
  GET_READY = 1,
  COUNTDOWN_QUESTION = 2,
  TRANSITION_TO_ANSWER = 3,
  LOADING_POLL_RESULTS = 3,
  SHOW_ANSWER_TO_QUESTION = 4,
  LOADING_RESULTS = 5,
  RESULTS = 6,
}
export const GET_READY_STEP_TIMEOUT = 300000;
