import React from 'react';
import styled from '@emotion/styled';
import BackButton from '../buttons/BackButton';
import {defaultThemeColor} from '../../theme/vars';

type ContainerProps = {
  backgroundColor?: string;
  maxWidth?: string;
  padding?: string;
  height?: string;
  alignItems?: string;
};

const Container = styled.div<ContainerProps>`
  background-color: ${({backgroundColor}) =>
    backgroundColor || defaultThemeColor};
  padding: ${({padding}) => padding || '1.5rem 0'};
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: ${({alignItems}) => alignItems || 'center'};
  justify-content: space-between;
  height: ${({height}) => height || 'unset'};
  // max-width: ${({maxWidth}) => maxWidth || '600px'};

  .endContainer {
    display: flex;
  }

  .endContainer--right {
    justify-content: flex-end;
  }
`;

type Props = {
  maxWidth?: string;
  backButton?: boolean;
  customBackAction?: () => void;
  menuButton?: React.ReactElement;
  leftContainer?: React.ReactElement;
  backgroundColor?: string;
  padding?: string;
  height?: string;
  alignItems?: string;
  children?: React.ReactElement;
};

const GenericHeader = ({
  maxWidth,
  backButton,
  customBackAction,
  menuButton,
  leftContainer,
  backgroundColor,
  padding,
  height,
  alignItems,
  children,
}: Props) => {
  return (
    <Container
      backgroundColor={backgroundColor}
      padding={padding}
      height={height}
      alignItems={alignItems}
      maxWidth={maxWidth}>
        {leftContainer ||
          (backButton && (
            <div className="endContainer">
              <BackButton customBackAction={customBackAction} />
            </div>
          ))}
        {children}
        {menuButton && (
          <div className="endContainer endContainer--right">{menuButton}</div>
        )}
    </Container>
  );
};

export default GenericHeader;
