import {RootState} from '../redux/rootReducer';
import {useSelector} from 'react-redux';

export default function useJoinedGame() {
  return (
    useSelector((state: RootState) => {
      const gameId = state.game.gameId;
      if (state.teams.nextGame?.id === gameId) {
        return state.teams.nextGame;
      }
    }) || null
  );
}
