import {useState} from 'react';
import {useDispatch} from 'react-redux';

// constants
import {triviaStep} from '../../constants/Trivia.constants';

// hooks

import {
  handlePollCompletion,
  handlePollDetails,
  setStep,
} from '../../redux/modules/poll';
import InteractionContainer from '../../components/interactions/InteractionContainer';
import TriviaCountdownQuestion from '../../components/poll/TriviaCountdownQuestion';
import TriviaLoadingResults from '../../components/poll/TriviaLoadingResults';
import TriviaResults from '../../components/poll/TriviaResults';
import {resetInteraction} from '../../redux/modules/interaction';
import useTriviaPollSetup from '../../hooks/useTriviaPollSetup';
import {AnyAction} from '@reduxjs/toolkit';

type Props = {
  interaction: any;
  userId: string;
  gameId: string;
  poll: any;
  joinedTeam: any;
  isWatchPartyConnected?: boolean;
  isGuestUser: boolean;
};

const Poll = ({
  interaction,
  userId,
  gameId,
  poll,
  joinedTeam,
  isWatchPartyConnected,
  isGuestUser,
}: Props) => {
  const dispatch = useDispatch();

  const {step} = poll;
  const {timeStarted} = step;

  const {interactionId, metadata: interactionMetaData} = interaction;
  const [activeAnswer, setActiveAnswer] = useState<any | null>(null);
  const {color: backgroundColor} = joinedTeam;

  const setCurrentStep = (step: any) =>
    dispatch(setStep({step, interactionId}));

  useTriviaPollSetup({
    currentStep: step,
    setStep: setCurrentStep,
    gameId,
    interactionId,
    type: 'poll',
  });

  const handlePollCountdownQuestion = (nextStep: any, answer: any) => {
    setActiveAnswer(answer);
    setCurrentStep(nextStep);
  };

  const startPollSubmit = (body: any) =>
    dispatch(handlePollDetails({body}) as unknown as AnyAction);

  const startClearInteractionType = () =>
    dispatch(resetInteraction() as unknown as AnyAction);

  const startPollCompletion = (interactionId: any) =>
    dispatch(handlePollCompletion({interactionId}) as unknown as AnyAction);

  // switch to render
  const renderStateSwitch = (renderStep: any) => {
    switch (renderStep?.current) {
      case triviaStep.GET_READY:
        return null;
      case triviaStep.COUNTDOWN_QUESTION:
        return (
          <TriviaCountdownQuestion
            interaction={interaction}
            setStep={(s, answer) => handlePollCountdownQuestion(s, answer)}
            handleSubmit={startPollSubmit}
            userId={userId}
            poll={poll}
            isGuestUser={isGuestUser}
            backgroundColor={backgroundColor}
            isWatchPartyConnected={isWatchPartyConnected}
            timeStarted={timeStarted}
          />
        );
      case triviaStep.LOADING_POLL_RESULTS:
        return (
          <TriviaLoadingResults
            interaction={interaction}
            setStep={setCurrentStep}
            activeAnswer={activeAnswer}
            poll={poll}
            clearInteractionType={startClearInteractionType}
            backgroundColor={backgroundColor}
            isWatchPartyConnected={isWatchPartyConnected}
          />
        );
      case triviaStep.RESULTS:
        return (
          <TriviaResults
            interaction={interaction}
            poll={poll}
            activeAnswer={activeAnswer}
            clearInteractionType={startClearInteractionType}
            onTriviaCompletion={startPollCompletion}
            backgroundColor={backgroundColor}
            isWatchPartyConnected={isWatchPartyConnected}
          />
        );
      default:
        return null;
    }
  };

  return (
    <InteractionContainer
      backgroundColor={backgroundColor}
      content={
        interactionMetaData &&
        interactionMetaData.options &&
        renderStateSwitch(step)
      }
      isWatchPartyConnected={false}
    />
  );
};

export default Poll;
