import styled from '@emotion/styled';
import React from 'react';
import HTHeader from '../headers/HTHeader';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  font-family: 'Roboto-Regular';
  margin-top: 0px;
`;

export const ContentWrapper = styled.div`
  //max-width: 400px;
  width: 100%;
  //padding: 0 2rem 5rem;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
`;

type Props = {
  backButton?: boolean;
  customBackAction?: () => void;
  children: React.ReactNode;
  menuButton?: JSX.Element;
  usePortrait?: boolean;
  useLandscape?: boolean;
  noHeader?: boolean;
  hideRadioToggle?: boolean;
};

export default function DefaultLayout({
  children,
  backButton,
  customBackAction,
  menuButton,
  noHeader = false,
  hideRadioToggle = false,
}: Props) {
  return (
    <Container>
      {!noHeader && (
        <HTHeader
          backButton={backButton}
          menuButton={menuButton}
          customBackAction={customBackAction}
          hideRadioToggle={hideRadioToggle}
        />
      )}
      <ContentWrapper>{children}</ContentWrapper>
    </Container>
  );
}
