import styled from '@emotion/styled';
import {white} from '../../theme/vars';

type Props = {
  text?: string;
};

const Container = styled.div<Props>`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;

  .question {
    color: ${white};
    font-family: 'Roboto-Bold';
    font-size: 0.8rem;
    font-size: 40px;
    padding-top: 5px;
    padding-bottom: 5px;
    line-height: 1.2;
    text-align: center;
    width: 90%;
  }
`;

const Question = ({text}: Props) => {
  return (
    <Container>
      <div className="question">{text}</div>
    </Container>
  );
};

export default Question;
