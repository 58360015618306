import SVGIconButton from "./SVGIconButton";
import Menu from "../../assets/icons/menu";
import React from "react";

type Props = {
  menuAction: () => void;
}
const MenuButton = ({menuAction}: Props) => <SVGIconButton icon={<Menu/>} onClick={menuAction}/>;

export default MenuButton;
