import {css} from '@emotion/react';
import {black, eastBay, white, white_25} from './vars';

export const defaultInputLabel = css`
  color: ${white_25};
  font-size: 1rem;
  text-transform: uppercase;
  font-family: Roboto-Regular;
  position: absolute;
  left: 1rem;
  top: 1rem;
  transition: top 0.3s ease-out;
`;

export const defaultInput = css`
  background-color: ${eastBay};
  border: none;
  border-radius: 3px;
  color: ${white};
  font-family: Roboto-Regular;
  font-size: 21px;
  height: 3rem;
  padding-left: 1rem;
  width: 100%;
  &:focus ~ label,
  &:valid ~ label {
    font-size: 0.5rem;
    top: 0.25rem;
  }

  outline: none;
`;

export const defaultInputField = css`
  position: relative;
  margin-bottom: 1rem;
  width: 100%;
`;

export const defaultSelect = css`
  display: grid;
  grid-template-areas: 'select';
  align-items: center;
  padding-left: 1rem;
  position: relative;
  height: 3rem;
  width: 100%;
  border: none;
  border-radius: 3px;
  font-size: 1rem;
  cursor: pointer;
  background-color: ${eastBay};
  &:after {
    content: '';
    position: absolute;
    right: 1rem;
    top: 1rem;
    width: 1rem;
    height: 1rem;
    background: center / contain no-repeat
      url(../../assets/images/chevronDown.png);
  }
  select {
    appearance: none;
    background-color: transparent;
    border: none;
    color: ${white};
    margin: 0;
    width: 100%;
    font-family: Roboto-Regular;
    font-size: 1rem;
    cursor: inherit;
    line-height: inherit;
    z-index: 1;
    outline: none;
  }
  selectOption {
    color: ${black};
  }
`;

export const defaultSelectField = css`
  margin-bottom: 1rem;
  width: 100%;
`;

//TODO: For accessibility reasons, it would be good to keep the label within the DOM, just off-screen.
export const defaultSelectLabel = css`
  display: none;
`;

export const defaultForm: FormStyle = {
  field: {
    input: {
      label: defaultInputLabel,
      input: defaultInput,
      self: defaultInputField,
    },
    select: {
      label: defaultSelectLabel,
      select: defaultSelect,
      self: defaultSelectField,
    },
  },
};

export type FormStyle = {
  field: Record<string, unknown>;
};
