// PUSHER EVENTS
export const GAME_TIME_CHANNEL = 'game-time@';
export const SEASON_TICKET_GAME_CHANNEL = 'season-ticket-game@';
export const EVERYONE_CHANNEL = 'everyone@hometurf';
export const PRIVATE_CHANNEL = 'private-notifications@';

export const GAME_BEGIN = 'GAME_BEGIN';
export const GAME_END = 'GAME_END';
export const INTERACTION = 'interaction.start';
export const TEAM_SCREAM_RESULTS = 'interaction.result';
export const GAME_UPDATED = 'game.updated';
export const INTERACTION_RESULTS = 'interaction.result';
export const POINTS_EARNED = 'points.earned';

export const MEDIA_ADD = 'media.add';

// INTERACTION TYPES
export const STATS = 'stats';
export const TEAM_SCREAM = 'team-scream';
export const LIVE_STREAM_STOP = 'live-stream-stop';
export const HLS_STREAM_START = 'hls-stream-start';
export const SEASON_TICKET_STREAM_START = 'season-ticket-stream-start';
export const SEASON_TICKET_STREAM_STOP = 'season-ticket-stream-stop';
export const SOUND_STOP = 'stop-sound';
export const MEDIA = 'media';
export const TRIVIA = 'trivia';
export const POLL = 'poll';
export const SCOREBOARD = 'scoreboard';
export const WEBVIEW = 'webview';
